// import swal from "sweetalert";

const validateDate = (date, field) => {
    if (date === '') {
        document.getElementById(`${field}Error`).innerHTML =
            'Date cannot be empty'
        return true
    }

    if (!/^\d{4}-\d{2}-\d{2}$/.test(date)) {
        document.getElementById(`${field}Error`).innerHTML =
            'Date must be in a valid format (YYYY-MM-DD)'
        return true
    }

    const temp_date = new Date(date)
    if (!temp_date || !temp_date.getTime()) {
        document.getElementById(`${field}Error`).innerHTML =
            'Date must be a valid date'
        return true
    }

    if (temp_date < Date.now()) {
        document.getElementById(`${field}Error`).innerHTML =
            'Date must be upcomming'
        return true
    }

    return false
}

function validateTime(time, field) {
    if (time === '') {
        document.getElementById(`${field}Error`).innerHTML =
            'Time cannot be empty'
        return true
    }

    if (!/^\d{2}:\d{2}$/.test(time)) {
        document.getElementById(`${field}Error`).innerHTML =
            'Time must be in a valid format (HH:MM)'
        return true
    }

    return false
}

function validateTextArea(text, field, fieldName) {
    if (text === '') {
        document.getElementById(`${field}Error`).innerHTML =
            `${fieldName} cannot be empty`
        return true
    }
    return false
}

function validateOnlyNumber(text, field, fieldName) {
    if (text === '') {
        document.getElementById(`${field}Error`).innerHTML =
            `${fieldName} cannot be empty`
        return true
    }

    if (!/^[0-9]+$/.test(text)) {
        document.getElementById(`${field}Error`).innerHTML =
            'Please enter only numbers'
        return true
    }
}

function validateName(text, field) {
    if (text === '') {
        document.getElementById(`${field}Error`).innerHTML =
            'Full name cannot be empty'
        return true
    }

    if (!(text.split(' ').length >= 2)) {
        document.getElementById(`${field}Error`).innerHTML =
            'Full name must contain at least two words'
        return true
    }

    if (!/^[a-zA-Z]+$/.test(text.replace(/\s/g, ''))) {
        document.getElementById(`${field}Error`).innerHTML =
            'Full name must contain only alphanumeric characters and spaces'
        return true
    }

    document.getElementById(`${field}Error`).innerHTML = ''
    return false
}

function validateDateOfBirth(dateOfBirth, field) {
    if (dateOfBirth === '') {
        document.getElementById(`${field}Error`).innerHTML =
            'Date of birth cannot be empty'
        return true
    }

    if (!/^\d{4}-\d{2}-\d{2}$/.test(dateOfBirth)) {
        document.getElementById(`${field}Error`).innerHTML =
            'Date of birth must be in a valid format (YYYY-MM-DD)'
        return true
    }

    const temp_date = new Date(dateOfBirth)
    if (!temp_date || !temp_date.getTime()) {
        document.getElementById(`${field}Error`).innerHTML =
            'Date of birth must be a valid date'
        return true
    }

    if (temp_date > Date.now()) {
        document.getElementById(`${field}Error`).innerHTML =
            'Date of birth cannot be in future'
        return true
    }

    return false
}

function validateHeight(height, field) {
    if (height === '') {
        document.getElementById(`${field}Error`).innerHTML =
            'Height cannot be empty'
        return true
    }

    if (!/\d/.test(height)) {
        document.getElementById(`${field}Error`).innerHTML =
            'Height must be a number'
        return true
    }

    if (height <= 0) {
        document.getElementById(`${field}Error`).innerHTML =
            'Height must be greater than zero'
        return true
    }

    return false
}

function validateBloodGroup(bloodGroup, field) {
    if (bloodGroup === '') {
        document.getElementById(`${field}Error`).innerHTML =
            'Blood group cannot be empty'
        return true
    }

    const validBloodGroups = ['A+', 'A-', 'B+', 'B-', 'O+', 'O-', 'AB+', 'AB-']
    if (!validBloodGroups.includes(bloodGroup)) {
        document.getElementById(`${field}Error`).innerHTML =
            'Invalid blood group'
        return true
    }

    return false
}

function validateRadio(value, field, fieldName) {
    if (value === '') {
        document.getElementById(`${field}Error`).innerHTML =
            `${fieldName} cannot be empty`
        return true
    }
    return false
}

function validateEmail(email, field) {
    if (email === '') {
        document.getElementById(`${field}Error`).innerHTML =
            'Email ID cannot be empty'
        return true
    }

    if (!/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/.test(email)) {
        document.getElementById(`${field}Error`).innerHTML =
            'Email ID must be in a valid format'
        return true
    }

    return false
}

const validatePhoneNumber = (phoneNumber, field) => {
    if (!phoneNumber) {
        document.getElementById(`${field}Error`).innerHTML =
            'Phone number cannot be empty.'
        return true
    }

    if (!/^\d{10}$/.test(phoneNumber)) {
        document.getElementById(`${field}Error`).innerHTML =
            'Invalid phone number.'
        return true
    }

    return false
}

const validateFirstName = (firstName, field) => {
    if (!firstName) {
        document.getElementById(`${field}Error`).innerHTML =
            'Name cannot be empty.'
        return true
    }

    if (!/^[a-zA-Z]+$/.test(firstName.replace(/\s/g, ''))) {
        document.getElementById(`${field}Error`).innerHTML =
            'Name must contain only alphanumeric characters and spaces'
        return true
    }

    return false
}

const validateLastName = (lastName, field) => {
    if (!lastName) {
        document.getElementById(`${field}Error`).innerHTML =
            'Name cannot be empty.'
        return true
    }

    if (!/^[a-zA-Z]+$/.test(lastName.replace(/\s/g, ''))) {
        document.getElementById(`${field}Error`).innerHTML =
            'Name must contain only alphanumeric characters and spaces'
        return true
    }

    return false
}

const validateImg = (img) => {
    if (img === '') {
        // swal({
        //   title: "Please upload img , inorder to procceed",
        //   icon: "warning",
        //   buttons: "Ok",
        //   dangerMode: true,
        // });
        return true
    }
    return false
}

const validateAlphaNumHyphen = (value, field) => {
    if (!value) {
        document.getElementById(`${field}Error`).innerHTML =
            'Field cannot be empty'
        return true
    }

    if (!/^[a-zA-Z0-9-]+$/.test(value)) {
        document.getElementById(`${field}Error`).innerHTML =
            'Please enter only Numbers , Aplhabets and (-) Symbol'
        return true
    }
    document.getElementById(`${field}Error`).innerHTML = ''
    return false
}

const validateAplhaNum = (value, field) => {
    if (!value) {
        document.getElementById(`${field}Error`).innerHTML =
            'Field cannot be empty'
        return true
    }
    if (!/^[a-zA-Z0-9]+$/.test(value)) {
        document.getElementById(`${field}Error`).innerHTML =
            'Please enter only Numbers and Aplhabets'
        return true
    }
    document.getElementById(`${field}Error`).innerHTML = ''
    return false
}

const validateAplhaNumSpace = (value, field) => {
    if (!value) {
        document.getElementById(`${field}Error`).innerHTML =
            'Field cannot be empty'
        return true
    }
    if (!/^[a-zA-Z0-9\s]+$/.test(value)) {
        document.getElementById(`${field}Error`).innerHTML =
            'Please enter only Numbers and Aplhabets'
        return true
    }
    document.getElementById(`${field}Error`).innerHTML = ''
    return false
}

const validateAlpha = (value, field) => {
    if (!value) {
        document.getElementById(`${field}Error`).innerHTML =
            'Field cannot be empty'
        return true
    }

    if (!/^[a-zA-Z]+$/.test(value)) {
        document.getElementById(`${field}Error`).innerHTML =
            'Please enter only Aplhabets'
        return true
    }
}

const validateAlphaSpace = (value, field) => {
    if (!value) {
        document.getElementById(`${field}Error`).innerHTML =
            'Field cannot be empty'
        return true
    }

    if (!/^[a-zA-Z\s]+$/.test(value)) {
        document.getElementById(`${field}Error`).innerHTML =
            'Please enter only Aplhabets'
        return true
    }
}

export {
    validateDate,
    validateTime,
    validateTextArea,
    validateOnlyNumber,
    validateDateOfBirth,
    validateHeight,
    validateBloodGroup,
    validateRadio,
    validateEmail,
    validateName,
    validatePhoneNumber,
    validateFirstName,
    validateLastName,
    validateImg,
    validateAlphaNumHyphen,
    validateAplhaNum,
    validateAlpha,
    validateAlphaSpace,
    validateAplhaNumSpace,
}
