import React from 'react'

export default function Abbreviation() {
    return (
        <div>
            <div className="w-full flex justify-center items-center text-4xl font-bold font-inter">
                Abbreviation
            </div>
            <br />
            <br />
            <div>
                AI : Artificial Intelligence
                <br />
                ML : Machine Learning
                <br />
                DL : Deep Learning
                <br />
            </div>
        </div>
    )
}
