import React, { useEffect, useRef } from 'react'
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { useNavigate } from 'react-router-dom';

am4core.useTheme(am4themes_animated);

const RoadConditionStats = ({ roadConditionPieChartData }) => {

    const chartRef = useRef(null);
    const data = [];
    const navigate = useNavigate();

    useEffect(() => {
        let chart = am4core.create("pieCharts", am4charts.PieChart);
        chart.data = roadConditionPieChartData;

        chart.innerRadius = am4core.percent(0);
        chart.width = am4core.percent(100);
        chart.height = am4core.percent(100);

        let pieSeries = chart.series.push(new am4charts.PieSeries());
        pieSeries.dataFields.value = "value";
        pieSeries.dataFields.category = "label";

        pieSeries.slices.template.stroke = am4core.color("#ffffff");
        pieSeries.slices.template.strokeWidth = 1;
        pieSeries.slices.template.strokeOpacity = 1;
        pieSeries.labels.template.disabled = true;
        pieSeries.ticks.template.disabled = true;
        pieSeries.legendSettings.itemLabelText = "{category}: {value}";
        chart.logo.disabled = true;
        chart.legend = new am4charts.Legend();
        chart.legend.maxHeight = 150;
        chart.legend.valueLabels.template.disabled = true;
        chart.legend.fontSize = 12;
        chart.legend.useDefaultMarker = true;
        let marker = chart.legend.markers.template.children.getIndex(0);
        marker.cornerRadius(12, 12, 12, 12);

        // Set colors based on label
        pieSeries.slices.template.adapter.add("fill", function(fill, target) {
            switch(target.dataItem.dataContext.label.toLowerCase()) {
                case "good":
                    return am4core.color("#5DC35C"); // Green
                case "average":
                    return am4core.color("#ffa500"); // Orange
                case "poor":
                    return am4core.color("#ff0000"); // Red
                default:
                    return fill;
            }
        });

        chartRef.current = chart;

        return () => {
            chart.dispose();
        };

    }, [roadConditionPieChartData]);




  return (
    <div className='w-full h-full flex flex-col'>
        <div className='pieChartTitle font-poppins font-semibold leading-9 text-center mt-2 text-xl'>
            Road Condition
        </div>
        <div id='pieCharts' className='flex-grow'>
            {!(roadConditionPieChartData && Object.keys(roadConditionPieChartData).length > 0) && (
                <div>No Road Condition Data Available!</div>
            )}
        </div>
        <div className='mt-4 font-poppins flex justify-center font-normal text-xs hover:scale-105 underline cursor-pointer'
            onClick={() => navigate("/listview")}
        >
            View Details
        </div>
    </div>
  )
}

export default RoadConditionStats