//pointAPI
import { apiConnector } from '../../../usermanagement/services/apiConnector';
import { pointendpoints } from '../apis';
import { setHeatMapPointsData, setHeatMapLoading, setLoading, setPointsData } from '../../slices/pointSlice';
import toast from 'react-hot-toast';

export const getPoints = () => {
    return async (dispatch, getState) => {
        const { token } = getState().auth
        dispatch(setLoading(true))
        // const toastId = toast.loading('Loading..');
        let result = []
        try {
            const response = await apiConnector(
                'GET',
                pointendpoints.GET_POINTS_BY_USER,
                null,
                { authorization: token }
            )

            if (response.status === 200) {
                result = response?.data
            }

            // dispatch(setPointStatus(response.status))
            dispatch(setPointsData(response.data.result))
        } catch (error) {
            console.error('Error in getting Points', error)
        }
        dispatch(setLoading(false))
        // toast.dismiss(toastId);
    }
}

export const getHeatMapPoints = () => {
	return async (dispatch, getState) => {
		const { token } = getState().auth;
		dispatch(setHeatMapLoading(true));
		// const toastId = toast.loading('Loading..');
		let result = [];
		try {
			const response = await apiConnector(
				'GET',
				pointendpoints.GET_HEATMAP_POINTS_BY_USER,
				null,
				{ authorization: token }
			);
  
			if (response.status === 200) {
				result = response?.data;
			}

			// dispatch(setPointStatus(response.status))
			dispatch(setHeatMapPointsData(response.data.result));
		} catch (error) {
            toast.error('Some error happened in plotting Heat Map!');
			console.error('Error in getting Points', error);
		}
		dispatch(setHeatMapLoading(false));
		// toast.dismiss(toastId);
	};
};

export const getSingleRoadHeatMap = (roadName, roadNo) => {
    return async (dispatch, getState) => {
        const { token } = getState().auth

        let result = []
        try {
            const response = await apiConnector(
                "GET",
                pointendpoints.GET_HEATMAP_SINGLE_ROAD,
                null,
                { authorization: token },
                { roadName, roadNo }
            )

            if (response.status === 200) {
                result = response?.data?.result
            }
        } catch (error) {
            //console.log('Error in getting Points', error);
        }

        return result
        // dispatch(setHeatMapLoading(false))
    }
}

export const getSinglePointData = (imgData) => {
    return async (dispatch, getState) => {
        const { token } = getState().auth
        dispatch(setLoading(true))
        let result = []
        let query = {}

        Object.keys(imgData).forEach((key) => {
            // Check if the value is not an empty string
            if (imgData[key] !== '') {
                query[key] = imgData[key]
            }
        })
        try {
            const response = await apiConnector(
                'GET',
                pointendpoints.GET_IMAGE_BY_LAT_LONG,
                null,
                { Authorization: token },
                // { lat: query.lat, long: query.long }
                query
            )

            result = response?.data.data
        } catch (error) {
            console.error('Error in getting onclick point data', error)
        }
        dispatch(setLoading(false))
        return result
    }
}
