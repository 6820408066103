//Done UserVerifyIcons
import Group from './UserVerifyIcons/Group.png'
import Person from './UserVerifyIcons/person.svg'
// import Badge from './UserVerifyIcons/Badge.svg';
import SourceEnv from './UserVerifyIcons/source_env.svg'
import Folder from './UserVerifyIcons/folder.svg'
// import Approval from './UserVerifyIcons/approval.svg';
import PhotoCameras from './UserVerifyIcons/photo_camera.svg'
// import dot from './UserVerifyIcons/dot.svg';
import packageicon from './UserVerifyIcons/package.svg'

//Done(assets\rastaIcons\miscellaneous)
import front from './User/Front.jpg'
import back from './User/Back.jpg'
import grpdf from './Pdf/GovtGR.pdf'
import { AlarmOnTwoTone, PhotoCamera } from '@mui/icons-material'
import frontGovt from './User/FrontGovtId.jpg'
import backGovt from './User/BackGovtId.jpg'

//Done (\assets\rastaIcons\filterIcons)
// filter icons -> Conditions
import Rasta from '../assets/innerSidebar/rasta-ai.png'
import CloseBtn from '../assets/img/CloseBtn.png'
// Points and Segments
// import All from '../assets/img/All_icon.svg';
// import Good from '../assets/img/Good_icon.svg';
// import Bad from '../assets/img/Bad_icon.svg';
// import Average from '../assets/img/Average_icon.svg';
import Unassessed from '../assets/img/Unassessed_icon.svg'
import SeverePothole from '../assets/img/Severe_Potholes_icon.svg'
import MajorPothole from '../assets/img/Major_Potholes_icon.svg'
import MinorPothole from '../assets/img/Minor_Potholes_icon.svg'
import Patch from '../assets/img/Patchwork.svg'
import SlabAlignment from '../assets/img/Slab_Alignment_icon.svg'
// import Crack from '../assets/img/Cracks_icon.svg';
import Ravelling from '../assets/img/Ravelling_icon.svg'
import MajorJunction from '../assets/img/Major_Junctions_icons.svg'
// Other Conditions
import ZebraCrossing from '../assets/img/Zebra-crossing_icon.svg'
import Manhole from '../assets/img/Man_hole.png'
import Rutting from '../assets/img/Rutting_icon.svg'
// import Bleeding from '../assets/img/Bleeding_icon.svg';
import DamagedZebraCrossing from '../assets/img/Damaged_Zebra_Crossing_icon_Babel.png'
// import SpeedBreaker from '../assets/img/Speed_Breakers_icon.svg';
import DamagedDivider from '../assets/img/Damaged_Divider_Icon_Babel.png'
import RumblingStrip from '../assets/img/Rumbling_Strips_icon.svg'

//StatisticBar icons
import RoadPatch from '/src/assets/img/roadpatch.png'
import MajorPotholeStat from '/src/assets/img/major-pothole.png'
import MinorPotholeStat from '/src/assets/img/minor-pothole.png'
import CautionarySign from '/src/assets/img/cautionary-sign.png'
import InfoSign from '/src/assets/img/informatory-sign.png'
import LeftChevron from '/src/assets/StatisticsBar/left-chevron.png'
import RightChevron from '/src/assets/StatisticsBar/right-chevron.png'
import MandatorySignStat from '/src/assets/img/mandatory-sign.png'
import HotSpot from '/src/assets/img/hotspot.png'
import SpeedBreakerStat from '/src/assets/img/speed-breaker.png'

import StatisticsIcon from '../assets/img/StatisticsIcon.png'

//Done (\assets\rastaIcons\filterIcons)
// filter icons -> Features
// import AllFeature from '../assets/img/Feature_All_icon.svg';
import MandatorySign from '../assets/img/MandatorySign.svg'
import InformatorySign from '../assets/img/Informatory_Signs_icon_.svg'
import GantryBoard from '../assets/img/Gantry_boards_icon.svg'
//Road Assets
import MajorBidge from '../assets/img/Major_Bridge_icon.svg'
import MinorBridge from '../assets/img/Minor_Bridge_icon.svg'
import Culvert from '../assets/img/Culverts_icon.svg'
import ROB from '../assets/img/ROB_icon.svg'
import RUB from '../assets/img/RUB_icon.svg'
import Flyover from '../assets/img/Flyovers_icon.svg'
// import StreetLight from '../assets/img/Street_Lights_icon.svg';
import FootwayDamage from '../assets/img/Footways_Damage_icon.svg'
import SmartToilet from '../assets/img/Smart_Toilets_icon.svg'
import Tree from '../assets/img/trees_icon.svg'
import BusStop from '../assets/img/Bus_Stops_icon.svg'
import PetrolPump from '../assets/img/Petrol_Pumps_icon.svg'
// import TrafficSignal from '../assets/img/traffic-lights.png'
import DamagedSignBoard from '../assets/img/Damaged_Sign_Boards_icon.svg'
import RiverCrossing from '../assets/img/River_Crossing_icon.svg'
import Tunnel from '../assets/img/Tunnel_icon.svg'
import { minHeight } from '@mui/system'

//Done (assets/sideBars/innersideBars)
// inner sidebar
// import RastaNav from './innerSidebar/Rasta.png';
// import Dash from './innerSidebar/Dashboard.png';
// import ActiveDash from './innerSidebar/ActiveDashboard.png';
// import User from './innerSidebar/User.png';
// import ActiveUser from './innerSidebar/ActiveUser.png';
// import Home from './innerSidebar/Home.png';
// import ActiveHome from './innerSidebar/ActiveHome.png';
// import TripApproval from './innerSidebar/TripApprovalIcon.png';
import ActiveTripApproval from './innerSidebar/ActiveTripApproval.png'
// import Setting from './innerSidebar/Settings.png';
// import ActiveSetting from './innerSidebar/ActiveSettings.png';
// import Activeroad from './innerSidebar/ActiveRoad.png';
import Activeroad from './img/roadOrange.svg'
// import RoadWhite from './innerSidebar/roadWhite.png';
// import Office from './innerSidebar/Office.svg'
// import ActiveOffice from './innerSidebar/ActiveOffice.svg';

//Done(assets\rastaIcons\searchAndSurvey\singleMap)
// single map
// import Comparison from './map/Comparison.png';
// import ListView from './map/Listview.png';
// import MapSearch from './sidebar/searchC.png'

//Done (assets\rastaIcons\utilities\unVerified)
// UserManagement
import ProfilePhoto from './UserVerifyIcons/ProfilePhoto.svg'
import VerifyMark from './UserVerifyIcons/VerifiedMark.svg'
import Pencil from './UserVerifyIcons/Pencil.svg'
import NotVerify from './UserVerifyIcons/NotVerified.svg'
import SearchIcon from './UserVerifyIcons/SearchIcon.svg'
import cleanSearchIcon from './img/searchicon.svg'
import downloadReport from './img/downloadReport.svg'
import csvReportIcon from './img/csvReport.svg'

//Done (assets\statisticsBar)
import RoadCondition from './StatisticsBar/roadCondition.png'
import InventoryCondition from './StatisticsBar/inventoryCondition.png'
import CountRoadsToRepair from './StatisticsBar/countsroadtorepair.png'
import CountPotholes from './StatisticsBar/countOfPothole_.png'
import MajorBridges from './StatisticsBar/majorBridges.svg'
import MinorBridges from './StatisticsBar/minorBridges_.png'
import Culverts from './StatisticsBar/culverts.png'

//Done(assets\mapIcons\pointDetails)
//pointDetails Icons:
import PointPrevious from '../assets/img/SingleMapIcons/prevIcon.png'
import PointNext from '../assets/img/SingleMapIcons/nextIcon.png'
import PointPlay from '../assets/img/SingleMapIcons/play_circle.png'
import PointComment from '../assets/img/SingleMapIcons/message.png'
import PointFullScreen from '../assets/img/SingleMapIcons/fullscreen_exit.png'
import PointGoogleMap from '../assets/img/SingleMapIcons/send.png'
import PointMaximize from '../assets/img/SingleMapIcons/maximize.png'
import PointLocation from '../assets/img/SingleMapIcons/locations.png'
import PointRoadName from '../assets/img/SingleMapIcons/roadnameicon.png'
import PointLatLong from '../assets/img/SingleMapIcons/latlon.png'
// import PointVideoView from '../assets/img/SingleMapIcons/play_circle.png'
import PointDateTime from '../assets/img/SingleMapIcons/calender.png'
import PointDefect from '../assets/img/SingleMapIcons/defect.svg'
import PointChainage from '../assets/img/SingleMapIcons/chainage.png'
import PointImgZoomIn from '../assets/img/SingleMapIcons/zoom_out_map.svg'

//Images to be plotted on the Map
import greenPointOnMap from '../assets/ImgToPlotOnMap/greentick.png'
import streetLightOnMap from '../assets/ImgToPlotOnMap/double street light.png'
import informatoryBoardOnMap from '../assets/ImgToPlotOnMap/informatory board.png'
import leftChevronOnMap from '../assets/StatisticsBar/left-chevron-plot.png'
import rightChevronOnMap from '../assets/StatisticsBar/right-chevron -plot.png'
import trafficLightOnMap from '../assets/ImgToPlotOnMap/traffic light.png'
import rightChevronOnFeature from '../assets/ImgToPlotOnMap/right chevron.svg'
import leftChevronOnFeature from '../assets/ImgToPlotOnMap/left chevron.svg'
import mandatorySignOnMap from '../assets/ImgToPlotOnMap/mandatory sign.png'
import cautionarySignOnMap from '../assets/ImgToPlotOnMap/Cautionary sign.png'
import informatorySignOnMap from '../assets/ImgToPlotOnMap/Informatory_Signs_icon.png'
import commenticon from '../assets/ImgToPlotOnMap/500x500/commenticon3.png'

// core
import Not_Found from '../assets/MainImages/404_Not_Found.svg'

//Preloader
import preLoaderImage from './favicon_logo1.png'

// static images
import electricFeeder from '../assets/img/Electric Feeder.png'
import electricfeeder from '../assets/img/electricfeeder.png'
import gantryBoard from '../assets/img/gantry-board.png'
import pedestrianCrossing from '../assets/img/pedestrian-crossing.png'
import tree from '../assets/img/Trees.png'
import rumbling from '../assets/img/rumbling-street.png'
import cctv2 from '../assets/img/cctv2.png'
import hoarding from '../assets/img/Hoardings.png'
import enchroachment from '../assets/img/Encroachment.png'
import workInProgress from '../assets/img/Work In Progress.png'
import metroStation from '../assets/img/Metro Station.png'
import crack from '../assets/img/crack.png'

export const iconMap = {
    firstName: Group,
    lastName: Group,
    person: Person,
    badge: 'https://rasta-icons.s3.ap-south-1.amazonaws.com/assets/rastaIcons/utilities/unVerified/Badge.svg', //Badge
    soureenv: SourceEnv,
    approval:
        'https://rasta-icons.s3.ap-south-1.amazonaws.com/assets/rastaIcons/utilities/unVerified/approval.svg', //Approval,
    designation: PhotoCameras,
    posting: packageicon,
    folder: Folder,
    threeDot:
        'https://rasta-icons.s3.ap-south-1.amazonaws.com/assets/rastaIcons/utilities/unVerified/dot.svg', //dot,
    frontImg: front,
    backImg: back,
    grpdf: grpdf,
    frontGovt: frontGovt,
    backGovt: backGovt,
}

export const filterIcons = {
    //Condition
    all: 'https://rasta-icons.s3.ap-south-1.amazonaws.com/assets/rastaIcons/filterIcons/conditions/All_icon.svg', //All,
    good: 'https://rasta-icons.s3.ap-south-1.amazonaws.com/assets/rastaIcons/filterIcons/conditions/Good_icon.svg', //Good
    bad: 'https://rasta-icons.s3.ap-south-1.amazonaws.com/assets/rastaIcons/filterIcons/conditions/Bad_icon.svg', //Bad
    average:
        'https://rasta-icons.s3.ap-south-1.amazonaws.com/assets/rastaIcons/filterIcons/conditions/Average_icon.svg', //Average,
    unassessed: Unassessed,
    severepothole: SeverePothole,
    majorpothole: MajorPothole,
    minorpothole: MinorPothole,
    patch: Patch,
    slabalignment: SlabAlignment,
    crack: 'https://rasta-icons.s3.ap-south-1.amazonaws.com/assets/rastaIcons/filterIcons/conditions/Cracks_icon.svg', //Crack,
    ravelling: Ravelling,
    majorjunction: MajorJunction,
    zebracrossing: ZebraCrossing,
    manhole: Manhole,
    rutting: Rutting,
    bleeding:
        'https://rasta-icons.s3.ap-south-1.amazonaws.com/assets/rastaIcons/filterIcons/conditions/Bleeding_icon.svg', //Bleeding,
    damagedzebracrossing: DamagedZebraCrossing,
    speedbreaker:
        'https://rasta-icons.s3.ap-south-1.amazonaws.com/assets/rastaIcons/filterIcons/conditions/Speed_Breakers_icon.svg', //SpeedBreaker,
    damageddivider: DamagedDivider,
    rumblingstrip: RumblingStrip,
    //Segement

    //Feature
    allfeature:
        'https://rasta-icons.s3.ap-south-1.amazonaws.com/assets/rastaIcons/filterIcons/features/Feature_All_icon.svg',
    mandatorysign: MandatorySign,
    informatorysign: InformatorySign,
    gantryboard: GantryBoard,
    majorbridge: MajorBidge,
    minorbridge: MinorBridge,
    culvert: Culvert,
    rob: ROB,
    rub: RUB,
    flyover: Flyover,
    streetlight:
        'https://rasta-icons.s3.ap-south-1.amazonaws.com/assets/rastaIcons/filterIcons/roadAssets/Street_Lights_icon.svg', //StreetLight,
    footwaydamage: FootwayDamage,
    smarttoilet: SmartToilet,
    tree: Tree,
    busstop: BusStop,
    petrolpump: PetrolPump,
    trafficsignal:
        'https://rasta-icons.s3.ap-south-1.amazonaws.com/assets/rastaIcons/filterIcons/roadAssets/traffic-lights.png', //TrafficSignal,
    damagedsignboard: DamagedSignBoard,
    rivercrossing: RiverCrossing,
    tunnel: Tunnel,
}

export const DetailedReportIcons = {
    DownloadIcon: downloadReport,
    SearchIcon: cleanSearchIcon,
    CSVReportIcon: csvReportIcon,
}

export const innerSidebarIcons = {
    dash: 'https://rasta-icons.s3.ap-south-1.amazonaws.com/assets/sideBars/innerSideBar/Dashboard.png', //Dash
    activeDash:
        'https://rasta-icons.s3.ap-south-1.amazonaws.com/assets/sideBars/innerSideBar/ActiveDashboard.png', //ActiveDash,
    home: 'https://rasta-icons.s3.ap-south-1.amazonaws.com/assets/sideBars/innerSideBar/Home.png', //Home
    activeHome:
        'https://rasta-icons.s3.ap-south-1.amazonaws.com/assets/sideBars/innerSideBar/ActiveHome.png', //ActiveHome
    user: 'https://rasta-icons.s3.ap-south-1.amazonaws.com/assets/sideBars/innerSideBar/User.png', //User,
    activeUser:
        'https://rasta-icons.s3.ap-south-1.amazonaws.com/assets/sideBars/innerSideBar/ActiveUser.png', //ActiveUser
    setting:
        'https://rasta-icons.s3.ap-south-1.amazonaws.com/assets/sideBars/innerSideBar/Settings.png', //Setting
    activeSetting:
        'https://rasta-icons.s3.ap-south-1.amazonaws.com/assets/sideBars/innerSideBar/ActiveSettings.png', //ActiveSetting
    tripApproval:
        'https://rasta-icons.s3.ap-south-1.amazonaws.com/assets/sideBars/innerSideBar/TripApprovalIcon.png', //TripApproval
    activeTrip:
        'https://rasta-icons.s3.ap-south-1.amazonaws.com/assets/sideBars/innerSideBar/ActiveTripApproval.png', //ActiveTripApproval
    rastaLogo:
        'https://rasta-icons.s3.ap-south-1.amazonaws.com/assets/sideBars/innerSideBar/Rasta.png', //RastaNav
    road: Activeroad, //"https://rasta-icons.s3.ap-south-1.amazonaws.com/assets/sideBars/innerSideBar/ActiveRoad.png",
    roadWhite:
        'https://rasta-icons.s3.ap-south-1.amazonaws.com/assets/sideBars/innerSideBar/roadWhite.png', //RoadWhite
    office: 'https://rasta-icons.s3.ap-south-1.amazonaws.com/assets/sideBars/innerSideBar/Office.svg', //Office
    activeOffice:
        'https://rasta-icons.s3.ap-south-1.amazonaws.com/assets/sideBars/innerSideBar/ActiveOffice.svg', //ActiveOffice
}

export const SingleMapIcons = {
    comparison:
        'https://rasta-icons.s3.ap-south-1.amazonaws.com/assets/rastaIcons/searchAndSurvey/singleMap/Comparison.png', //Comparison
    listview:
        'https://rasta-icons.s3.ap-south-1.amazonaws.com/assets/rastaIcons/searchAndSurvey/singleMap/Listview.png', //ListView
    mapsearch:
        'https://rasta-icons.s3.ap-south-1.amazonaws.com/assets/rastaIcons/searchAndSurvey/singleMap/searchC.png', //MapSearch
    statistics: StatisticsIcon,
}

export const UserProfileIcons = {
    profilephoto: ProfilePhoto,
    verifyMark: VerifyMark,
    editProfile: Pencil,
    notverify: NotVerify,
    search: SearchIcon,
}

export const titles = [
    {
        name: 'Road Condition',
        icon: RoadCondition,
        alt: RoadCondition,
    },
    {
        name: 'Inventory Condition',
        icon: InventoryCondition,
        alt: InventoryCondition,
    },
    {
        name: 'Roads Repair',
        icon: CountRoadsToRepair,
        alt: CountRoadsToRepair,
        count: 10,
    },
    {
        name: 'Count of Potholes',
        icon: CountPotholes,
        alt: CountPotholes,
        count: 10,
    },
    {
        name: 'Major Bridges',
        icon: MajorBridges,
        alt: MajorBridges,
        count: 10,
    },
    {
        name: 'Minor Bridges',
        icon: MinorBridges,
        alt: MinorBridges,
        count: 10,
    },
    {
        name: 'Culverts',
        icon: Culverts,
        alt: Culverts,
        count: 10,
    },
]

export const listmode = {
    rasta: Rasta,
}

export const closeImg = {
    closeImg: CloseBtn,
}

export const pointDetailIcons = {
    pointprev: PointPrevious,
    pointnext: PointNext,
    pointplay: PointPlay,
    pointcomment: PointComment,
    // pointfullscreen:PointFullScreen,
    pointfullscreen: PointImgZoomIn,
    pointgooglemap: PointGoogleMap,
    pointmaximize: PointMaximize,
    pointlocation: PointLocation,
    pointroadname: PointRoadName,
    pointlatlong: PointLatLong,
    pointdatetime: PointDateTime,
    pointdefect: PointDefect,
    pointchainage: PointChainage,
}

export const core = {
    notfound: Not_Found,
}

export const ImagesOnMap = {
    streetlightonmap: streetLightOnMap,
    greenpointonmap: greenPointOnMap,
    informatoryboardonmap: informatoryBoardOnMap,
    leftchevrononmap: leftChevronOnMap,
    rightchevrononmap: rightChevronOnMap,
    trafficlightonmap: trafficLightOnMap,
    leftchevrononfeature: leftChevronOnFeature,
    rightchevrononfeature: rightChevronOnFeature,
    mandatorysignonmap: mandatorySignOnMap,
    cautionarysignonmap: cautionarySignOnMap,
    informatorysignonmap: informatoryBoardOnMap,
    electicfeederonmap: electricFeeder,
    gantryboardonmap: gantryBoard,
    pedestraincrossingonmap: pedestrianCrossing,
    treesonmap: tree,
    speedbreakeronmap: SpeedBreakerStat,
    rumblingstriponmap: rumbling,
    cctvonmap: cctv2,
    busstoponmap: BusStop,
    hoardingonmap: hoarding,
    encroachmentonmap: enchroachment,
    workinprogressonmap: workInProgress,
    metrostationonmap: metroStation,
    commenticononmap: commenticon,
}

export const PreloaderImage = {
    PreloaderImage: preLoaderImage,
}

export const statisticsBarIcons = {
    roadCondition: RoadCondition,
    cautionarySign: CautionarySign,
    hotspot: HotSpot,
    infosign: InfoSign,
    leftchevron: LeftChevron,
    rightchevron: RightChevron,
    mandatorysign: MandatorySignStat,
    manhole: Manhole,
    minorpothole: MinorPotholeStat,
    majorpothole: MajorPotholeStat,
    roadpatch: RoadPatch,
    speedbreaker: SpeedBreakerStat,
    streetlamp:
        'https://rasta-icons.s3.ap-south-1.amazonaws.com/assets/rastaIcons/filterIcons/roadAssets/Street_Lights_icon.svg',
    trafficsignal:
        'https://rasta-icons.s3.ap-south-1.amazonaws.com/assets/rastaIcons/filterIcons/roadAssets/traffic-lights.png',
    electricfeeder: electricfeeder,
    gantryboard: gantryBoard,
    pedestriancrossing: pedestrianCrossing,
    tree: tree,
    rumblingstrip: rumbling,
    cctv: cctv2,
    busstop: BusStop,
    hoarding: hoarding,
    encroachment: enchroachment,
    workinprogress: workInProgress,
    metrostation: metroStation,
    crack: crack,
    //divider
    //   trench
    //   raveling:
    //   footpath:
}
