import { useState, useEffect } from 'react'
import './DetailedRoadReport.css'
import HeaderAndFooter from './HeaderAndFooter'
import DataOne from '../Details/DataOne'
import DataTwo from './DataTwo'
import DataThree from './DataThree'
import { useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import {
    getDetailOfAllRoad,
} from '../../../../usermanagement/services/Operations/CoreAPIs/reportAPI'
import FirstPage from '../Details/FirstPage'
import { Spinner } from '../../../../utils/Spinner'
import Abbreviation from '../Details/Abbreviation.jsx'
import { useNavigate } from 'react-router-dom'
import toast from 'react-hot-toast'

function DetailedRoadReport() {
    const location = useLocation()
    const dispatch = useDispatch()
    const { officeLevel, officeName, roadName, roadNo } = location.state || {}
    const [listOfRoad, setListOfRoad] = useState([])
    const [detailOfRoad, setDetailOfRoad] = useState([])
    const [detailChainage, setDetailChainage] = useState([])
    const [higherAuthority, setHigherAuthority] = useState(null)

    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)

    useEffect(() => {

        async function fetchReportData() {
            try {
                const reportData = await dispatch(
                    getDetailOfAllRoad(officeLevel, officeName, roadName, roadNo)
                )

                setListOfRoad(reportData.roadList);
                setDetailOfRoad(reportData.detailedReportData?.data ?? reportData.detailedReportData);
                setDetailChainage(reportData.chainageData);
                setHigherAuthority(reportData.higherAuth);

            } catch (error) {
                console.error("Error while getting road report Data: ", error);
            }
        }

        const fetchData = async () => {
            setLoading(true)
            try {
                await fetchReportData();
            } catch (error) {
                setError(error)
                console.error(
                    'Error in Detailed Road Report Generation: ',
                    error
                )
            } finally {
                setLoading(false)
            }
        }

        fetchData()
    }, [officeLevel, officeName, roadName, roadNo])

    if (error) {
        toast.error(
            'Some error occurred while generating Detailed Road Report!'
        )
        navigate('/report')
    }


    return (
        <>
            {loading ? (
                <div className="w-[100vw] h-[100vh] flex justify-center items-center">
                    <Spinner />
                </div>
            ) : (
                // <div className="w-[100vw] h-[100vh] flex justify-center items-center">
                <div className="display_in_print">
                    <HeaderAndFooter loading={loading}>
                        <div className="w-full text-justify">
                            <FirstPage
                                clientName={
                                    higherAuthority ? higherAuthority : roadName
                                }
                            />
                        </div>
                        <div className="w-full text-justify page-break">
                            <Abbreviation />
                        </div>
                        <div className="w-full text-justify page-break">
                            <DataOne />
                        </div>
                        <div className="page-break">
                            <DataTwo listOfRoad={listOfRoad} />
                        </div>
                        <div className="page-break">
                            <DataThree
                                detailOfRoad={detailOfRoad}
                                detailChainage={detailChainage}
                            />
                        </div>
                    </HeaderAndFooter>
                </div>
                // </div>
            )}
        </>
    )
}

export default DetailedRoadReport
