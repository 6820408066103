import { apiConnector } from '../../apiConnector'
import { reportendpoints } from '../../apis'

export const getOverviewReport = () => {
    return async (dispatch, getState) => {
        const { token } = getState().auth
        // dispatch(setLoading(true));
        let result = []
        try {
            const response = await apiConnector(
                'GET',
                reportendpoints.GET_OVERVIEW_REPORT_DETAILS,
                null,
                { authorization: token }
            )

            result = response?.data
        } catch (error) {
            console.error(
                'Error in getting overview report data :: getOverviewReport',
                error
            )
            throw error
        }
        // dispatch(setLoading(false));
        return result
    }
}

export const getListOfAllRoad = (officeLevel, officeName, roadName, roadNo) => {
    return async (dispatch, getState) => {
        const { token } = getState().auth
        // dispatch(setLoading(true));
        let result = []
        let params = {}

        if (officeLevel === 'Road') {
            params = {
                roadName,
                roadNo,
            }
        } else {
            params = {
                officeLevel,
                officeName,
            }
        }
        try {
            const response = await apiConnector(
                'GET',
                reportendpoints.GET_LIST_OF_ROADS,
                null,
                { authorization: token },
                params
            )
            result = response?.data
        } catch (error) {
            console.error(
                'Error in getting lists of road data :: getListOfAllRoad',
                error
            )
            throw error
        }
        // dispatch(setLoading(false));
        return result
    }
}

export const getDetailOfAllRoad = (
    officeLevel,
    officeName,
    roadName,
    roadNo
) => {
    return async (dispatch, getState) => {
        const { token } = getState().auth
        // dispatch(setLoading(true));
        let result = []
        let params = {}

        if (officeLevel === 'Road') {
            params = {
                roadName,
                roadNo,
            }
        } else {
            params = {
                officeLevel,
                officeName,
            }
        }
        try {
            const response = await apiConnector(
                'GET',
                reportendpoints.GET_DETAIL_ROAD,
                null,
                { authorization: token },
                params
            )
            result = response?.data
        } catch (error) {
            console.error(
                'Error in getting details of all roads :: getDetailOfAllRoad',
                error
            )
            throw error
        }
        // dispatch(setLoading(false));
        return result
    }
}

export const getPciOfAllRoad = (
    officeLevel,
    officeName,
    roadName,
    roadNo
) => {
    return async (dispatch, getState) => {
        const { token } = getState().auth
        // dispatch(setLoading(true));
        let result = []
        let params = {}

        if (officeLevel === 'Road') {
            params = {
                roadName,
                roadNo,
            }
        } else {
            params = {
                officeLevel,
                officeName,
            }
        }
        try {
            const response = await apiConnector(
                'GET',
                reportendpoints.GET_PCI_ROAD,
                null,
                { authorization: token },
                params
            )
            result = response?.data
        } catch (error) {
            console.error(
                'Error in getting details of all roads :: getPciOfAllRoad',
                error
            )
            throw error
        }
        // dispatch(setLoading(false));
        return result
    }
}

export const getMaintenanceReport = (officeLevel,
    officeName,
    roadName,
    roadNo) => {
        return async (dispatch, getState) => {
            const { token } = getState().auth
            // dispatch(setLoading(true));
            let result = []
            let params = {}
    
            if (officeLevel === 'Road') {
                params = {
                    roadName,
                    roadNo,
                }
            } else {
                params = {
                    officeLevel,
                    officeName,
                }
            }
            try {
                const response = await apiConnector(
                    'GET',
                    reportendpoints.GET_MAINTENANCE_ROAD,
                    null,
                    { authorization: token },
                    params
                )
                result = response?.data
            } catch (error) {
                console.error(
                    'Error in getting details of all roads :: getMaintenanceOfAllRoad',
                    error
                )
                throw error
            }
            // dispatch(setLoading(false));
            return result
        }

}

export const getDetailOfAllChainageForRoad = (
    officeLevel,
    officeName,
    roadName,
    roadNo
) => {
    return async (dispatch, getState) => {
        const { token } = getState().auth
        // dispatch(setLoading(true));
        let result = []
        let params = {}

        if (officeLevel === 'Road') {
            params = {
                roadName,
                roadNo,
            }
        } else {
            params = {
                officeLevel,
                officeName,
            }
        }
        try {
            const response = await apiConnector(
                'GET',
                reportendpoints.GET_DETAIL_OF_CHAINAGE,
                null,
                { authorization: token },
                params
            )
            result = response?.data
        } catch (error) {
            console.error(
                'Error in getting chainage wise data :: getDetailOfAllChainageForRoad',
                error
            )
            throw error
        }
        // dispatch(setLoading(false));
        return result
    }
}

export const getHigherAuthority = (
    officeLevel,
    officeName,
    roadName,
    roadNo
) => {
    return async (dispatch, getState) => {
        const { token } = getState().auth
        // dispatch(setLoading(true));
        let result = []
        let params = {}

        if (officeLevel === 'Road') {
            params = {
                roadName,
                roadNo,
            }
        } else {
            params = {
                officeLevel,
                officeName,
            }
        }
        try {
            const response = await apiConnector(
                'GET',
                reportendpoints.GET_AUTHORITY,
                null,
                { authorization: token },
                params
            )
            result = response?.data
        } catch (error) {
            console.error(
                'Error in getting higher authority data :: getHigherAuthority',
                error
            )
            throw error
        }
        // dispatch(setLoading(false));
        return result
    }
}

export const getCSVArchive = (
    officeLevel,
    officeName,
    roadName,
    roadNo,
    formData
) => {
    return async (dispatch, getState) => {
        const { token } = getState().auth

        let body = {}

        if (officeLevel === 'Road') {
            body = {
                roadName,
                roadNo,
                formData,
            }
        } else {
            body = {
                officeLevel,
                officeName,
                formData,
            }
        }

        try {
            // Fetch CSV files zip
            const response = await apiConnector(
                'POST',
                reportendpoints.GENERATE_CSV_REPORT,
                body,
                { authorization: token },
                null,
                'blob'
            )

            return response
        } catch (error) {
            console.error(
                'getCSVArchive :: Error in getting CSV zip file:',
                error
            )
            throw error
        }
    }
}
