import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { getCSVArchive } from '../../../../usermanagement/services/Operations/CoreAPIs/reportAPI'
import { Spinner } from '../../../../utils/Spinner'
import { FaDownload } from 'react-icons/fa'
import toast from 'react-hot-toast'
import { CSVConsentForm } from './CSVConsentForm'
import { BackButton } from '../../../Utils/BackButton'
import { DetailedReportIcons } from '../../../../assets/IconArray'

function CSVRoadReport() {
    const location = useLocation()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [formData, setFormData] = useState(null)
    const [error, setError] = useState(null)
    const { officeLevel, officeName, roadName, roadNo } = location.state || {}

    const downloadZipFile = async () => {
        setLoading(true)
        try {
            const response = await dispatch(
                getCSVArchive(
                    officeLevel,
                    officeName,
                    roadName,
                    roadNo,
                    formData
                )
            )

            if (response.status !== 200) {
                throw new Error('Network response was not ok')
            }

            // Extract the filename from the Content-Disposition header
            const contentDisposition = response.headers['content-disposition']

            let filename = 'RastaAI-network-report.zip'

            if (contentDisposition) {
                const match = contentDisposition.match(
                    /filename[^;=\n]*=['"]?([^'";\n]*)['"]?/i
                )
                if (match && match[1]) {
                    filename = 'RastaAI-report-' + match[1].replace(/"/g, '')
                }
            }

            const blob = new Blob([response.data], { type: 'application/zip' })
            const url = window.URL.createObjectURL(blob)

            const a = document.createElement('a')
            a.href = url
            a.download = filename
            document.body.appendChild(a)
            a.click()

            window.URL.revokeObjectURL(url)
            document.body.removeChild(a)

            navigate('/report')
        } catch (error) {
            setError(error)
            console.error('CSVRoadReport: ', error)
        } finally {
            setLoading(false)
        }
    }

    if (error) {
        toast.error('Some error occurred while generating CSV Report!')
        navigate('/report')
    }

    return (
        <div className="flex justify-center items-center h-screen w-screen">
            <div className="absolute top-0 left-0 w-full flex p-8">
                <BackButton direct={-1} />
            </div>
            <div className="flex justify-center items-center font-poppins h-[max(50%,30rem)] w-[min(90%,40rem)] border-2 border-grey-300 rounded-lg shadow-md p-4">
                {loading ? (
                    <Spinner height={'full'} />
                ) : formData && Object.keys(formData).length > 0 ? (
                    <div className="flex flex-col justify-center items-center gap-10 w-[90%]">
                        <div className="h-[30%]">
                            <img src={DetailedReportIcons.CSVReportIcon} />
                        </div>
                        <button
                            className="flex items-center bg-primary rounded-md hover:bg-orange-600 text-white w-[min(90%,20rem)] justify-center p-3"
                            onClick={downloadZipFile}
                        >
                            <FaDownload className="w-6 h-6 hover:scale-110 mr-4" />
                            Download CSV Report
                        </button>
                    </div>
                ) : (
                    <CSVConsentForm
                        formData={formData}
                        setFormData={setFormData}
                    />
                )}
            </div>
        </div>
    )
}

export default CSVRoadReport
