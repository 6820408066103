// MapInitializer.js
import { useEffect, useRef, useState } from 'react'
import mapboxgl from 'mapbox-gl'
import { useMap } from '../mapcontexts/MapContext'
import { constant } from '../utils/constant'
import MapboxDraw from '@mapbox/mapbox-gl-draw'
import * as turf from '@turf/turf'
import { IoMdCheckboxOutline } from 'react-icons/io'
import { MdCheckBoxOutlineBlank } from 'react-icons/md'
import { useDispatch } from 'react-redux'
import { getSingleRoadHeatMap } from '../services/Operations/PointsAPI'
import { useParams } from 'react-router-dom'
import { plotPointsHeatMap,removeHeatmapPoints } from '../points/Core/plotHeatmap'
import { setModalData } from '../slices/filterSlice'
import { defectColorTheme,defectColors,
    priorityOrder, } from '../points/Core/PointRules'

const MapInitializer = ({
    onMapLoad,
    sourceId,
    layerId,
    setAssetVisible,
    assetVisible,
    roadName,
    roadNo,
    map,
    pointData
}) => {
    const dispatch = useDispatch()
    const { setMapInstance } = useMap()
    const mapContainer = useRef(null)
    const [drawMode, setDrawMode] = useState('simple_select')
    const [geotype, setGeotype] = useState(null)
    const [roundedArea, setRoundedArea] = useState(0)
    const [roundedDistance, setRoundedDistance] = useState(0)
    const [heatMapData, setHeatMapData] = useState([])
    const [showHeatMap, setShowHeatMap] = useState(false)
    const [mapLoaded, setMapLoaded] = useState(false)
    
    const [heapMapVisible, setHeatMapVisible] = useState(true)
    const { flag, id1, id2 } = useParams()
    

    let ROADNAME=decodeURIComponent(id1)
    let ROADNO=decodeURIComponent(id2)
    
   var dataPart = ROADNO.split('-date-')
   if (dataPart[0]) 
       ROADNO= dataPart[0]
    

    const getHeatmapData = async () => {
        const data = await dispatch(getSingleRoadHeatMap(ROADNAME, ROADNO))
        setHeatMapData(data)
        setShowHeatMap(true)
    }
    function assignColor(defectCounts) {
        let selectedColor = defectColorTheme.green
        const defects = Object.keys(defectCounts)
        for (let i = 0; i < priorityOrder.length; i++) {
            const defect = priorityOrder[i]
            if (defects.includes(defect)) {
                selectedColor = defectColors[defect]
                break
            }
        }
        return selectedColor
    }


    function groupPointsByColor(points) {
        const defaultColor = defectColorTheme.green

        const colorAssignments = points.map((point) => ({
            point,
            color:
                point.pred_image === true &&
                point.defect &&
                Object.keys(point.defect).length > 0
                    ? assignColor(point.defect)
                    : defaultColor,
        }))

        const groups = colorAssignments.reduce((groups, { point, color }) => {
            groups[color] = groups[color] || []
            groups[color].push(point)
            return groups
        }, {})

        return groups
    }


    const removePointsToMap = (points, openModelCallback) => {
        if (!Array.isArray(points) || !map || !mapLoaded) {
            console.error(
                "Invalid points array or Map is not available or not loaded yet"
            )
            return
        }

        const groupedPoints = groupPointsByColor(points)
        // console.log(groupedPoints, "groupedPoints.....")
        Object.entries(groupedPoints).forEach(([color, colorPoints], index) => {
            const sourceId = `${color}-point-source`
            const layerId = `${color}-point-layer`

            // console.log(sourceId, "sourceid.....removing")
            // console.log(layerId, "layerId.....removing")

            //   mapRef = map;

            // map.removeSource(sourceId)
            map.removeLayer(layerId)
        })
    }
    const openModal = (coordinates) => {
        dispatch(setModalData(coordinates))
    }

    
    const addPointsToMap2 = (points, openModalCallback) => {
        if (!Array.isArray(points) || !map || !mapLoaded) {
            console.error(
                "Invalid points array or Map is not available or not loaded yet"
            )
            return
        }

        const groupedPoints = groupPointsByColor(points)

        Object.entries(groupedPoints).forEach(([color, colorPoints], index) => {
            const sourceId = `${color}-point-source`
            const layerId = `${color}-point-layer`

            map.addLayer({
                id: layerId,
                type: "circle",
                source: sourceId,
                paint: {
                    "circle-radius": {
                        base: 1,
                        stops: [
                            [13, 2],
                            [14, 2.6],
                            [15, 3.2],
                            [16.5, 3.2],
                            [17, 5.5],
                            [18, 6],
                            [20, 12],
                            [21, 14],
                            [22, 20],
                        ],
                    },
                    "circle-color": color,
                },
                transition: {
                    duration: 0.8,
                    delay: 0.5,
                },
            })

            map.on("mouseenter", layerId, () => {
                map.getCanvas().style.cursor = "pointer"
            })

            map.on("mouseleave", layerId, () => {
                map.getCanvas().style.cursor = ""
            })

            map.on("click", layerId, (e) => {
                const features = map.queryRenderedFeatures(e.point, {
                    layers: [layerId],
                })
                mapRef = map
                if (features.length > 0) {
                    const clickedFeature = features[0]
                    const coordinates = clickedFeature.properties.coordinates
                    const [lng, lat] = JSON.parse(coordinates)
                    const clickedPoint = [lng, lat]

                    openModalCallback(clickedPoint)

                    const center = map.getCenter()
                    const zoom = map.getZoom()
                    const bearing = map.getBearing()
                    const pitch = map.getPitch()

                    dispatch(
                        setMapState({
                            center: [center.lng, center.lat],
                            zoom,
                            bearing,
                            pitch,
                            marker: clickedPoint,
                        })
                    )

                    if (clickedPoint && !isInAnalytics) {
                        // Remove previous marker if available
                        if (marker) {
                            RemoveMarkerSingleMap()
                        }
                        AddMarkerSingleMap(clickedPoint)
                    }
                }
            })
        })
    }


    const removeHeatMap = () => {
        setHeatMapData([])
        removeHeatmapPoints()
         addPointsToMap2(pointData, openModal)
        setAssetVisible(true)
        setShowHeatMap(false)
    }

    useEffect(() => {
        if (showHeatMap) {
            plotPointsHeatMap(map, heatMapData, "heatmap", setHeatMapData);
            setAssetVisible(false);
            removePointsToMap(pointData, openModal)
        } 
             // Clear heatmap points from the map
        
    }, [showHeatMap, heatMapData])

    useEffect(() => {
        const map = new mapboxgl.Map({
            container: mapContainer.current,
            style: constant[0].style,
            center: [73.71285, 18.59587],
            zoom: 16,
            attributionControl: false,
        })

        const fullscreen = new mapboxgl.FullscreenControl()
        map.addControl(fullscreen, 'bottom-right')

        const nav = new mapboxgl.NavigationControl()
        map.addControl(nav, 'bottom-right')

        const geolocate = new mapboxgl.GeolocateControl({
            positionOptions: {
                enableHighAccuracy: true,
            },
            trackUserLocation: true,
            showUserLocation: true,
            fitBoundsOptions: {
                maxZoom: 14,
            },
        })

        map.addControl(geolocate, 'bottom-right')

        const draw = new MapboxDraw({
            modes: MapboxDraw.modes,
            displayControlsDefault: false,
            controls: {
                line_string: true,
                polygon: true,
                trash: true,
            },
            defaultMode: drawMode, // Set default mode to null
        })

        map.addControl(draw) // Add the draw control to the map

        // Handle draw events
        map.on('draw.selectionchange', updateFeature)
        map.on('draw.create', updateFeature)
        map.on('draw.update', updateFeature)
        map.on('draw.delete', updateFeature)

        function updateFeature(e) {
            const data = draw.getAll()
            //   //console.log("dataaaaaaaaaaaaaaaaaaaaaaaaaaaaaa", data, drawMode);

            let roundedDistance = null
            let roundedArea = null
            const answer = document.getElementById(
                drawMode === 'draw_line_string'
                    ? 'calculated-distance'
                    : 'calculated-area'
            )
            if (drawMode === 'simple_select') {
                const selectedFeatures = draw.getSelected()
                // //console.log("Selected features:", selectedFeatures);
                // setRoundedArea(null);
                // setRoundedDistance(null);

                // Calculate area or distance based on the type of selected features
                if (selectedFeatures.features.length > 0) {
                    const feature = selectedFeatures.features[0]
                    const geometryType =
                        selectedFeatures.features[0].geometry.type
                    setGeotype(geometryType)
                    if (geometryType === 'LineString') {
                        // Calculate and display distance
                        let totalDistance = 0
                        const lineStringCoordinates =
                            selectedFeatures.features[0].geometry.coordinates
                        // //console.log("LineStringCoordinates", lineStringCoordinates);
                        for (
                            let i = 0;
                            i < lineStringCoordinates.length - 1;
                            i++
                        ) {
                            const distanceValue = turf.distance(
                                turf.point(lineStringCoordinates[i]),
                                turf.point(lineStringCoordinates[i + 1]),
                                { units: 'meters' }
                            )
                            totalDistance += distanceValue
                        }

                        // Round and set the total distance
                        roundedDistance = Math.round(totalDistance * 100) / 100
                        // //console.log("geometryD", roundedDistance);
                        setRoundedDistance(roundedDistance)
                    } else if (geometryType === 'Polygon') {
                        // Calculate and display area
                        const areaValue = turf.area(feature)
                        roundedArea = Math.round(areaValue * 100) / 100
                        // //console.log("geometryA", roundedArea);
                        setRoundedArea(roundedArea)
                    }
                }
                if (e.type === 'draw.delete') {
                    // Reset rounded area and distance to null
                    setRoundedArea(null)
                    setRoundedDistance(null)
                }
            } else {
                // Clear answer if not in "simple_select" mode
                // setRoundedArea(null);
                // setRoundedDistance(null);
                if (data.features.length > 0) {
                    // //console.log("data.features[0]", data.features);
                    const index = data.features.length - 1
                    const selectedFeature = data.features[index]
                    const geometryType = selectedFeature.geometry.type

                    if (geometryType === 'LineString') {
                        // Calculate and display distance
                        const distanceValue = turf.distance(
                            turf.point(selectedFeature.geometry.coordinates[0]),
                            turf.point(selectedFeature.geometry.coordinates[1]),
                            { units: 'meters' }
                        )
                        roundedDistance = Math.round(distanceValue * 100) / 100
                        setRoundedDistance(roundedDistance)
                        // //console.log("rounded distance", roundedDistance);

                        //  answer.innerHTML = `<div style=""><p><strong>${roundedDistance}</strong></p><p>meters</p></div>`;
                        // ... display distance in answer ...
                    } else if (geometryType === 'Polygon') {
                        // Calculate and display area
                        // //console.log("polygon data", data);
                        const areaValue = area(selectedFeature)
                        roundedArea = Math.round(areaValue * 100) / 100

                        // //console.log("rounded area", roundedArea);
                        setRoundedArea(roundedArea)
                        //  answer.innerHTML = `<p><strong>${roundedArea}</strong></p><p>square meters</p>`;
                    } else {
                        // Clear answer if it's not a line string or polygon
                        // setRoundedArea(null);
                        // setRoundedDistance(null)
                        // answer.innerHTML = "";
                        if (e.type !== 'draw.delete') {
                            alert('Click the map to draw a line.')
                            draw.deleteAll()
                        }
                    }
                } else {
                    // Clear answer if no feature is selected
                    answer.innerHTML = ''
                }
            }
        }

        map.on('load', () => {
            setMapInstance(map)
            setMapLoaded(true)
            if (onMapLoad) {
                onMapLoad(map)
            }
        })

        return () => {
            map.remove()
        }
    }, [])

    useEffect(() => {
        // Display the answer based on the draw mode
        setRoundedDistance((prevDistance) => {
            // Update UI or perform additional actions based on prevDistance
            return roundedDistance
        })

        setRoundedArea((prevArea) => {
            // Update UI or perform additional actions based on prevArea
            return roundedArea
        })

        // //console.log("useeffectA", roundedArea);

        if (drawMode === 'draw_line_string') {
            const answer = document.getElementById('calculated-distance')

            if (answer && roundedDistance !== null && roundedDistance !== 0) {
                answer.innerHTML = `<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
			<div style={{ flex: '0 0 auto', marginRight: '8px' }}>
			  <p><strong>Road Width:</strong></p>
			</div>
			<div style={{ flex: '1 1 auto' }}>
			  <p>${roundedDistance}meters</p>
			</div>
		  </div>`
            } else if (answer) {
                answer.innerHTML = '' // No HTML if roundedDistance is 0 or null
            }
        } else if (drawMode === 'draw_polygon') {
            const answer = document.getElementById('calculated-area')

            if (answer && roundedArea !== null && roundedArea !== 0) {
                answer.innerHTML = `<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
			<div style={{ flex: '0 0 auto', marginRight: '8px' }}>
			  <p><strong>Area:</strong></p>
			</div>
			<div style={{ flex: '1 1 auto' }}>
			  <p>${roundedArea} meters</p>
			</div>
		  </div>
		  `
            } else if (answer) {
                answer.innerHTML = '' // No HTML if roundedArea is 0 or null
            }
        } else if (drawMode === 'simple_select') {
            if (geotype !== null) {
                const answer = document.getElementById(
                    geotype === 'LineString'
                        ? 'calculated-distance'
                        : 'calculated-area'
                )

                if (answer) {
                    if (
                        geotype === 'LineString' &&
                        roundedDistance !== null &&
                        roundedDistance !== 0
                    ) {
                        answer.innerHTML = `<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
				<div style={{ flex: '0 0 auto', marginRight: '8px' }}>
				  <p><strong>Road Width:</strong></p>
				</div>
				<div style={{ flex: '1 1 auto' }}>
				  <p>${roundedDistance}meters</p>
				</div>
			  </div>`
                    } else if (
                        geotype === 'Polygon' &&
                        roundedArea !== null &&
                        roundedArea !== 0
                    ) {
                        answer.innerHTML = `<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
				<div style={{ flex: '0 0 auto', marginRight: '8px' }}>
				  <p><strong>Area:</strong></p>
				</div>
				<div style={{ flex: '1 1 auto' }}>
				  <p>${roundedArea} sq meters</p>
				</div>
			  </div>`
                    } else {
                        answer.innerHTML = '' // No HTML if roundedDistance or roundedArea is 0 or null
                    }
                }
            }
        }
    }, [roundedDistance, roundedArea, drawMode, geotype])

    const handleAssetsPlotting = () => {
        setAssetVisible((prev) => !prev)
    }


    return (
        <>
            {' '}
            <div ref={mapContainer} style={{ height: '83vh', zIndex: '0' }}>
                <div
                    className={`absolute top-1 right-28 text-white z-10 cursor-pointer rounded-md bg-primary px-2 py-1  hover:scale-105 transition-transform duration-300 `}
                    onClick={handleAssetsPlotting}
                >
                    {' '}
                    {assetVisible ? 'Hide  Assets' : 'Plot Assets'}
                </div>
                {roundedArea !== null && roundedArea !== 0 && (
                    <div
                        id="calculated-area"
                        style={{
                            height: '50px',
                            width: '150px',
                            position: 'absolute',
                            bottom: '13%',
                            left: '2%',
                            color: 'white',
                            borderRadius: '5px',
                            backgroundColor: 'grey',
                            // textAlign: "center",
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    ></div>
                )}
                {roundedDistance !== null && roundedDistance !== 0 && (
                    <div
                        id="calculated-distance"
                        style={{
                            height: '50px',
                            width: '150px',
                            position: 'absolute',
                            bottom: '23%',
                            left: '2%',
                            color: 'white',
                            borderRadius: '5px',
                            backgroundColor: 'grey',
                            //  textAlign: "center",
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    ></div>
                )}

            {!showHeatMap ? (
               
                <div
                    className="absolute flex justify-center items-center top-1 right-1 text-white font-semibold z-10 cursor-pointer rounded-md bg-primary px-2 py-1 hover:scale-105 transition-transform duration-300"
                    onClick={() => {
                        getHeatmapData()
                    }}
                >
                    <MdCheckBoxOutlineBlank className="w-5 h-5 mr-2" />
                    Heat Map
                </div>
            ) : (
                <div
                    className="absolute flex justify-center items-center top-1 right-1 text-white font-semibold z-10 cursor-pointer rounded-md bg-primary px-2 py-1 hover:scale-105 transition-transform duration-300"
                    onClick={() => {
                        removeHeatMap()
                    }}
                >
                    <IoMdCheckboxOutline className="w-5 h-5 mr-2" />
                    Heat Map
                </div>
               
            )}

            </div>
            
        </>
    )
}

export default MapInitializer
