import { apiConnector } from "../../usermanagement/services/apiConnector"
import { paymentEndPoints } from "../../usermanagement/services/apis"
import { setLoading } from "../../usermanagement/slices/authSlice"

export const getPaymentOrderID = (body) => {
    return async (dispatch, getState) => {
        const { token } = getState().auth
        dispatch(setLoading(true))

        let result = []
        try {
            const response = await apiConnector(
                "POST",
                paymentEndPoints.GET_PAYMENT_ORDER_ID,
                body,
                { authorization: token }
            )
            result = response?.data
        } catch (error) {}
        dispatch(setLoading(false))
        return result
    }
}

export const PaymentVerification = (body) => {
    return async (dispatch, getState) => {
        const { token } = getState().auth
        dispatch(setLoading(true))
        let result = []
        try {
            const response = await apiConnector(
                "POST",
                paymentEndPoints.PAYMENT_VERIFICATION,
                body,
                { authorization: token },
            )
            result = response?.data
        } catch (error) {}
        dispatch(setLoading(false))
        return result
    }
}