export const constant = [
    {
        title: 'layer1',
        style: 'mapbox://styles/rasta-ai/clp9kjai4002g01pk3rc3fh1z',
    },
    {
        title: 'layer2',
        style: 'mapbox://styles/rasta-ai/clq2645vm008i01plhtkactnb',
    },
    {
        title: 'layer3',
        style: 'mapbox://styles/rasta-ai/clq26d4mb00bu01qs1s0tc8y8',
    },
    {
        title: 'layer4',
        style: 'mapbox://styles/rasta-ai/clq26blth01ri01pa6czffbpq',
    },
]
