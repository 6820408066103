import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { getMemberDetailsByAdmin, updateMember } from '../../../services/Operations/memberAPI';
import Modal from 'react-modal'
import { Button, TextField } from '@mui/material'
import toast from 'react-hot-toast'
import { Spinner } from '../../../../utils/Spinner'
import { closeImg } from '../../../../assets/IconArray';

const UpdateMember = ({ memberUserId, setUpdateMemberDetails }) => {
  const [memberDetails, setMemberDetails] = useState();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [isDialogOpen, setIsDialogOpen] = useState(true);
  const [emailError, setEmailError] = useState('');
  const [phoneWarning, setPhoneWarning] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [permissionsError, setPermissionsError] = useState(false);
  const [selectedPermissions, setSelectedPermissions] = useState([]);

  const [formData, setFormData] = useState({
    name: '',
    username: '',
    role: 'Member',
    email: '',
    phone: '',
    dob: '',
    memberPermissions: []
  });

  const {  name, username, email, phone, memberPermissions} =
  formData

  useEffect(() => {
    async function fetchMemberDetails() {
      setLoading(true);
      try {
        const result = await dispatch(getMemberDetailsByAdmin(memberUserId));
        setMemberDetails(result?.userDetail);
        setFormData({
          name: result?.userDetail.name || '',
          username: result?.userDetail.username || '',
          role: 'Member',
          email: result?.userDetail.email || '',
          phone: result?.userDetail.phone?.toString() || '',
          dob: result?.userDetail.dob || '',
          memberPermissions: result?.userDetail.memberPermissions || []
        });
        setSelectedPermissions(result?.userDetail.memberPermissions || []);
      } catch (error) {
        console.error("Error while fetching Member Details: ", error);
        toast.error("Failed to fetch member details");
      } finally {
        setLoading(false);
      }
    }

    fetchMemberDetails();
  }, [memberUserId, dispatch]);

  const handleChange = (field) => (event) => {
    let value = event.target.value;

    if (field === 'phone') {
      value = value.replace(/\D/g, '');
      if (value.length > 10) {
        setPhoneWarning('Phone number should not exceed 10 digits');
        value = value.slice(0, 10);
      } else if (value.length < 10) {
        setPhoneWarning('Phone number should be 10 digits');
      } else {
        setPhoneWarning('');
      }
    }

    if (field === 'email') {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(value)) {
        setEmailError('Enter a valid email address');
      } else {
        setEmailError('');
      }
    }

    setFormData(prevData => ({
      ...prevData,
      [field]: value
    }));
  };

  const handlePermissionChange = (e) => {
    let updatedPermissions;
    if (e.target.checked) {
      updatedPermissions = [...selectedPermissions, e.target.value];
    } else {
      updatedPermissions = selectedPermissions.filter(permission => permission !== e.target.value);
    }

    setSelectedPermissions(updatedPermissions);
    setFormData(prevData => ({
      ...prevData,
      memberPermissions: updatedPermissions
    }));

    setPermissionsError(updatedPermissions.length < 2);
  };

  const handleSubmit = async () => {
    // Implement the update logic here

    if (emailError) {
      toast.error('Please fill in a valid Email ID before submitting.')
      return
    }

    if (permissionsError) {
      toast.error('Please select atleast two permissions!')
      return 
    }

    if (phoneWarning) {
      toast.error(phoneWarning)
      return
    }

    for (const key in formData) {
      if (formData[key] === '') {
        toast.error('Please fill in all fields before submitting.')
        return
      }
    }

    setLoading(true)
    try {
      await dispatch(updateMember(
        memberUserId,
        email,
        phone,
        memberPermissions
      ))
      
      // Clear form data
      setFormData({
        name: '',
        username: '',
        email: '',
        phone: '',
        memberPermissions: []
      })
      
      setSelectedPermissions([])
      
      // Reload the page
      window.location.reload()
    } catch (error) {
      console.error("Error while updating member details: ", error)
      toast.error("Failed to submit data. Please try again.")
    } finally {
      setLoading(false)
    }
  };

  return (
    <>
      {loading && (
        <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <Spinner />
        </div>
      )}

      <Modal
        isOpen={isDialogOpen}
        onRequestClose={isDialogOpen}
        contentLabel="Update Member Dialog"
        style={{
          overlay: {
            backgroundColor: 'rgba(255, 255, 255, 0.1)',
            backdropFilter: 'blur(4px)',
            zIndex: 100,
          },
          content: {
            width: '63vw',
            maxHeight: '90vh',
            margin: 'auto',
            overflow: 'hidden',
            borderRadius: '25px',
          },
        }}
      >
        <div className="mx-auto mt-[1vw] dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700 relative" style={{ width: '60vw', height: '75vh', backgroundColor: 'white' }}>
          <div className='flex justify-end'>
            <div onClick={() => setUpdateMemberDetails(false)}>
              <img src={closeImg.closeImg} alt="close" className='w-6 h-6 cursor-pointer'/>
            </div>
          </div>
          <div className="flex mt-[-1rem] justify-center items-center">
            <div>
              <h2 className="font-poppins text-left text-2xl font-bold ml-6">Update Member</h2>
            </div>
          </div>

          <div className="flex flex-col font-poppins mt-[1vw] border-2 border-orange-500 rounded-lg w-[60vw] h-[75vh]">
            <div className='flex justify-around items-center mt-[5vh] h-10 w-full'>
              <div className="mt-[-2rem] text-lg" style={{ fontWeight: 700, lineHeight: '24px', letterSpacing: '0em', color: '#00000' }}>
                Update Member's details
              </div>
              <div className="mt-[-2rem] text-lg" style={{ fontWeight: 700, lineHeight: '24px', letterSpacing: '0em', color: '#00000' }}>
                Update Permissions
              </div>
            </div>
            <div className="h-full grid grid-cols-2 gap-2 justify-center items-center mx-2 grow">
              <div className="w-full h-full overflow-auto">
                <div className="flex flex-col justify-center items-center">
                  <label className="w-3/4 flex mb-2 text-black font-semibold item-left text-sm" htmlFor="text-field" style={{ marginBottom: '-0.1rem', width: '75%' }}>
                    Full Name
                  </label>
                  <TextField
                    label=""
                    variant="outlined"
                    size="small"
                    value={formData.name}
                    InputProps={{
                      readOnly: true,
                    }}
                    sx={{
                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: 'black' },
                      width: '75%',
                      height: '60%',
                      color: 'black',
                    }}
                  />

                  <label className="w-3/4 flex mb-2 text-black font-semibold item-left text-sm" htmlFor="text-field" style={{ marginBottom: '-0.1rem', width: '75%', marginTop: '1rem' }}>
                    Username
                  </label>
                  <TextField
                    label=""
                    variant="outlined"
                    size="small"
                    value={formData.username}
                    InputProps={{
                      readOnly: true,
                    }}
                    sx={{
                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: 'black' },
                      width: '75%',
                      height: '60%',
                      color: 'black',
                    }}
                  />

                  <label className="w-3/4 flex mb-2 text-black font-semibold item-left text-sm" htmlFor="text-field" style={{ marginBottom: '-0.1rem', width: '75%', marginTop: '1rem' }}>
                    Email
                  </label>
                  <TextField
                    label=""
                    variant="outlined"
                    size="small"
                    value={formData.email}
                    onChange={handleChange('email')}
                    sx={{
                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: 'black' },
                      width: '75%',
                      height: '60%',
                      color: 'black',
                    }}
                  />
                  {emailError && (
                    <p className="text-red-500 text-xs mt-1" style={{ width: '75%' }}>
                      {emailError}
                    </p>
                  )}

                  <label className="w-3/4 flex mb-2 text-black font-semibold item-left text-sm" htmlFor="text-field" style={{ marginBottom: '-0.1rem', width: '75%', marginTop: '1rem' }}>
                    Phone
                  </label>
                  <TextField
                    label=""
                    variant="outlined"
                    size="small"
                    value={formData.phone}
                    onChange={handleChange('phone')}
                    sx={{
                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: 'black' },
                      width: '75%',
                      height: '60%',
                      color: 'black',
                    }}
                  />
                  {phoneWarning && (
                    <p className="text-red-500 text-xs mt-1" style={{ width: '75%' }}>
                      {phoneWarning}
                    </p>
                  )}

                  <label className="w-3/4 flex mb-2 text-black font-semibold item-left text-sm" htmlFor="text-field" style={{ marginBottom: '-0.1rem', width: '75%', marginTop: '1rem' }}>
                    Date of Birth
                  </label>
                  <TextField
                    label=""
                    variant="outlined"
                    size="small"
                    type="date"
                    value={formData.dob}
                    InputProps={{
                      readOnly: true,
                    }}
                    sx={{
                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: 'black' },
                      width: '75%',
                      height: '60%',
                      color: 'black',
                    }}
                  />
                </div>
                {/* ... (repeat for other fields) ... */}
              </div>
              <div className="w-full h-full overflow-auto">
                <div className="flex items-center flex-col gap-6 mt-3.5">
                  {/* Permissions checkboxes */}
                  <label className="flex justify-between w-[85%] border border-gray-400 rounded-md p-2 items-center me-5 cursor-pointer">
                    <span className="ms-3 text-sm font-medium text-black">Heat Map</span>
                    <input
                      type="checkbox"
                      value="HeatMap"
                      className="sr-only peer"
                      checked={selectedPermissions.includes("HeatMap")}
                      onChange={handlePermissionChange}
                    />
                    <div className="relative w-11 h-6 bg-gray-200 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-green-600"></div>
                  </label>
                  <label className="flex justify-between w-[85%] border border-gray-400 rounded-md p-2 items-center me-5 cursor-pointer">
                    <span className="ms-3 text-sm font-medium text-black">Statistics Bar and Road Conditions</span>
                    <input
                      type="checkbox"
                      value="StatisticsBar"
                      className="sr-only peer"
                      checked={selectedPermissions.includes("StatisticsBar")}
                      onChange={handlePermissionChange}
                    />
                    <div className="relative w-11 h-6 bg-gray-200 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-green-600"></div>
                  </label>
                  <label className="flex justify-between w-[85%] border border-gray-400 rounded-md p-2 items-center me-5 cursor-pointer">
                    <span className="ms-3 text-sm font-medium text-black">List Of Trips</span>
                    <input
                      type="checkbox"
                      value="ListOfTrips"
                      className="sr-only peer"
                      checked={selectedPermissions.includes("ListOfTrips")}
                      onChange={handlePermissionChange}
                    />
                    <div className="relative w-11 h-6 bg-gray-200 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-green-600"></div>
                  </label>
                  <label className="flex justify-between w-[85%] border border-gray-400 rounded-md p-2 items-center me-5 cursor-pointer">
                    <span className="ms-3 text-sm font-medium text-black">Single Map Points And Details</span>
                    <input
                      type="checkbox"
                      value="SingleMapPoints"
                      className="sr-only peer"
                      checked={selectedPermissions.includes("SingleMapPoints")}
                      onChange={handlePermissionChange}
                    />
                    <div className="relative w-11 h-6 bg-gray-200 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-green-600"></div>
                  </label>
                  <label className="flex justify-between w-[85%] border border-gray-400 rounded-md p-2 items-center me-5 cursor-pointer">
                    <span className="ms-3 text-sm font-medium text-black">Budget Calculator</span>
                    <input
                      type="checkbox"
                      value="BudgetCalculator"
                      className="sr-only peer"
                      checked={selectedPermissions.includes("BudgetCalculator")}
                      onChange={handlePermissionChange}
                    />
                    <div className="relative w-11 h-6 bg-gray-200 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-green-600"></div>
                  </label>
                  <label className="flex justify-between w-[85%] border border-gray-400 rounded-md p-2 items-center me-5 cursor-pointer">
                    <span className="ms-3 text-sm font-medium text-black">Utility DashBoard Access</span>
                    <input
                      type="checkbox"
                      value="UtilityDashboard"
                      className="sr-only peer"
                      checked={selectedPermissions.includes("UtilityDashboard")}
                      onChange={handlePermissionChange}
                    />
                    <div className="relative w-11 h-6 bg-gray-200 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-green-600"></div>
                  </label>
                  <label className="flex justify-between w-[85%] border border-gray-400 rounded-md p-2 items-center me-5 cursor-pointer">
                    <span className="ms-3 text-sm font-medium text-black">Reports</span>
                    <input
                      type="checkbox"
                      value="Reports"
                      className="sr-only peer"
                      checked={selectedPermissions.includes("Reports")}
                      onChange={handlePermissionChange}
                    />
                    <div className="relative w-11 h-6 bg-gray-200 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-green-600"></div>
                  </label>
                  <label className="flex justify-between w-[85%] border border-gray-400 rounded-md p-2 items-center me-5 cursor-pointer">
                    <span className="ms-3 text-sm font-medium text-black">Compare Trips</span>
                    <input
                      type="checkbox"
                      value="CompareTrips"
                      className="sr-only peer"
                      checked={selectedPermissions.includes("CompareTrips")}
                      onChange={handlePermissionChange}
                    />
                    <div className="relative w-11 h-6 bg-gray-200 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-green-600"></div>
                  </label>
                  {/* ... (repeat for other permissions) ... */}
                </div>
              </div>
            </div>
            <div className="d-flex justify-center items-center" style={{ marginLeft: 'auto', marginRight: 'auto', width: '205.87px', height: '15vh', display: 'flex', flexDirection: 'column' }}>
              <Button
                type="button"
                className="mt-[-3rem] mb-10"
                onClick={handleSubmit}
                disabled={emailError || phoneWarning}
                style={{
                  width: '205px',
                  height: '47px',
                  backgroundColor: '#ff6100',
                  borderRadius: '4px',
                  color: '#FFF',
                  textAlign: 'center',
                }}
              >
                Update
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default UpdateMember