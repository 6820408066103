import React ,{useState,useEffect}  from 'react'
import HeaderAndFooter from '../DetailedRoadReport/HeaderAndFooter'
import { Spinner } from '../../../../utils/Spinner'
import FirstPage from '../Details/FirstPage'
import { useLocation,useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { getMaintenanceReport } from '../../../../usermanagement/services/Operations/CoreAPIs/reportAPI'
import Abbreviation from '../Details/Abbreviation'
import DataOne from '../Details/DataOne'
import DataoneMaintenance from './DataoneMaintenance'

function MaintenaceRoadReport() {
    const location = useLocation()
    const dispatch = useDispatch()
    const { officeLevel, officeName, roadName, roadNo } = location.state || {}
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    const [detailOfRoad, setDetailOfRoad] = useState([])
    const [higherAuthority, setHigherAuthority] = useState(null)

    useEffect(() => {
        async function fetchReportData() {
            try {
                const reportData = await dispatch(
                    getMaintenanceReport(officeLevel, officeName, roadName, roadNo)
                );
            
                setDetailOfRoad(reportData.data);
                setHigherAuthority(reportData.higherAuth);
                

            } catch (error) {
                console.error("Error while getting road report Data: ", error);
            }
        }
        const fetchData = async () => {
            setLoading(true)
            try {
                await fetchReportData()
            } catch (error) {
                setError(error)
                console.error(
                    'Error in Detailed Road Report Generation: ',
                    error
                )
            }
            setLoading(false)
        }

        fetchData()
    }, [officeLevel, officeName, roadName, roadNo])

  

  return (
    <>
            {loading ? (
                        <div className="w-[100vw] h-[100vh] flex justify-center items-center">
                            <Spinner />
                        </div>
                    ) : (
    
                            <HeaderAndFooter loading={loading}>
                            <div className="w-full text-justify">
                            <FirstPage
                                clientName={
                                    higherAuthority ? higherAuthority : roadName
                                }
                            />
                            </div>
                            <div className="w-full text-justify page-break">
                            <Abbreviation />
                            </div>
                            <div className="w-full text-justify page-break">
                            <DataOne />
                        </div>
                        <div className='page-break'>
                            <DataoneMaintenance 
                            data={detailOfRoad}
                            roadName={roadName}
                            />
                        </div>
                            </HeaderAndFooter>
                        )}
    </>
  )
}

export default MaintenaceRoadReport