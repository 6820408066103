//BudgetPage.js
import React, { useState, useEffect } from 'react'
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import lock from '../assets/img/lock_crown.png'

//import { Checkbox } from '@mui/material';
import { Checkbox, Select, MenuItem } from '@mui/material'

import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
    getRoadListInDropDown,
    getSingleRoadBudgetData,
} from '../mapbox/services/Operations/RoadsAPI'
import { selectProfile } from '../usermanagement/slices/profileSlice'
import { selectAuth } from '../usermanagement/slices/authSlice'
import { IoMdCloseCircle } from 'react-icons/io'

const BudgetPage = ({ closeCalculatorDialog }) => {
    const [selectAll, setSelectAll] = useState(false)
    const [roadListArray, setRoadListArrray] = useState([])
    const [pricePlan, setPricePlan] = useState(true)
    const [selectedItems, setSelectedItems] = useState([]) 
    const [calculateClicked, setCalculateClicked] = useState(false)
    const [totalCost, setTotalCost] = useState(0)
    const [selectedRoad, setSelectedRoad] = useState('All Roads')
    const [selectedRoadData, setSelectedRoadData] = useState([])
    const [tableData, setTableData] = useState([])
    const [reportSelectionDialogOpen, setReportSelectionDialogOpen] =
        useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false)

    const openModal = () => setIsModalOpen(true)
    const closeModal = () => setIsModalOpen(false)

    const dispatch = useDispatch()
    const { userType } = useSelector(selectAuth)
    const { profileUserData } = useSelector(selectProfile)
    const location = useLocation()

    // if (userType !== 'JE') {
    const { subdivision_name } = location.state || {}
    // setSubdivisionValue(subdivision_name);
    // }

    // api for dropdown list
    useEffect(() => {
        // localStorage.setItem('fromRasta', 'true');
        const fetchData = async () => {
            let subdivisionName = ''
            try {
                if (userType === 'JE') {
                    subdivisionName = null
                } else if (
                    userType === 'Admin' &&
                    profileUserData?.officeLevel === 'Sub-division'
                ) {
                    subdivisionName = profileUserData?.officeName
                } else {
                    subdivisionName = subdivision_name
                }
                const result = await dispatch(
                    getRoadListInDropDown(subdivisionName)
                )
                if (result) {
                    setRoadListArrray(
                        result.map(
                            (road) => `${road.roadNo} - ${road.roadName}`
                        )
                    )
                }
            } catch (error) {
                console.error('Error fetching road data:', error)
            }
        }

        fetchData()
    }, [])
    useEffect(() => {
        handleCalculate()
    }, [selectedItems, tableData])

    const getSingleRoadPointData = async (value) => {
        const [roadNo, roadName] = value.split(' - ')

        try {
            const result = await dispatch(
                getSingleRoadBudgetData(roadName, roadNo)
            )
            if (result[0]) {
                setSelectedRoadData(result[0])
            }
        } catch (error) {
            console.error('Error fetching road data:', error)
        }
    }

    useEffect(() => {
        if (selectedRoadData && selectedRoadData.total_detected) {
            const updatedTableData = Object.keys(
                selectedRoadData.total_detected
            ).map((defect, index) => {
                const cost = getCostForDefect(defect)
                // const cost =1000
                const found = selectedRoadData.total_detected[defect] || 0
                const total = cost * found

                return {
                    id: index + 1,
                    // defect: defect,
                    defect: defect.charAt(0).toUpperCase() + defect.slice(1), // Capitalize the first letter of the defect name
                    cost: cost, // Assign the cost calculated earlier
                    found: found,
                    total: total,
                }
            })
            setTableData(updatedTableData)
        }
    }, [selectedRoadData])

    const selectedData = tableData.filter((item) =>
        selectedItems.includes(item.id)
    )

    const generatePDF = () => {
        const doc = new jsPDF()
        doc.setFontSize(16)
        doc.setFont('helvetica', 'bold')
        doc.text('Budget Report', 80, 10)

        const tableBody = selectedData.map((item) => [
            item.defect,
            item.cost,
            item.found,
            item.total,
        ])

        const totalSum = selectedData.reduce((acc, item) => acc + item.total, 0)

        tableBody.push(['Total', '', '', totalSum])

        doc.autoTable({
            startY: 20,
            head: [['Defect', 'Cost(rupees)', 'Found', 'Total(rupees)']],
            body: tableBody,
            headStyles: {
                fillColor: [254, 97, 0], // RGB for orange color
            },
            didParseCell: function (data) {
                if (data.row.index === tableBody.length - 1) {
                    data.cell.styles.fontStyle = 'bold'
                }
            },
        })

        doc.save('budget_report.pdf')
    }

    const totalSum = tableData.reduce((acc, item) => acc + item.total, 0)

    const generateCSV = () => {
        const csvHeader = ['Defect', 'Cost(rupees)', 'Found', 'Total(rupees)']

        const csvBody = selectedData.map((item) => [
            item.defect,
            item.cost,
            item.found,
            item.total,
        ])

        const totalSum = selectedData.reduce((acc, item) => acc + item.total, 0)

        csvBody.push(['Total', '', '', totalSum])

        const csvContent = [
            csvHeader.join(','),
            ...csvBody.map((row) => row.join(',')),
        ].join('\n')

        const blob = new Blob([csvContent], {
            type: 'text/csv;charset=utf-8;',
        })

        const link = document.createElement('a')
        const url = URL.createObjectURL(blob)
        link.setAttribute('href', url)
        link.setAttribute('download', 'budget_report.csv')
        link.style.visibility = 'hidden'

        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
    }

    // Define a function to get the cost for each defect
    const getCostForDefect = (defect) => {
        defect = defect.toUpperCase()
        switch (defect) {
            // Convert the defect to uppercase before comparing
            case 'MANHOLE':
                return 1000 // Set the cost for Manhole
            case 'POTHOLE':
                return 2000 // Set the cost for Pothole
            case 'SPEED-BREAKER':
                return 1500
            case 'HOTSPOT':
                return 2000
            case 'ROAD-PATCH':
                return 2000

            case 'Roadpatch':
                return 2000
            case 'MINOR-POTHOLE':
                return 1500
            case 'MAJOR-POTHOLE':
                return 2500
            case 'SPEEDBREAKER':
                return 3000

            default:
                return 0 // Default cost if the defect is not found
        }
    }

    const handleCheckboxChange = (itemId) => {
        const updatedSelectedItems = [...selectedItems]
        const index = updatedSelectedItems.indexOf(itemId)

        if (index === -1) {
            updatedSelectedItems.push(itemId)
        } else {
            updatedSelectedItems.splice(index, 1)
        }

        setSelectedItems(updatedSelectedItems)

        if (updatedSelectedItems.length === tableData.length) {
            setSelectAll(true)
        } else if (updatedSelectedItems.length === 0) {
            setSelectAll(false)
        }
        
        else {
            setSelectAll(false)
        }
    }

    const handleSelectAll = () => {
        if (selectAll) {
            setSelectedItems([])
        } else {
            const allItemIds = tableData.map((item) => item.id)
            setSelectedItems(allItemIds)
        }
        setSelectAll(!selectAll)
    }

    const handleCostChange = (itemId, value) => {
        handleCalculate()
        const updatedTableData = tableData.map((item) =>
            item.id === itemId ? { ...item, cost: parseInt(value) || 0 } : item
        )

        setTableData(updatedTableData)
    }

    const handleCalculate = () => {
        setCalculateClicked(true)

        // Perform the calculation logic here
        const calculatedTotalCost = tableData.reduce((acc, item) => {
            if (selectedItems.includes(item.id)) {
                const itemTotal = item.cost * item.found
                item.total = itemTotal
                return acc + itemTotal
            }

            return acc
        }, 0)

        // Update the totalCost state
        setTotalCost(calculatedTotalCost)
    }

    const onClickCloseModal = () => {
        setReportSelectionDialogOpen(false)
        // navigate(-1); // This will navigate back to the previous page
    }

    const paided = true

    const [age, setAge] = React.useState('')

    const handleChange = (event) => {
        // checkboxes null
        setSelectedItems([])
        setTotalCost(0)

        if (event.target.value && event.target.value !== 'All Roads') {
            setSelectedRoad(event.target.value)
            getSingleRoadPointData(event.target.value)
        }
    }

    return (
        <>
            <div>
                <div
                    className="mx-auto  mt-8  relative"
                    style={{
                        width: '80vw',
                        height: '80vh',
                        backgroundColor: 'white',
                        fontFamily: 'Poppins',
                    }}
                >
                    <h2 className="mb-2 ml-6 text-left text-2xl font-bold font-family-Poppins">
                        Budget Estimator
                    </h2>
                    <div
                        className="mb-2 font-poppins ml-6"
                        style={{
                            fontSize: '16px',
                            fontWeight: 400,
                            lineHeight: '24px',
                            letterSpacing: '0em',
                            color: '#86878B',
                        }}
                    >
                        Get a Calculated estimated Budget cost
                    </div>
                    
                    <div className='flex flex-col lg:flex lg:flex-row'> 
                        {/* <ThemeProvider theme={theme}> */}
                        <div className="flex">
                            <div className="lg:relative lg:w-2/3 lg:p-4">
                                {/* checkboxes  */}
                                <div className="flex">
                                    <div className="flex ">
                                        <div
                                            className="w-1/2  p-0 mr-6 "
                                            style={{
                                                // background: "#FE6100",
                                                width: '290px',
                                                height: '80px',
                                                // border: '2px solid #FE6100',
                                                borderRadius: '16px',
                                                border: 'none',
                                                outline: 'none',
                                            }}
                                        >
                                            <div className="">
                                                <Select
                                                    value={selectedRoad}
                                                    onChange={handleChange}
                                                    className="w-full font-poppins"
                                                    sx={{
                                                        width: '100%',

                                                        height: '100%',
                                                        border: '2px solid #FE6100',
                                                        borderRadius: '16px',

                                                        cursor: 'pointer',
                                                        '&:focus': {
                                                            outline: 'none',
                                                            boxShadow: 'none',
                                                        },
                                                    }}
                                                >
                                                    <MenuItem
                                                        value="All Roads"
                                                        style={{ outline: 'none' }}
                                                    >
                                                        Select Roads
                                                    </MenuItem>
                                                    {roadListArray.map(
                                                        (item, index) => (
                                                            <MenuItem
                                                                style={{
                                                                    outline: 'none',
                                                                }}
                                                                key={index}
                                                                value={item}
                                                            >
                                                                {item}
                                                            </MenuItem>
                                                        )
                                                    )}
                                                </Select>
                                            </div>
                                        </div>
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'left',
                                                alignItems: 'center',
                                                padding: '10px',
                                                background: '#FFF',
                                                width: '290px',
                                                height: '58px',
                                                borderRadius: '16px',
                                                border: '2px solid #FE6100',
                                            }}
                                        >
                                            <div className="flex items-center justify-center gap-x-5  items-left">
                                                <h4 className="text-lg font-bold text-left">
                                                    Total Length :
                                                </h4>
                                                <p className="text-gray-600 text-left">
                                                    {selectedRoadData.distance > 0
                                                        ? `${parseFloat(
                                                            selectedRoadData.distance
                                                        ).toFixed(1)} Km`
                                                        : '0 Km'}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* table body */}

                                <div className="mt-4 ">
                                    <table className="w-[648px] text-center rounded-xl shadow-lg">
                                        <thead>
                                            <tr
                                                className="bg-ffddc7 text-center justify-center items-center border-b-2 rounded-xl border-orange-500 overflow-hidden"
                                                style={{
                                                    background: 'white',
                                                    borderTopLeftRadius: '8px',
                                                    borderTopRightRadius: '8px',
                                                }}
                                            >
                                                <th className="px-4 py-2 font-poppins text-base font-semibold leading-6 text-left tracking-tight ">
                                                    <Checkbox
                                                        onChange={handleSelectAll}
                                                        checked={selectAll}
                                                        style={{
                                                            color: selectAll
                                                                ? '#ff6100'
                                                                : '',
                                                        }}
                                                    />
                                                    DEFECTS
                                                </th>
                                                <th className="px-4 py-2 font-poppins text-center text-base font-semibold leading-6 tracking-tight ">
                                                    COST
                                                </th>
                                                <th className="px-4 py-2 font-poppins text-base font-semibold leading-6 tracking-tight text-center">
                                                    FOUND
                                                </th>
                                                <th className="px-4 py-2 font-poppins text-base font-semibold leading-6 tracking-tight text-center">
                                                    TOTAL
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {tableData.length !== 0 ? (
                                                tableData.map((item, index) => (
                                                    <tr
                                                        key={item.id}
                                                        className={
                                                            index !== 0
                                                                ? 'mt-4'
                                                                : ''
                                                        }
                                                    >
                                                        <td className="px-4 py-3 font-poppins text-base font-medium leading-6 tracking-tight text-left border-b">
                                                            <Checkbox
                                                                className="mx-2"
                                                                onChange={() =>
                                                                    handleCheckboxChange(
                                                                        item.id
                                                                    )
                                                                }
                                                                checked={selectedItems.includes(
                                                                    item.id
                                                                )}
                                                                style={{
                                                                    color: selectedItems.includes(
                                                                        item.id
                                                                    )
                                                                        ? '#ff6100'
                                                                        : '',
                                                                }}
                                                            />
                                                            <span className="mx-2">
                                                                {item.defect}
                                                            </span>
                                                        </td>
                                                        <td className="px-4 py-3 font-poppins text-base font-medium leading-6 tracking-tight text-center border-b">
                                                            <div className="relative">
                                                                <span
                                                                    style={{
                                                                        position:
                                                                            'absolute',
                                                                        left: '5px',
                                                                        top: '50%',
                                                                        transform:
                                                                            'translateY(-50%)',
                                                                        color: '#000',
                                                                    }}
                                                                >
                                                                    ₹
                                                                </span>
                                                                <input
                                                                    type="text"
                                                                    value={
                                                                        item.cost
                                                                    }
                                                                    onChange={(e) =>
                                                                        handleCostChange(
                                                                            item.id,
                                                                            e.target
                                                                                .value
                                                                        )
                                                                    }
                                                                    placeholder="Enter"
                                                                    className="w-20 px-2 py-1 pl-8 rounded border-2 border-grey-500"
                                                                    style={{
                                                                        background:
                                                                            'white',
                                                                        paddingLeft:
                                                                            '20px',
                                                                    }}
                                                                />
                                                            </div>
                                                        </td>
                                                        <td className="px-4 py-3 font-poppins text-base font-medium leading-6 tracking-tight text-center border-b">
                                                            {pricePlan ? (
                                                                // Render the item.found value if it's truthy
                                                                item.found
                                                            ) : (
                                                                // Render an image (replace 'your_image_path' with the actual path)
                                                                <img
                                                                    src={lock}
                                                                    alt="Locked"
                                                                    style={{
                                                                        width: 40,
                                                                        height: 40,
                                                                    }}
                                                                />
                                                            )}
                                                        </td>
                                                        <td className="px-4 py-3 font-poppins text-base font-medium leading-6 tracking-tight text-center border-b">
                                                            {pricePlan ? (
                                                                // Render the item.found value if it's truthy
                                                                item.cost *
                                                                item.found
                                                            ) : (
                                                                // Render an image (replace 'your_image_path' with the actual path)
                                                                <img
                                                                    className="ml-5"
                                                                    src={lock}
                                                                    alt="Locked"
                                                                    style={{
                                                                        width: 40,
                                                                        height: 40,
                                                                    }}
                                                                />
                                                            )}
                                                        </td>
                                                    </tr>
                                                ))
                                            ) : (
                                                // Render "No data available" message if data is not available
                                                <tr>
                                                    <td
                                                        colSpan="4"
                                                        className="px-4 py-3 font-poppins text-base font-medium leading-6 tracking-tight text-center border-b"
                                                    >
                                                        No data available
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        {/* </ThemeProvider> */}

                        <div
                            className="lg:absolute w-[80%] lg:w-1/3 p-4 top-0 right-0 mt-4 flex flex-col items-center justify-center mx-auto my-auto border border-solid shadow-lg rounded-xl"
                            style={{
                                boxShadow: '0px 4px 27.5px 0px rgba(0, 0, 0, 0.25)',
                            }}
                        >
                            <div className="flex justify-center mb-6">
                                <img
                                    src="icons/budget.png"
                                    alt=""
                                    className="w-[220px] h-[200px]"
                                />
                            </div>

                            <div className="flex flex-col items-center justify-center mb-12">
                                <p className="font-poppins text-lg font-semibold">
                                    Total Estimated Budget
                                </p>
                                <p className="text-xs">
                                    Get a Calculated estimated Budget cost
                                </p>
                            </div>
                            <div className="p-4 rounded-lg w-[250px] h-[70px] bottom-4 text-white text-center justify-center border border-solid border-orange-500 mb-10">
                                <p
                                    className="text-2xl font-bold leading-9"
                                    style={{ color: '#000' }}
                                >{`₹ ${totalCost}`}</p>
                            </div>

                            <div>
                                {selectedRoad !== 'All Roads' &&
                                selectedItems.length > 0 ? (
                                    <button
                                        onClick={openModal}
                                        className="w-28 h-10 p-1 px-2 box-content rounded-md bg-[#f8b286] focus:outline-none hover:bg-primary text-white shadow-md"
                                    >
                                        <p className="font-poppins text-sm">
                                            Budget Report
                                        </p>
                                    </button>
                                ) : (
                                    ''
                                )}

                                {isModalOpen && (
                                    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                                        <div className="bg-white p-6 rounded-lg shadow-lg  w-[500px] h-[350px] ">
                                            <div className="flex justify-end">
                                                <button onClick={closeModal}>
                                                    <IoMdCloseCircle className="w-6 h-6 text-orange-500" />
                                                </button>
                                            </div>
                                            <div>
                                                <h2 className="text-2xl font-poppins font-semibold flex justify-center mb-16 mt-10">
                                                    {' '}
                                                    Select The Report Format
                                                </h2>
                                            </div>
                                            <div className="flex space-x-10 justify-evenly">
                                                <button
                                                    onClick={generatePDF}
                                                    className="not_display_in_print w-28 h-10 p-1 px-2 box-content rounded-md bg-[#f8b286] focus:outline-none focus:bg-primary hover:bg-primary text-white shadow-md"
                                                >
                                                    <p className="font-poppins text-sm">
                                                        Budget Report PDF
                                                    </p>
                                                </button>

                                                <button
                                                    onClick={generateCSV}
                                                    className="not_display_in_print w-28 h-10 p-1 px-2 box-content rounded-md bg-[#f8b286] focus:outline-none focus:bg-primary hover:bg-primary text-white shadow-md"
                                                >
                                                    <p className="font-poppins text-sm">
                                                        Budget Report CSV
                                                    </p>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BudgetPage
