import React, { useState } from 'react'
import { TextField, MenuItem } from '@mui/material'
import ShowPrevAssetsDefects from './ShowPrevAssetsDefects'
import FilterAssets from './FilterAssets'
import FilterDefects from './FilterDefects'
import { selectImageResponse2 } from '../../slices/imageResponseSlice'
import { useSelector } from 'react-redux'
import { initialAssetsData, initialDefectsData } from './FilterData'

const RightComponent = ({ imageResponse1, originalImageFlag }) => {
    const [selectedTab, setSelectedTab] = useState('Assets')
    const { asset, defect } = useSelector(selectImageResponse2)
    const [searchTerm, setSearchTerm] = useState("")

    const handleTabClick = (tab) => {
        setSelectedTab(tab)
    }

    const getTotalDefectCount = () => {
        return originalImageFlag === 1 ? 0 : Object.values(defect).reduce((total, count) => total + count, 0);
    }

    const getTotalAssetCount = () => {
        return originalImageFlag === 1 ? 0 : Object.values(asset).reduce((total, count) => total + count, 0);
    }

    const totalDefectCount = getTotalDefectCount()
    const totalAssetsCount = getTotalAssetCount()

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value.toLowerCase())
    }

    const filteredAssets = initialAssetsData.filter((asset) =>
        asset.name.toLowerCase().includes(searchTerm)
    )

    const filteredDefects = initialDefectsData.filter((defect) =>
        defect.name.toLowerCase().includes(searchTerm)
    )

    return (
        <div className="w-full h-full overflow-hidden">
            <div
                className="flex flex-col relative mt-2 gap-y-2"
                style={{
                    width: 'full',
                    height: '80%',
                }}
            >
                <div className="flex">
                    <div
                        className="mx-auto flex bg-orange-400 rounded-xl px-2 py-2 gap-x-8"
                    >
                        <div
                            className={`rounded-xl px-4 font-poppins font-semibold cursor-pointer ${
                                selectedTab === 'Assets'
                                    ? 'text-white transition-all 200 ease-linear bg-red-600'
                                    : 'text-black'
                            } `}
                            onClick={() => handleTabClick('Assets')}
                        >
                            Assets ( {totalAssetsCount})
                        </div>
                        <div
                            className={`rounded-xl px-4 font-poppins font-semibold cursor-pointer ${
                                selectedTab === 'Defects'
                                    ? 'text-white transition-all 200 ease-linear bg-red-600'
                                    : 'text-black'
                            } `}
                            onClick={() => handleTabClick('Defects')}
                        >
                            Defects ( {totalDefectCount} )
                        </div>
                    </div>
                </div>
                <div
                    className="w-full px-4 flex-grow overflow-auto"
                    style={{
                        borderTop: '2px solid black',
                        borderBottom: '2px solid black',
                        flexGrow: 1,
                    }}
                >
                    {selectedTab === 'Assets' && (
                        <>
                            <MenuItem>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    placeholder="Search Assets"
                                    value={searchTerm}
                                    onChange={handleSearchChange}
                                    size="large"
                                    sx={{
                                        marginTop: 1,
                                        marginBottom: 1,
                                        "& .MuiOutlinedInput-input": {
                                            padding: "10px 16px",
                                            fontSize: "0.875rem",
                                        },
                                        "& .MuiOutlinedInput-root": {
                                            borderRadius: "10px",
                                        },
                                    }}
                                />
                            </MenuItem>
                            <FilterAssets assets={filteredAssets}
                            searchTerm={searchTerm} />
                        </>
                    )}
                    {selectedTab === 'Defects' && (
                        <>
                            <MenuItem>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    placeholder="Search Defects"
                                    value={searchTerm}
                                    onChange={handleSearchChange}
                                    size="large"
                                    sx={{
                                        marginTop: 1,
                                        marginBottom: 1,
                                        "& .MuiOutlinedInput-input": {
                                            padding: "10px 16px",
                                            fontSize: "0.875rem",
                                        },
                                        "& .MuiOutlinedInput-root": {
                                            borderRadius: "10px",
                                        },
                                    }}
                                />
                            </MenuItem>
                            <FilterDefects defects={filteredDefects}
                            searchTerm={searchTerm} />
                        </>
                    )}
                </div>
            </div>
            <div
                className="z-10 px-5"
                style={{
                    width: 'full',
                    height: '20%',
                }}
            >
                <p className="text-lg text-center font-bold">Previously Detected</p>
                <div className="border-[1px] mx-auto border-black w-full"></div>
                <ShowPrevAssetsDefects 
                    imageResponse1={imageResponse1} 
                    originalImageFlag={originalImageFlag}
                />
            </div>
        </div>
    )
}

export default RightComponent