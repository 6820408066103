import React from 'react'

const ShowPrevAssetsDefects = ({ imageResponse1, originalImageFlag }) => {
    // Create empty response when original image is selected
    console.log("flag", originalImageFlag)
    const displayResponse = originalImageFlag === 1 ? {
        asset: {},
        defect: {}
    } : imageResponse1

    return (
        <div className="flex overflow-hidden px-2" style={{ width: 'full' }}>
            <div
                style={{
                    width: '50%',
                    height: 'full',
                }}
            >
                <p>Assets :-</p>
                {displayResponse?.asset &&
                Object.keys(displayResponse.asset).length > 0 ? (
                    Object.entries(displayResponse.asset).map(
                        ([key, value], index) => (
                            <p key={index} className="text-xs">
                                {key} : {value}
                            </p>
                        )
                    )
                ) : (
                    <p className="ml-3 text-sm">No Assets</p>
                )}
            </div>
            <div className="w-[2px] h-[200px] border-[1px] mt-1 mr-4 border-black"></div>
            <div
                style={{
                    width: '50%',
                    height: 'full',
                }}
            >
                <p>Defects :-</p>
                {displayResponse?.defect &&
                Object.keys(displayResponse.defect).length > 0 ? (
                    Object.entries(displayResponse.defect).map(
                        ([key, value], index) => (
                            <p key={index} className="text-xs">
                                {key} : {value}
                            </p>
                        )
                    )
                ) : (
                    <p className="ml-3 text-sm">No Defects</p>
                )}
            </div>
        </div>
    )
}

export default ShowPrevAssetsDefects
