import { ShowForPermission } from '../../../accesscontrol/ShowPermissionComponent'
import { useModal } from '../../../hooks/useModal'
import { ConfirmModal } from '../../../utilsUser/ConfirmModal'
import { RiDeleteBin6Line } from 'react-icons/ri'
import { useDispatch, useSelector } from 'react-redux'
import { DeleteMemberByAdmin } from '../../../services/Operations/memberAPI'
import { formatDate } from '../../../utilsUser/formDate'
import { BiSolidEdit } from 'react-icons/bi'
import { useState } from 'react'
import UpdateMember from './UpdateMember'
import Modal from 'react-modal'
import { closeImg } from '../../../../assets/IconArray'
import { selectAuth } from '../../../slices/authSlice'

export const MemberCard = ({ members, office }) => {
    const dispatch = useDispatch()
    const { isOpen, openModal, closeModal, modalConfig } = useModal()
    const [updateMemberDetails, setUpdateMemberDetails] = useState(false)
    const [selectedMemberUserId, setSelectedMemberUserId] = useState(null)
    const [dialogOpen, setDialogOpen] = useState(false)
    const [memberDeleteId, setMemberDeleteId] = useState(null)
    const { userType } = useSelector(selectAuth);

    const handleMemberChanges = (memberUserId) => {
        setSelectedMemberUserId(memberUserId)
        setUpdateMemberDetails(true)
    }

    const handleDialogOpen = (memberId) => {
        setMemberDeleteId(memberId)
        setDialogOpen(true)
    }

    const handleClose = () => {
        setDialogOpen(false)
    }

    const handleDelete = () => {
        console.log('Member to delete: ', memberDeleteId)
        setDialogOpen(false)
        dispatch(DeleteMemberByAdmin(memberDeleteId)).then(() => {
            window.location.reload()
        })
    }

    return (
        <>
            {updateMemberDetails && (
                <UpdateMember 
                memberUserId={selectedMemberUserId} 
                setUpdateMemberDetails={setUpdateMemberDetails}
                />
            )}
            <div className="h-screen flex justify-center mt-10">
                <ConfirmModal
                    isOpen={isOpen}
                    closeModal={closeModal}
                    modalConfig={modalConfig}
                />
                <div>
                    <div className="grid grid-cols-3 gap-x-10 gap-y-5 justify-center items-center">
                        {members?.map((member, index) => (
                            <div
                                key={index}
                                className="border-[1px] border-[#FE6100] h-fit w-15vm rounded-md p-3 font-poppins flex-col gap-y-5"
                                style={{ margin: '15px', width: '27vw' }}
                            >
                                <div className="flex justify-between items-center">
                                    <h2 className="font-bold text-2xl mt-2">
                                        Member {index + 1}
                                    </h2>
                                    { (userType == "Admin" || userType == "Head") ? (
                                        <div
                                            onClick={() =>
                                                handleMemberChanges(member.user_id)
                                            }
                                        >
                                        <BiSolidEdit className="w-5 h-5 text-orange-500 cursor-pointer transform transition-transform duration-200 hover:scale-110" />
                                    </div>
                                    ) : " "} 
                                </div>
                                <div className="flex flex-col">
                                    <div className="flex items-center mt-2 gap-x-3">
                                        <div className="flex w-1/2 gap-3  justify-between">
                                            <p className="font-semibold">
                                                Name
                                            </p>
                                            <p>:</p>
                                        </div>
                                        <p className="font-normal text-sm flex-1 justify-start">
                                            {member?.name}
                                        </p>
                                    </div>

                                    <ShowForPermission permission="VIEW_USERNAME">
                                        <div className="flex items-center mt-2 gap-x-3">
                                            <div className="flex w-1/2 gap-3  justify-between">
                                                <p className="font-semibold">
                                                    Username
                                                </p>
                                                <p>:</p>
                                            </div>
                                            <p className="font-normal text-sm flex-1 justify-start">
                                                {member?.username}
                                            </p>
                                        </div>
                                    </ShowForPermission>

                                    <div className="flex items-center mt-2 gap-x-3">
                                        <div className="flex w-1/2 gap-3  justify-between">
                                            <p className="font-semibold">
                                                Email
                                            </p>
                                            <p>:</p>
                                        </div>
                                        <p className="font-normal text-sm flex-1 justify-start">
                                            {member?.email}
                                        </p>
                                    </div>
                                    <div className="flex items-center mt-2 gap-x-3">
                                        <div className="flex w-1/2 gap-3  justify-between">
                                            <p className="font-semibold">DOB</p>
                                            <p>:</p>
                                        </div>
                                        <p className="font-normal text-sm flex-1 justify-start">
                                            {formatDate(member?.dob)}
                                        </p>
                                    </div>
                                    <div className="flex items-center mt-2 gap-x-3">
                                        <div className="flex w-1/2 gap-3  justify-between">
                                            <p className="font-semibold">
                                                Phone No
                                            </p>
                                            <p>:</p>
                                        </div>
                                        <p className="font-normal text-sm flex-1 justify-start">
                                            {member?.phone}
                                        </p>
                                        <div
                                            onClick={() =>
                                                handleDialogOpen(member.user_id)
                                            }
                                        >
                                            <RiDeleteBin6Line className="h-5 w-5 text-primary hover:shadow-sm hover:scale-125 transition-all 200ms ease-in cursor-pointer" />
                                        </div>
                                        <Modal
                                            isOpen={dialogOpen}
                                            contentLabel="Update Member Dialog"
                                            style={{
                                                overlay: {
                                                    backgroundColor:
                                                        'rgba(255, 255, 255, 0.1)',
                                                    backdropFilter: 'blur(4px)',
                                                    zIndex: 100,
                                                },
                                                content: {
                                                    width: '50vw',
                                                    maxHeight: '25vh',
                                                    margin: 'auto',
                                                    overflow: 'hidden',
                                                    borderRadius: '25px',
                                                },
                                            }}
                                        >
                                            <div>
                                                <div
                                                    onClick={() =>
                                                        setDialogOpen(
                                                            !dialogOpen
                                                        )
                                                    }
                                                    className="flex justify-end"
                                                >
                                                    <img
                                                        src={closeImg.closeImg}
                                                        alt="X"
                                                        className="w-6 h-6 cursor-pointer"
                                                    />
                                                </div>
                                                <div className="flex flex-col w-full h-full justify-center items-center gap-8 mt-4">
                                                    <div className="text-2xl font-semibold font-poppins">
                                                        <h3>
                                                            Are you sure you
                                                            want to delete the
                                                            member?
                                                        </h3>
                                                    </div>
                                                    <div className="flex w-full justify-evenly items-center">
                                                        <div className="flex justify-between gap-x-8">
                                                            <button
                                                                className="bg-[#FE6100] text-white px-4 py-2 rounded-md"
                                                                onClick={
                                                                    handleDelete
                                                                }
                                                            >
                                                                Yes
                                                            </button>
                                                            <button
                                                                className="bg-[#FE6100] text-white px-4 py-2 rounded-md"
                                                                onClick={
                                                                    handleClose
                                                                }
                                                            >
                                                                No
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Modal>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    )
}
