import React, { useEffect, useState } from 'react'
import './StatisticBar.css';
import StatisticValue from './StatisticValue';
import { getStatisticsData } from '../../mapbox/services/Operations/StatisticAPI';
import { useDispatch } from 'react-redux';
// import {filterIcons,ImagesOnMap} from '../../assets/IconArray';
import {statisticsBarIcons} from '../../assets/IconArray';
import { Spinner } from '../../utils/Spinner';
import Modal from "react-modal";
import RoadConditionStats from './RoadConditionStats';
import { getOverviewReport } from '../../usermanagement/services/Operations/CoreAPIs/reportAPI';
import { ShowForPermission } from '../../usermanagement/accesscontrol/ShowPermissionComponent';

const StatisticBar = () => {
    const dispatch = useDispatch();
    const [statistics, setStatistics] = useState([]);
    const [loading, setLoading] = useState(true); 
    const [roadConditionDetails, setRoadConditionDetails] = useState(false);
    const [roadCondition, setroadCondition] = useState({});
    let roadConditionPieChartData = [];

    useEffect(()=>{
        async function fetchStatisticsData(){
            try {
                const statisticsData = await dispatch(getStatisticsData());

            const imageArray = {
                "CAUTIONARY-SIGN": statisticsBarIcons.cautionarySign,
                "HOTSPOT":statisticsBarIcons.hotspot,
                "INFORMATORY-SIGN":statisticsBarIcons.infosign ,
                "LEFT-CHEVRON": statisticsBarIcons.leftchevron,
                "RIGHT-CHEVRON": statisticsBarIcons.rightchevron,
                "MANDATORY-SIGN": statisticsBarIcons.mandatorysign,
                "MANHOLE":statisticsBarIcons.manhole,
                "MINOR-POTHOLE":statisticsBarIcons.minorpothole,
                "MAJOR-POTHOLE":statisticsBarIcons.majorpothole,
                "ROAD-PATCH":statisticsBarIcons.roadpatch,
                "SPEED-BREAKER":statisticsBarIcons.speedbreaker,
                "STREET-LIGHT": statisticsBarIcons.streetlamp,
                "TRAFFIC-LIGHT": statisticsBarIcons.trafficsignal,
                "ELECTRIC-FEEDER": statisticsBarIcons.electricfeeder,
                "GANTRY-BOARD": statisticsBarIcons.gantryboard,
                "PEDESTRIAN-CROSSING": statisticsBarIcons.pedestriancrossing,
                "TREES": statisticsBarIcons.tree,
                "RUMBLING-STRIP": statisticsBarIcons.rumblingstrip,
                "CCTV": statisticsBarIcons.cctv,
                "BUS-STOP": statisticsBarIcons.busstop,
                "HOARDING": statisticsBarIcons.hoarding,
                "ENCROACHMENT": statisticsBarIcons.encroachment,
                "WORK-IN-PROGRESS": statisticsBarIcons.workinprogress,
                "METRO-STATION": statisticsBarIcons.metrostation,
                "FOOTPATH": statisticsBarIcons.footpath,
                "CRACK": statisticsBarIcons.crack,
                "RAVELING": statisticsBarIcons.raveling,
                "TRENCH": statisticsBarIcons.trench,
                "DAMAGE-DIVIDER": statisticsBarIcons.divider,
            }

            const transformedData = Object.keys(statisticsData).flatMap(assetKey => 
                Object.keys(statisticsData[assetKey]).map(defectKey => ({
                    img: imageArray[defectKey],
                    name: defectKey, // Only defect name
                    count: statisticsData[assetKey][defectKey]
                }))
            );

            // Group by defect name
            const groupedData = transformedData.reduce((acc, item) => {
                const existing = acc.find(i => i.name === item.name);
                if (existing) {
                    existing.count += item.count;
                } else {
                    acc.push(item);
                }
                return acc;
            }, []);

            setStatistics(groupedData);
        }catch(e){
            console.log(e)
        }finally{
            setLoading(false);
        }
    }

        fetchStatisticsData();
    },[dispatch])

    useEffect(() => {
		async function fetchData() {
			try {
				const data = await dispatch(getOverviewReport());
				setroadCondition(data.percentage);
			} catch (error) {
				console.error('Error fetching data:', error);
			}
		}

		fetchData();
	}, []);

   if (Object.keys(roadCondition).length > 0) {
        Object.keys(roadCondition).forEach((point) => {
            roadConditionPieChartData.push({
                label: point,
                value: roadCondition[point]
            })
        })
    }

  return (
    <>
    <ShowForPermission permission='StatisticsBar'>
        <div className="statistic-bar text-center hide-scrollbar top-0 right-0">
            <div className='flex flex-col gap-3'>
                <div className={`flex flex-col pt-2 gap-1 cursor-pointer items-center ${roadConditionDetails ? 'bg-orange-300' : 'bg-slate-200'} rounded-lg transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-105`}
                    onClick={() => setRoadConditionDetails(!roadConditionDetails)}
                >
                    {
                        roadConditionDetails ? (
                            <div className=''>
                                <Modal
                                    isOpen = {roadConditionDetails}    
                                    contentLabel='road condition pychart'
                                    style={{
                                        overlay: {
                                            backgroundColor: "rgba(0, 0, 0, 0.5)",
                                            backdropFilter: "blur(4px)",
                                            zIndex: 30,
                                          },
                                          content: {
                                            width: "460px",
                                            height: "510px",
                                            borderRadius: "10px",
                                            marginLeft: "calc(100vw - 700px)",
                                        },
                                    }}
                                >
                                    <RoadConditionStats 
                                        roadConditionPieChartData = {roadConditionPieChartData}
                                    />
                                </Modal>
                            </div>
                        ) : (
                            ""
                        )
                    }

                    <img src={statisticsBarIcons.roadCondition} alt="roadCondition" width={100} height={100}/>
                    <div className=''>Road Condition</div>
                </div>
                <div className='text-xl font-bold py-1 bg-primary text-white sticky top-0 z-10 rounded-lg'>Statistics</div>
            </div>
            {loading ? ( 
                <Spinner/>
            ) : (
                statistics.map((value) => {
                    return <StatisticValue StatObj={value} key={value.name} />
                })
            )}        
        </div>
    </ShowForPermission> 
        </>
  )
}

export default StatisticBar;