//Assets Filter Data
export const initialAssetsData = [
    {
        id: 'STREET LIGHT',
        name: 'Street Light',
        count: 0,
        isChecked: false,
    },
    {
        id: 'MANHOLE',
        name: 'Manhole',
        count: 0,
        isChecked: false,
    },
    {
        id: 'TRAFFIC LIGHT',
        name: 'Traffic Light',
        count: 0,
        isChecked: false,
    },
    {
        id: 'TREES',
        name: 'Trees',
        count: 0,
        isChecked: false,
    },
    {
        id: 'MANDATORY SIGN',
        name: 'Mandatory Sign',
        count: 0,
        isChecked: false,
    },
    {
        id: 'CAUTIONARY SIGN',
        name: 'Cautionary Sign',
        count: 0,
        isChecked: false,
    },
    {
        id: 'INFORMATORY SIGN',
        name: 'Informatory Sign',
        count: 0,
        isChecked: false,
    },
    {
        id: 'PEDESTRIAN CROSSING',
        name: 'Pedestrian Crossing',
        count: 0,
        isChecked: false,
    },
    {
        id: 'SPEED BREAKER',
        name: 'Speed Breaker',
        count: 0,
        isChecked: false,
    },
    {
        id: 'RUMBLING STRIP',
        name: 'Rumbling Strip',
        count: 0,
        isChecked: false,
    },
    {
        id: 'CCTV',
        name: 'CCTV',
        count: 0,
        isChecked: false,
    },
    {
        id: 'GANTRY BOARD',
        name: 'Gantry Board',
        count: 0,
        isChecked: false,
    },
    {
        id: 'ELECTRIC FEEDER',
        name: 'Electric Feeder',
        count: 0,
        isChecked: false,
    },
    {
        id: 'BUS STOP',
        name: 'Bus Stop',
        count: 0,
        isChecked: false,
    },
    {
        id: 'HOARDING',
        name: 'Hoarding',
        count: 0,
        isChecked: false,
    },
    {
        id: 'ENCROACHMENT',
        name: 'Encroachment',
        count: 0,
        isChecked: false,
    },
    {
        id: 'TRENCH',
        name: 'Trench',
        count: 0,
        isChecked: false,
    },
    {
        id: 'WORK IN PROGRESS',
        name: 'Work in Progress',
        count: 0,
        isChecked: false,
    },
    {
        id: 'METRO STATION',
        name: 'Metro Station',
        count: 0,
        isChecked: false,
    },
    {
        id: 'FOOTPATH',
        name: 'Footpath',
        count: 0,
        isChecked: false,
    },
]

// Defects Filter data
export const initialDefectsData = [
    {
        id: 'MAJOR POTHOLE',
        name: 'Major Pothole',
        count: 0,
        isChecked: false,
    },
    {
        id: 'MINOR POTHOLE',
        name: 'Minor Pothole',
        count: 0,
        isChecked: false,
    },
    {
        id: 'CRACK',
        name: 'Crack',
        count: 0,
        isChecked: false,
    },
    {
        id: 'ROAD PATCH',
        name: 'Road Patch',
        count: 0,
        isChecked: false,
    },
    {
        id: 'DAMAGED DIVIDER',
        name: 'Damaged Divider',
        count: 0,
        isChecked: false,
    },
    {
        id: 'RAVELING',
        name: 'Raveling',
        count: 0,
        isChecked: false,
    },
]
