import React, { useRef, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import {
    editLatLng,
    saveAnnotation,
} from '../mapbox/services/Operations/BackOfficeAPI'
import { setLoading, selectPoint } from '../mapbox/slices/pointSlice'
import { Spinner } from '../utils/Spinner'
import Controls from './components/LeftComponent/Controls'
import EditCoordinates from './components/EditCoordinates'
import RightComponent from './components/RightComponent/RightComponent'
import ImageAnnotation from './components/LeftComponent/ImageAnnotation'
import SingleMap from '../mapbox/mapcomponents/SingleMap'
import FloatingMap from './FloatingMap'
import { selectProfile } from '../usermanagement/slices/profileSlice'
import {
    selectImageResponse2,
    emptyAsset,
    emptyDefect,
} from './slices/imageResponseSlice'
import { RiCloseCircleFill } from 'react-icons/ri'
import { getSinglePointData } from '../mapbox/services/Operations/PointsAPI'
import { MdCheckBoxOutlineBlank } from 'react-icons/md'
import { IoMdCheckboxOutline } from 'react-icons/io'
import { toast } from 'react-hot-toast'

const EditImage = () => {
    const location = useLocation()
    const dispatch = useDispatch()
    const { pointsData } = useSelector(selectPoint)
    const queryParams = new URLSearchParams(location.search)
    const lat = parseFloat(queryParams.get('lat'))
    const long = parseFloat(queryParams.get('long'))
    const flag = parseInt(queryParams.get('flag'))
    const predImage = queryParams.get('predImage')
    const [originalImageVisible, setOriginalImageVisible] = useState(false)
    const [originalImageFlag, setOriginalImageFlag] = useState(flag)
    const { profileUserData } = useSelector(selectProfile)
    const userName = profileUserData?.username
    const { asset, defect } = useSelector(selectImageResponse2)
    const loading = useSelector((state) => state.point.loading)
    const canvasRef = useRef(null)
    const [annotations, setAnnotations] = useState([])
    const [saveFlag, setSaveFlag] = useState(null)
    const [saveFlag1, setSaveFlag1] = useState(null)
    const containerRef = useRef(null)
    const customDivRef = useRef(null)
    const [coordinates, setCoordinates] = useState(null)
    const [editCoordinates, setEditCoordinates] = useState(null)
    const [imgSrc, setImgsrc] = useState(null)
    const [imageId, setImageId] = useState(null)
    const [imageResponse1, setImageResponse1] = useState({})
    const dragRef = useRef(null)
    const [imageResponse, setImageResponse] = useState({})
    const [roadName, setRoadName] = useState(null)
    const [roadNo, setRoadNo] = useState(null)
    const [mapHide, setMapHide] = useState(true)
    const [colors, setColors] = useState({
        border: '#E53C3C',
        text: '#FDFCFF',
        textBg: '#FA0310',
    })
    const [shapes, setShapes] = useState([])
    const [rotation, setRotation] = useState(0)
    const [viewerDimensions, setViewerDimensions] = useState({
        width: 0,
        height: 0,
    })
    const [newAssetData, setNewAssetData] = useState({})
    const [newDefectData, setNewDefectData] = useState({})

    // Helper function to calculate text width
    const measureText = (context, text, fontSize) => {
        context.font = `${fontSize}px Arial`
        return context.measureText(text).width
    }

    const downloadImage = () => {
        const canvas = canvasRef.current
        const context = canvas.getContext('2d')
        const img = new Image()
        img.src = `data:image/jpeg;base64,${imgSrc}`

        img.onload = () => {
            // Set canvas dimensions to match the image
            canvas.width = img.width
            canvas.height = img.height

            // Calculate scale factor between display size and actual image size
            const scaleX = img.width / viewerDimensions.width
            const scaleY = img.height / viewerDimensions.height

            // Clear the canvas
            context.clearRect(0, 0, canvas.width, canvas.height)

            // Apply rotation if needed
            if (rotation !== 0) {
                context.save()
                context.translate(canvas.width / 2, canvas.height / 2)
                context.rotate((rotation * Math.PI) / 180)
                context.translate(-canvas.width / 2, -canvas.height / 2)
            }

            // Draw the image
            context.drawImage(img, 0, 0)

            if (rotation !== 0) {
                context.restore()
            }

            // Draw all shapes from Konva
            shapes.forEach((shape) => {
                // Scale points to match original image dimensions
                const scaledPoints = shape.points.map((point) => ({
                    x: point.x * scaleX,
                    y: point.y * scaleY,
                }))

                // Draw the polygon
                context.beginPath()
                scaledPoints.forEach((point, index) => {
                    if (index === 0) {
                        context.moveTo(point.x, point.y)
                    } else {
                        context.lineTo(point.x, point.y)
                    }
                })
                context.closePath()

                // Set the stroke style
                context.strokeStyle = shape.color
                context.lineWidth = 3 * scaleX // Scale the line width
                context.stroke()

                // Fill with semi-transparent color
                context.fillStyle = `${shape.color}55`
                context.fill()

                // Draw the label if it exists
                if (shape.label) {
                    const centerX =
                        scaledPoints.reduce((sum, p) => sum + p.x, 0) /
                        scaledPoints.length
                    const centerY =
                        scaledPoints.reduce((sum, p) => sum + p.y, 0) /
                        scaledPoints.length

                    // Scale font size and calculate dynamic width
                    const fontSize = 14 * scaleX
                    const padding = 10 * scaleX // Padding on each side
                    const textWidth = measureText(
                        context,
                        shape.label,
                        fontSize
                    )
                    const boxWidth = textWidth + padding * 2
                    const boxHeight = 20 * scaleY

                    // Draw label background with the same color as the shape
                    context.fillStyle = shape.color
                    context.fillRect(
                        centerX - boxWidth / 2,
                        centerY - boxHeight / 2,
                        boxWidth,
                        boxHeight
                    )

                    // Draw label text
                    context.fillStyle = 'white'
                    context.font = `${fontSize}px Arial`
                    context.textAlign = 'center'
                    context.textBaseline = 'middle'
                    context.fillText(shape.label, centerX, centerY)
                }
            })

            // Example usage:
            const now = new Date()
            const timestamp = formatDate(now)
            const fileName = `${timestamp}_Lat${lat}_Lon${long}#${userName}.png`

            // Create download link
            const link = document.createElement('a')
            link.download = fileName
            link.href = canvas.toDataURL('image/png')
            link.click()
        }

        img.onerror = (error) => {
            console.error('Error loading image for download:', error)
            toast.error('Failed to download image')
        }
    }

    const imageFileBuffer = async () => {
        const canvas = canvasRef.current
        const context = canvas.getContext('2d')

        const img = new Image()
        img.src = `data:image/jpeg;base64,${imgSrc}`

        await new Promise((resolve, reject) => {
            img.onload = () => {
                canvas.width = img.width
                canvas.height = img.height

                context.drawImage(img, 0, 0)

                annotations.forEach((annotation) => {
                    context.beginPath()
                    context.moveTo(annotation.mark.x, annotation.mark.y)
                    context.lineTo(
                        annotation.mark.x + annotation.mark.width,
                        annotation.mark.y
                    )
                    context.lineTo(
                        annotation.mark.x + annotation.mark.width,
                        annotation.mark.y + annotation.mark.height
                    )
                    context.lineTo(
                        annotation.mark.x,
                        annotation.mark.y + annotation.mark.height
                    )
                    context.closePath()

                    // context.strokeStyle = 'red';
                    context.strokeStyle = colors.border
                    context.lineWidth = 4
                    context.stroke()

                    context.font = 'bold 16px Arial'
                    // context.fillStyle = 'red';
                    context.fillStyle = colors.textBg
                    context.fillRect(
                        annotation.mark.x,
                        annotation.mark.y - 20,
                        context.measureText(annotation.comment).width + 11,
                        21
                    )

                    context.fillStyle = colors.text
                    context.fillText(
                        annotation.comment,
                        annotation.mark.x + 6,
                        annotation.mark.y - 5
                    )
                })

                resolve()
            }

            img.onerror = (error) => {
                reject(error)
            }
        })

        const imageDataURL = canvas.toDataURL('image/png')

        const byteString = atob(imageDataURL.split(',')[1])
        const mimeString = imageDataURL
            .split(',')[0]
            .split(':')[1]
            .split(';')[0]
        const ab = new ArrayBuffer(byteString.length)
        const ia = new Uint8Array(ab)
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i)
        }
        const blob = new Blob([ab], { type: mimeString })

        return blob
    }

    useEffect(() => {
        const fetchData = async () => {
            dispatch(setLoading(true))
            try {
                const result = await dispatch(
                    editLatLng(lat, long, predImage, originalImageFlag)
                )

                setImgsrc(result?.image)
                setCoordinates(result?.coordinate)
                setImageId(result?.imageId)

                // Handle image response
                if (result?.imageResponse) {
                    setImageResponse1(result.imageResponse)

                    // Update assets and defects based on flag
                    if (originalImageFlag === 0) {
                        // Update the asset counts
                        if (result.imageResponse.asset) {
                            Object.entries(result.imageResponse.asset).forEach(
                                ([key, value]) => {
                                    dispatch({
                                        type: 'imageResponse/updateAsset',
                                        payload: { type: key, count: value },
                                    })
                                }
                            )
                        }

                        // Update the defect counts
                        if (result.imageResponse.defect) {
                            Object.entries(result.imageResponse.defect).forEach(
                                ([key, value]) => {
                                    dispatch({
                                        type: 'imageResponse/updateDefect',
                                        payload: { type: key, count: value },
                                    })
                                }
                            )
                        }

                        // Set shapes if they exist
                        if (result.imageResponse.shapes) {
                            setShapes(result.imageResponse.shapes)
                        }
                    } else {
                        // Clear assets and defects for original image
                        dispatch(emptyAsset())
                        dispatch(emptyDefect())
                        setShapes([])
                    }
                }

                const points_lat_long = {
                    lat: lat,
                    long: long,
                }
                const data = await dispatch(getSinglePointData(points_lat_long))
                setRoadName(data?.roadName)
                setRoadNo(data?.roadNo)
            } catch (error) {
                console.error('Error fetching data:', error)
                toast.error('Failed to load image data')
            }
            dispatch(setLoading(false))
        }

        fetchData()
    }, [lat, long, predImage, originalImageFlag, dispatch])

    useEffect(() => {
        const fetchData = async () => {
            setSaveFlag1(true)
            if (saveFlag) {
                try {
                    const finalImageResponse = await mergeImageResponses(
                        imageResponse1,
                        asset,
                        defect
                    )
                    setImageResponse(finalImageResponse)
                } catch (error) {
                    console.error('Error while merging image responses:', error)
                }
            }
            setSaveFlag1(false)
        }

        fetchData()
    }, [saveFlag])

    function mergeImageResponses(imageResponse1, asset, defect) {
        let finalImageResponse = {
            asset: {},
            defect: {},
        }

        if (originalImageFlag === 0) {
            finalImageResponse = {
                asset: { ...imageResponse1?.asset },
                defect: { ...imageResponse1?.defect },
            }
        }

        for (let assetType in asset) {
            if (finalImageResponse.asset.hasOwnProperty(assetType)) {
                finalImageResponse.asset[assetType] += asset[assetType]
            } else {
                finalImageResponse.asset[assetType] = asset[assetType]
            }
        }

        for (let defectType in defect) {
            if (finalImageResponse.defect.hasOwnProperty(defectType)) {
                finalImageResponse.defect[defectType] += defect[defectType]
            } else {
                finalImageResponse.defect[defectType] = defect[defectType]
            }
        }
        return finalImageResponse
    }

    const handleSave = async () => {
        try {
            dispatch(setLoading(true))
            const now = new Date()
            const timestamp = formatDate(now)
            const fileName = `${timestamp.replace(/[:#]/g, '-')}_Lat${lat}_Lon${long}_${userName.replace(/#/g, '-')}`

            const canvas = canvasRef.current
            const context = canvas.getContext('2d')
            const img = new Image()
            img.src = `data:image/jpeg;base64,${imgSrc}`

            img.onload = () => {
                canvas.width = img.width
                canvas.height = img.height

                const scaleX = img.width / viewerDimensions.width
                const scaleY = img.height / viewerDimensions.height

                // Clear the canvas
                context.clearRect(0, 0, canvas.width, canvas.height)

                if (rotation !== 0) {
                    context.save()
                    context.translate(canvas.width / 2, canvas.height / 2)
                    context.rotate((rotation * Math.PI) / 180)
                    context.translate(-canvas.width / 2, -canvas.height / 2)
                }

                context.drawImage(img, 0, 0)

                if (rotation !== 0) {
                    context.restore()
                }

                shapes.forEach((shape) => {
                    const scaledPoints = shape.points.map((point) => ({
                        x: point.x * scaleX,
                        y: point.y * scaleY,
                    }))

                    // Draw the polygon
                    context.beginPath()
                    scaledPoints.forEach((point, index) => {
                        if (index === 0) {
                            context.moveTo(point.x, point.y)
                        } else {
                            context.lineTo(point.x, point.y)
                        }
                    })
                    context.closePath()

                    // Set the stroke style with thinner line
                    context.strokeStyle = shape.color
                    context.lineWidth = 1.5 * scaleX // Reduced from 3

                    // Fill with more transparent color
                    context.fillStyle = `${shape.color}22` // Changed from 55 to 22 for less opacity
                    
                    context.stroke()
                    context.fill()

                    // Draw the label if it exists
                    if (shape.label) {
                        const minX = Math.min(...scaledPoints.map(p => p.x));
                        const maxX = Math.max(...scaledPoints.map(p => p.x));
                        const centerY = scaledPoints.reduce((sum, p) => sum + p.y, 0) / scaledPoints.length;
                        
                        // Calculate if shape is in left or right half of canvas
                        const isInLeftHalf = (minX + maxX) / 2 < canvas.width / 2;
                        
                        // Scale font size and calculate dynamic width
                        const fontSize = 14 * scaleX;
                        const padding = 10 * scaleX;
                        const textWidth = measureText(context, shape.label, fontSize);
                        const boxWidth = textWidth + padding * 2;
                        const boxHeight = 20 * scaleY;
                        
                        // Position label on right if shape is on left, and vice versa
                        const labelX = isInLeftHalf 
                            ? maxX + 15 * scaleX
                            : minX - boxWidth - 15 * scaleX;

                        // Draw label background
                        context.fillStyle = shape.color;
                        context.fillRect(
                            labelX,
                            centerY - boxHeight / 2,
                            boxWidth,
                            boxHeight
                        );

                        // Draw label text
                        context.fillStyle = 'white';
                        context.font = `${fontSize}px Arial`;
                        context.textAlign = 'center';
                        context.textBaseline = 'middle';
                        context.fillText(
                            shape.label,
                            labelX + boxWidth / 2,
                            centerY
                        );
                    }
                })

                // Convert the canvas to a blob
                canvas.toBlob(async (blob) => {
                    const formData = new FormData()
                    formData.append('anotation', blob, fileName)
                    formData.append('roadName', roadName)
                    formData.append('roadNo', roadNo)
                    formData.append('imageId', imageId)

                    // Initialize empty objects for merged data
                    let mergedAssets = {}
                    let mergedDefects = {}

                    if (originalImageFlag === 0) {
                        // For predicted image, merge with existing predictions
                        if (imageResponse1?.asset) {
                            mergedAssets = { ...imageResponse1.asset }
                            mergedDefects = { ...imageResponse1.defect }
                        }

                        // Add new annotations to existing ones
                        Object.entries(asset).forEach(([key, value]) => {
                            mergedAssets[key] = (mergedAssets[key] || 0) + value
                        })

                        Object.entries(defect).forEach(([key, value]) => {
                            mergedDefects[key] =
                                (mergedDefects[key] || 0) + value
                        })
                    } else {
                        // For original image, only use new annotations
                        mergedAssets = { ...asset }
                        mergedDefects = { ...defect }
                    }

                    // Add new data from shapes if needed
                    Object.entries(newAssetData).forEach(([key, value]) => {
                        mergedAssets[key] = (mergedAssets[key] || 0) + value
                    })

                    Object.entries(newDefectData).forEach(([key, value]) => {
                        mergedDefects[key] = (mergedDefects[key] || 0) + value
                    })

                    const imageResponseData = {
                        asset: mergedAssets,
                        defect: mergedDefects,
                        shapes: shapes,
                    }

                    formData.append(
                        'imageResponse',
                        JSON.stringify(imageResponseData)
                    )

                    const result = await dispatch(saveAnnotation(formData))

                    if (result?.status === 200) {
                        setNewAssetData({})
                        setNewDefectData({})
                        // toast.success('Annotations saved successfully');
                        setTimeout(() => {
                            window.location.reload();
                        }, 500);
                    }
                }, 'image/png')
            }

            img.onerror = (error) => {
                console.error('Error loading image for saving:', error)
                toast.error('Failed to save annotation')
            }
        } catch (error) {
            console.error('Error in saveAnnotation:', error)
            toast.error('Failed to save annotation')
        } finally {
            dispatch(setLoading(false))
        }
    }

    useEffect(() => {
        let offsetX,
            offsetY,
            isDragging = false

        const handleMouseDown = (e) => {
            if (e.target === dragRef.current) {
                isDragging = true
                offsetX =
                    e.clientX - dragRef.current.getBoundingClientRect().left
                offsetY =
                    e.clientY - dragRef.current.getBoundingClientRect().top
            }
        }

        const handleMouseMove = (e) => {
            if (!isDragging) return
            const newX = e.clientX - offsetX
            const newY = e.clientY - offsetY
            dragRef.current.style.left = `${newX}px`
            dragRef.current.style.top = `${newY}px`
        }

        const handleMouseUp = () => {
            isDragging = false
        }

        if (!dragRef.current) return

        dragRef.current.addEventListener('mousedown', handleMouseDown)
        document.addEventListener('mousemove', handleMouseMove)
        document.addEventListener('mouseup', handleMouseUp)

        return () => {
            if (dragRef.current) {
                dragRef.current.removeEventListener(
                    'mousedown',
                    handleMouseDown
                )
            }
            document.removeEventListener('mousemove', handleMouseMove)
            document.removeEventListener('mouseup', handleMouseUp)
        }
    }, [mapHide])

    const formatDate = (date) => {
        const year = date.getFullYear()
        const month = ('0' + (date.getMonth() + 1)).slice(-2)
        const day = ('0' + date.getDate()).slice(-2)
        let hours = date.getHours()
        const minutes = ('0' + date.getMinutes()).slice(-2)
        const seconds = ('0' + date.getSeconds()).slice(-2)
        const ampm = hours >= 12 ? 'pm' : 'am'

        hours = hours % 12
        hours = hours ? hours : 12

        const formattedDate = `${year}-${month}-${day}_${hours}:${minutes}:${seconds}${ampm}`
        return formattedDate
    }

    const handleOriginalImage = () => {
        if (originalImageFlag === 0) {
            setOriginalImageFlag(1)
            // Clear assets and defects when switching to original image
            dispatch(emptyAsset())
            dispatch(emptyDefect())
            setShapes([])
        } else {
            setOriginalImageFlag(0)
            // Restore predicted image data
            if (imageResponse1?.asset) {
                Object.entries(imageResponse1.asset).forEach(([key, value]) => {
                    dispatch({
                        type: 'imageResponse/updateAsset',
                        payload: { type: key, count: value },
                    })
                })
            }
            if (imageResponse1?.defect) {
                Object.entries(imageResponse1.defect).forEach(
                    ([key, value]) => {
                        dispatch({
                            type: 'imageResponse/updateDefect',
                            payload: { type: key, count: value },
                        })
                    }
                )
            }
            if (imageResponse1?.shapes) {
                setShapes(imageResponse1.shapes)
            }
        }
        setOriginalImageVisible(!originalImageVisible)
    }

    // Function to update new assets
    const addNewAsset = (assetType, count) => {
        setNewAssetData((prev) => ({
            ...prev,
            [assetType]: (prev[assetType] || 0) + count,
        }))
    }

    // Function to update new defects
    const addNewDefect = (defectType, count) => {
        setNewDefectData((prev) => ({
            ...prev,
            [defectType]: (prev[defectType] || 0) + count,
        }))
    }

    return (
        <div className="h-screen w-screen relative overflow-hidden ">
            {/* {loading ? (
				<Spinner />
			) : ( */}
            <>
                <div
                    className="h-screen w-screen flex bg-white "
                    style={{
                        filter:
                            // saveFlag || editCoordinates
                            editCoordinates ? 'blur(5px' : 'none',
                    }}
                >
                    <div
                        style={{
                            width: '77%',
                        }}
                        className="flex flex-col gap-y-2 "
                    >
                        <div className="flex justify-end mr-14 mt-6">
                            <div
                                className="flex flex-row justify-center items-center cursor-pointer text-white font-poppins font-semibold rounded-md bg-orange-500 px-2 py-1 hover:scale-105 transition-transform duration-300"
                                onClick={handleOriginalImage}
                            >
                                {originalImageVisible ? (
                                    <IoMdCheckboxOutline className="w-5 h-5 mr-2" />
                                ) : (
                                    <MdCheckBoxOutlineBlank className="w-5 h-5 mr-2" />
                                )}
                                Edit Original Image
                            </div>
                        </div>
                        <div
                            style={{
                                height: '85%',
                                borderBottom: '2px solid black',
                                zIndex: 60,
                            }}
                            className="flex justify-center items-center p-4"
                        >
                            {/* {loading ? (
								<Spinner />
							) : ( */}
                            <ImageAnnotation
                                flag={originalImageFlag}
                                imgSrc={imgSrc}
                                canvasRef={canvasRef}
                                annotations={annotations}
                                setAnnotations={setAnnotations}
                                containerRef={containerRef}
                                customDivRef={customDivRef}
                                loading={loading}
                                shapes={shapes}
                                setShapes={setShapes}
                                rotation={rotation}
                                setRotation={setRotation}
                                viewerDimensions={viewerDimensions}
                                setViewerDimensions={setViewerDimensions}
                            />
                            {/* )} */}
                        </div>
                        <div
                            style={{
                                height: '15%',
                                // border: '2px solid blue',
                            }}
                            className="flex justify-center items-center"
                        >
                            <Controls
                                downloadImage={downloadImage}
                                originalImageFlag={originalImageFlag}
                                setEditCoordinates={setEditCoordinates}
                                coordinates={coordinates}
                                setCoordinates={setCoordinates}
                                setSaveFlag={setSaveFlag}
                                setMapHide={setMapHide}
                                mapHide={mapHide}
                                setColors={setColors}
                                colors={colors}
                            />
                        </div>
                    </div>
                    <div
                        style={{
                            width: '23%',
                            //  border: '2px solid green',
                            borderLeft: '2px solid black',
                        }}
                    >
                        <div
                            className=""
                            style={{
                                height: '100%',
                                // border: '2px solid blue',
                            }}
                        >
                            <RightComponent
                                originalImageFlag={originalImageFlag}
                                imageResponse1={imageResponse1}
                            />
                        </div>
                    </div>
                </div>
                {editCoordinates && (
                    <div className="absolute top-0 left-0 w-[100%] h-[100%] flex justify-center  items-center">
                        <div
                            className="border-2 bg-white relative rounded-lg flex items-center"
                            style={{ width: '40%', height: '80%' }}
                        >
                            <EditCoordinates
                                setEditCoordinates={setEditCoordinates}
                                coordinates={coordinates}
                                imageId={imageId}
                            />
                        </div>
                    </div>
                )}
                {saveFlag && (
                    <div
                        className="absolute top-0 left-0 w-[100%] h-[100%] flex justify-center items-end overflow-hidden pb-5"
                        style={{ zIndex: 62 }}
                    >
                        {!saveFlag1 ? (
                            <div
                                className="border-2 bg-white  relative rounded-lg flex flex-col mb-4 mr-8"
                                style={{ width: '40%', height: '40%' }}
                            >
                                <div className="flex justify-end cursor-pointer text-primary">
                                    <RiCloseCircleFill
                                        className="w-7 h-7"
                                        onClick={() => setSaveFlag(false)}
                                    />
                                </div>
                                <div className="border-2 border-green-400 mx-8 p-1 mb-2  rounded-lg  h-full">
                                    <div
                                        className="flex gap-x-2 "
                                        style={{ height: '80%' }}
                                    >
                                        <div
                                            className="border-2 border-red-500 flex flex-col rounded-lg flex-grow overflow-auto"
                                            style={{
                                                height: 'full',
                                                width: '50%',
                                                flexGrow: 1,
                                            }}
                                        >
                                            <p className="font-serif font-semibold text-center">
                                                Total Assests : &nbsp;{' '}
                                            </p>
                                            {imageResponse &&
                                            imageResponse.asset &&
                                            Object.keys(imageResponse.asset)
                                                .length > 0 ? (
                                                <div className="ml-4 text-sm">
                                                    <ul>
                                                        {Object.entries(
                                                            imageResponse.asset
                                                        ).map(
                                                            ([key, value]) => (
                                                                <li key={key}>
                                                                    {key} :
                                                                    &nbsp;
                                                                    {value}
                                                                </li>
                                                            )
                                                        )}
                                                    </ul>
                                                </div>
                                            ) : (
                                                <div className="ml-4 text-sm">
                                                    No assets
                                                </div>
                                            )}
                                        </div>
                                        <div
                                            className="border-2 border-red-500 flex flex-col rounded-lg flex-grow overflow-auto "
                                            style={{
                                                height: 'full',
                                                width: '50%',
                                                flexGrow: 1,
                                            }}
                                        >
                                            <p className="font-serif font-semibold text-center">
                                                Total Defects : &nbsp;
                                            </p>
                                            {imageResponse &&
                                            imageResponse.defect &&
                                            Object.keys(imageResponse.defect)
                                                .length > 0 ? (
                                                <div className="ml-4 text-sm">
                                                    <ul>
                                                        {Object.entries(
                                                            imageResponse.defect
                                                        ).map(
                                                            ([key, value]) => (
                                                                <li key={key}>
                                                                    {key}:
                                                                    &nbsp;
                                                                    {value}
                                                                </li>
                                                            )
                                                        )}
                                                    </ul>
                                                </div>
                                            ) : (
                                                <div className="ml-4 text-xs">
                                                    No defects
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="flex justify-evenly items-center mt-2">
                                        <button
                                            className="bg-orange-500 text-white font-bold py-1 px-4 rounded transform transition-transform duration-200 hover:scale-105 active:scale-95 focus:outline-none"
                                            onClick={() => setSaveFlag(false)}
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            className={`bg-orange-500 text-white font-bold py-1 px-4 rounded transform transition-transform duration-200 hover:scale-105 active:scale-95 focus:outline-none ${loading ? 'cursor-not-allowed opacity-50' : 'hover:bg-orange-600'}`}
                                            onClick={
                                                loading ? null : handleSave
                                            }
                                            disabled={loading}
                                        >
                                            {loading ? <Spinner /> : 'CONFIRM'}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <Spinner />
                        )}
                    </div>
                )}
            </>
            {/* )} */}
            {!mapHide && (
                <div
                    ref={dragRef}
                    className="absolute flex justify-center select-none"
                    style={{
                        width: '22rem',
                        height: '20rem',
                        right: '5%',
                        bottom: '5%',
                        borderRadius: '5px',
                        background: '#feebb7',
                        zIndex: 900,
                        cursor: 'move',
                        padding: '2px  ',
                        position: 'absolute',
                    }}
                >
                    <div
                        style={{
                            width: '22rem',
                            height: '18rem',
                            borderRadius: '5px',
                            zIndex: 999,
                            overflow: 'hidden',
                            position: 'relative',
                        }}
                    >
                        <FloatingMap
                            lat={lat}
                            long={long}
                            points={pointsData}
                        />
                    </div>
                    <div className="absolute bottom-0 mb-1"> Drag Here</div>
                </div>
            )}
        </div>
    )
}

export default EditImage
