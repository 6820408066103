import { createSlice } from '@reduxjs/toolkit'

// initial state

const initialState = {
	loading: null,
	pointsLoaded: null,
	pointsData: null,
	dataLoaded:null,
	pointStatus:null,
	heatMapLoading : false,
	heatMapPointsData : null,
	// removeMarker:null,
 
	
};

const pointSlice = createSlice({
	name: 'point',
	initialState: initialState,
	reducers: {
		setLoading(state, action) {
			state.loading = action.payload;
		},
		setPointsData(state, action) {
			state.pointsData = action.payload;
			state.pointsLoaded = true;
		}, 
		setPointStatus(state, action) {
			state.pointStatus = action.payload;
		},
		setHeatMapLoading(state, action) {
			state.heatMapLoading = action.payload;
		},
		setHeatMapPointsData(state, action) {
			state.heatMapPointsData = action.payload;
		}, 
		// setRemoveMarker(state, action) {
		// 	state.removeMarker = action.payload;
		// },
	


		 
	},
});

export const { setLoading, setPointsData, setPointStatus,setRemoveMarker, setHeatMapPointsData, setHeatMapLoading } = pointSlice.actions;
export const selectPoint = (state) => state.point;
export default pointSlice.reducer;
