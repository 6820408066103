import mapboxgl from "mapbox-gl"
import { defectColorTheme, defectColors, priorityOrder } from "./PointRules"

export let marker = null
let mapRef = null
export let currentLayerIds = []

export const plotPointsHeatMap = (map, points, label, setFloatCoordinates) => {
    // console.log(points, "points...")
    mapRef = map
    function addLayerWithVisibilityToggle(layerId, sourceId) {
        map.addLayer(
            {
                id: layerId,
                type: "heatmap",
                source: sourceId,
                // maxzoom: 15,
                paint: {
                    "heatmap-weight": [
                        "interpolate",
                        ["linear"],
                        ["get", "density"],
                        0,
                        0,
                        1,
                        1,
                    ],
                    "heatmap-intensity": [
                        "interpolate",
                        ["linear"],
                        ["zoom"],
                        0,
                        1,
                        9,
                        3, // Increase the intensity slightly at higher zoom levels
                        12,
                        10, // Further increase the intensity when zoomed in
                    ],
                    "heatmap-color": [
                        "interpolate",
                        ["linear"],
                        ["heatmap-density"],
                        0,
                        "rgba(33,102,172,0)",
                        0.2,
                        "rgb(103,169,207)",
                        0.4,
                        "rgb(209,229,240)",
                        0.6,
                        "rgb(253,219,199)",
                        0.8,
                        "rgb(239,138,98)",
                        1,
                        "rgb(178,24,43)",
                    ],
                    "heatmap-radius": [
                        "interpolate",
                        ["linear"],
                        ["zoom"],
                        0,
                        2, // Small radius at low zoom levels
                        3,
                        15, // Larger radius at medium zoom levels
                        5,
                        25, // Much larger radius when zoomed in
                    ],
                    "heatmap-opacity": [
                        "interpolate",
                        ["linear"],
                        ["zoom"],
                        7,
                        1, // Full opacity at mid zoom
                        9,
                        0.9, // Slightly decrease opacity
                        12,
                        0.6, // More transparent when zoomed in
                    ],
                },
                transition: {
                    duration: 0.8,
                    delay: 0.5,
                },
            },
            "waterway-label"
        )

        // Call toggleLayerVisibility after the layer is added
        currentLayerIds.push(layerId)
        //toggleLayerVisibility(layerId, 0);
        map.on("mouseenter", layerId, () => {
            map.getCanvas().style.cursor = "pointer"
        })

        map.on("mouseleave", layerId, () => {
            map.getCanvas().style.cursor = ""
        })
    }

    // Function to update data on the map
    const updateData = (points) => {
        let validPoints = points.filter(
            (point) =>
                point?.lat !== null &&
                point?.long !== null &&
                point?.pred_image !== null
        )
        const pointGroups = groupPoints(validPoints)

        Object.keys(pointGroups).forEach((color) => {
            const layerId = `heatmap-point-layer`
            const featureCollection = {
                type: "FeatureCollection",
                features: pointGroups[color].map((point, index) => ({
                    type: "Feature",
                    geometry: {
                        type: "Point",
                        coordinates: [point.long, point.lat],
                    },
                    properties: {
                        index: index,
                        circleColor: color,
                        coordinates: [point.long, point.lat],
                        mapType: "Point",
                    },
                })),
            }

            const sourceId = `heatmap-point-source`

            // Check if source already exists
            if (!map.getSource(sourceId)) {
                // If it doesn't exist, add it
                map.addSource(sourceId, {
                    type: "geojson",
                    data: featureCollection,
                })
            } else {
                // Update existing source data
                map.getSource(sourceId).setData(featureCollection)
            }

            // Check if layer already exists
            if (!map.getLayer(layerId)) {
                // If it doesn't exist, add it with visibility toggle
                addLayerWithVisibilityToggle(layerId, sourceId)
            }

            map.on("click", layerId, (e) => {
                // console.log('clicked on point',layerId)
                // console.log("label ",label);

                const features = map.queryRenderedFeatures(e.point, {
                    layers: [layerId],
                })
                // console.log('onclick inside plotpoints');

                if (features.length > 0) {
                    const clickedFeature = features[0]
                    // console.log(
                    // 	'Clicked feature:',
                    // 	clickedFeature.properties.circleColor
                    // );
                    const coordinates = clickedFeature.properties.coordinates
                    const [lng, lat] = JSON.parse(coordinates)
                    const clickedPoint = [lng, lat]
                    // console.log('Clicked point:', clickedPoint);
                    // openModalCallback(clickedPoint);
                    if (label == "float") {
                        setFloatCoordinates(clickedPoint)
                    }
                    // if (clickedPoint) {
                    // 	// Remove previous marker if available
                    // 	if (marker) {
                    // 		RemoveMarker();
                    // 	}
                    // 	AddMarker(clickedPoint);
                    // }
                }
            })
        })
    }

    updateData(points)
}

export const removeHeatmapPoints = () => {
    currentLayerIds.forEach((layerId) => {
        mapRef.removeLayer(layerId)
    })
    currentLayerIds = []
}

function groupPoints(points) {
    const defaultColor = "#de5f5f"

    const colorAssignments = points.map((point) => ({
        point,
        color: defaultColor,
    }))

    const groups = colorAssignments.reduce((groups, { point, color }) => {
        groups[color] = groups[color] || []
        groups[color].push(point)
        return groups
    }, {})

    return groups
}

export function getCurrentLayerIdsOfHeatMapPoints() {
    return currentLayerIds
}
