// Level wise array

export const levelArray = [
    { name: 'All', checked: true },
    { name: 'Region', checked: false },
    { name: 'Circle', checked: false },
    { name: 'Division', checked: false },
    { name: 'Sub-division', checked: false },
]

export const ACCOUNT_TYPE = {
    USER: 'user',
    OWNER: 'owner',
    JE: 'je',
}

export const REQUEST_TYPE = {
    DELETE_PROFILE: 'delete_profile',
    DELETE_OFFICE: 'delete_office',
}

export const STATUS = {
    STATUS_TYPE: 'pending',
}
