// MapComponent1.js
const Map1 = ({ mapRef }) => {
    return (
        <div
            ref={mapRef}
            style={{ width: '100%', height: '100%', borderRadius: '12px' }}
        />
    )
}

export default Map1
