import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    loading: false,
}

const officeSlice = createSlice({
    name: 'office',
    initialState: initialState,
    reducers: {
        setLoading(state, value) {
            state.loading = value.payload
        },
        setOffices(state, value) {
            state.officesData = value.payload
        },
    },
})

export const { setLoading, setOffices } = officeSlice.actions
export default officeSlice.reducer
