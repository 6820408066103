import React, { useState } from 'react'
import { FaCircleCheck } from 'react-icons/fa6'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getPaymentOrderID, PaymentVerification } from './PaymentAPI';
import { store } from '../../redux/store/aioutputstore';
import toast from 'react-hot-toast';
import { selectProfile } from '../../usermanagement/slices/profileSlice';

const PricingPlan = () => {
    const [plan, setPlan] = useState('Monthly')
    let {profileUserData} = useSelector(selectProfile);
    const dispatch = useDispatch()
    const navigate = useNavigate();

    const loadScript = (src) => {
        return new Promise((resolve) => {
          const script = document.createElement("script");
    
          script.src = src;
    
          script.onload = () => {
            resolve(true)
          }
          script.onerror = () => {
            resolve(false)
          }
    
          document.body.appendChild(script);
        })
    }
    
    const createRazorpayOrder = async (amount) => {
        let data = {
          amount: amount * 100,
          currency: "INR"
        }

        let result = await dispatch(getPaymentOrderID(data));

        if(result.success == true){
            handleRazorpayScreen(result.result.amount , result.result.id);
        }else{
            toast.error("Something Went Wrong, Please try again!");
        }

    }

    const handleRazorpayScreen = async(amount , order_id) => {
        const res = await loadScript("https:/checkout.razorpay.com/v1/checkout.js")
    
        if (!res) {
          toast.error("Something Went Wrong, Please try again!")
          return;
        }
    
        const options = {
          key: import.meta.env.VITE_RAZORPAY_KEY,
          amount: amount,
          currency: 'INR',
          order_id : order_id,
          name: profileUserData.username,
          description: "payment to buy premium subscription",
          handler: function (response){
            handlePaymentVerification(response);
          },
          prefill: {
            name: profileUserData.username,
            email: profileUserData.email
          },
          theme: {
            color: "#f97316"
          }
        }
    
        const paymentObject = new window.Razorpay(options)
        paymentObject.open()
    }

    const handlePaymentVerification = async (response) => {
        
        let result = await dispatch(PaymentVerification(response))

        if(result.success == true){
            localStorage.clear();
            store.dispatch({ type: "RESET" })
            toast.success("Payment Successfull, Please login again to avail premium features");
            navigate("/login")
        }else{
            toast.error("Payment Failed, please try again");
        }
    }
    return (
        <div className=" flex flex-col items-center  py-10">
            <div className="text-3xl text-center font-bold text-orange-500">
                Choose Your right plan
            </div>
            <div className="w-1/2 text-center">
                Select from best plans, ensuring a perfect match. Need more or
                less? Customize your subscription for a seamless fit!
            </div>
            <div className=" rounded-2xl border border-slate-300 mt-5 ">
                <button
                    type="button"
                    className={`rounded-xl  px-6 py-1 ${plan === 'Monthly' ? 'bg-orange-500' : 'bg-transparent text-black'} `}
                    onClick={() => setPlan('Monthly')}
                >
                    Monthly
                </button>
                <button
                    type="button"
                    className={`px-6 py-1 rounded-xl ${plan === 'Yearly' ? 'bg-orange-500' : 'bg-transparent'}`}
                    onClick={() => setPlan('Yearly')}
                >
                    Yearly
                </button>
            </div>
            <div className="flex md:flex-row flex-col gap-10 md:w-3/4 px-10 mt-10">
                <div className="border border-slate-300 rounded-xl md:w-1/3 shadow-2xl p-5 ">
                    <div className="p-3 border-b border-slate-300 flex flex-col gap-1">
                        <div className="text-sm font-semibold">Free Plan</div>
                        <div className="text-2xl">₹0</div>
                        <div className="text-sm font-medium">Current Plan</div>
                    </div>
                    <div className="text-sm">
                        <div className="mt-3">FEATURES</div>
                        <div className="flex gap-2 ">
                            <span className="h-2 w-2 mr-2">
                                <FaCircleCheck color="#008000" />
                            </span>
                            Map Themes (Black and White)
                        </div>
                        <div className="flex gap-2 ">
                            <span className="h-2 w-2 mr-2">
                                <FaCircleCheck color="#008000" />
                            </span>
                            Search Roads
                        </div>
                        <div className="flex gap-2 ">
                            <span className="h-2 w-2 mr-2">
                                <FaCircleCheck color="#008000" />
                            </span>
                            Plotting filter condition points
                        </div>
                        <div className="flex gap-2 ">
                            <span className="h-2 w-2 mr-2">
                                <FaCircleCheck color="#008000" />
                            </span>
                            Report Section (Pay for report and get download
                            option)
                        </div>
                        <div className="flex gap-2 ">
                            <span className="h-2 w-2 mr-2">
                                <FaCircleCheck color="#008000" />
                            </span>
                            View each and every Icon in the left bar to increase
                            interaction with the features.
                        </div>
                        <div className="flex gap-2 ">
                            <span className="h-2 w-2 mr-2">
                                <FaCircleCheck color="#008000" />
                            </span>
                            Road Condition, Inventory Condition, Pothole Count,
                            Major Bridges, Minor Bridges, Culvert Counts
                            (Tentative count with sign)
                        </div>
                        <div className="flex gap-2 ">
                            <span className="h-2 w-2 mr-2">
                                <FaCircleCheck color="#008000" />
                            </span>
                            Plot all defects at once on the map (Restrict
                            plotting individual defects on the map)
                        </div>
                        <div className="flex gap-2 ">
                            <span className="h-2 w-2 mr-2">
                                <FaCircleCheck color="#008000" />
                            </span>
                            Calculator Section (Only View)
                        </div>
                        <div className="flex gap-2 ">
                            <span className="h-2 w-2 mr-2">
                                <FaCircleCheck color="#008000" />
                            </span>
                            Utilities Dashboard (Only View)
                        </div>
                        <div className="flex gap-2 ">
                            <span className="h-2 w-2 mr-2">
                                <FaCircleCheck color="#008000" />
                            </span>
                            Comparison Analysis (Only View)
                        </div>
                    </div>
                    <div className="flex justify-center mt-16">
                        <button className="px-6 py-1 bg-orange-500 text-white rounded-lg">
                            Already Activated
                        </button>
                    </div>
                </div>
                <div className="border border-slate-300 rounded-xl md:w-1/3 shadow-2xl p-5 ">
                    <div className="p-3 border-b border-slate-300 flex flex-col gap-1">
                        <div className="text-sm font-semibold">Gold Plan</div>
                        <div className="text-2xl">
                            {plan === 'Monthly' ? '₹14,999' : '₹1,79,988'}
                        </div>
                        <div className="text-sm font-medium">Flexi Plan</div>
                    </div>
                    <div className="text-sm">
                        <div className="mt-3">FEATURES</div>
                        <div className="flex gap-2 ">
                            <span className="h-2 w-2 mr-2">
                                <FaCircleCheck color="#008000" />
                            </span>
                            All Map Themes present in free plan + Blue and
                            Satellite
                        </div>
                        <div className="flex gap-2 ">
                            <span className="h-2 w-2 mr-2">
                                <FaCircleCheck color="#008000" />
                            </span>
                            Accurate detailed report with paid charges for Road
                            Condition, Inventory Condition, Pothole Count, Major
                            Bridges Count, Minor Bridges Count, Culvert Count
                            (600 for report per km)
                        </div>
                        <div className="flex gap-2 ">
                            <span className="h-2 w-2 mr-2">
                                <FaCircleCheck color="#008000" />
                            </span>
                            RCI
                        </div>
                        <div className="flex gap-2 ">
                            <span className="h-2 w-2 mr-2">
                                <FaCircleCheck color="#008000" />
                            </span>
                            Screenshot
                        </div>
                        <div className="flex gap-2 ">
                            <span className="h-2 w-2 mr-2">
                                <FaCircleCheck color="#008000" />
                            </span>
                            All features present in free + Plot and view
                            specific road defect on the map.
                        </div>
                        <div className="flex gap-2 ">
                            <span className="h-2 w-2 mr-2">
                                <FaCircleCheck color="#008000" />
                            </span>
                            Lower price in report and detailed road report, road
                            roughness report
                        </div>
                        <div className="flex gap-2 ">
                            <span className="h-2 w-2 mr-2">
                                <FaCircleCheck color="#008000" />
                            </span>
                            Show location, road number, image, start and end
                            chainnage, date and time and defect.
                        </div>
                        <div className="flex gap-2 ">
                            <span className="h-2 w-2 mr-2">
                                <FaCircleCheck color="#008000" />
                            </span>
                            Map Comparison
                        </div>
                        <div className="flex gap-2 ">
                            <span className="h-2 w-2 mr-2">
                                <FaCircleCheck color="#008000" />
                            </span>
                            Enable Calculator Section
                        </div>
                        <div className="flex gap-2 ">
                            <span className="h-2 w-2 mr-2">
                                <FaCircleCheck color="#008000" />
                            </span>
                            Utilities Dashboard (Only View)
                        </div>
                        <div className="flex gap-2 ">
                            <span className="h-2 w-2 mr-2">
                                <FaCircleCheck color="#008000" />
                            </span>
                            Comparison Analysis (Compare trips)
                        </div>
                    </div>
                    <div className="flex justify-center mt-6">
                        <button className="px-6 py-1 border border-slate-400 rounded-lg">
                            Subscribe
                        </button>
                    </div>
                </div>
                <div className=" border border-slate-300 rounded-xl md:w-1/3 shadow-2xl p-5 ">
                    <div className="p-3 border-b border-slate-300 flex flex-col gap-1">
                        <div className="text-sm font-semibold">
                            Enterprise Plan
                        </div>
                        <div className="text-2xl">Let's Connect</div>
                        <div className="text-sm font-medium">Custom Plan</div>
                    </div>
                    <div className="text-sm">
                        <div className="mt-3">FEATURES</div>
                        <div className="flex gap-2 ">
                            <span className="h-2 w-2 mr-2">
                                <FaCircleCheck color="#008000" />
                            </span>
                            All features present in Free and Gold plan +
                            Customization according to the user
                        </div>
                        <div className="flex gap-2 ">
                            <span className="h-2 w-2 mr-2">
                                <FaCircleCheck color="#008000" />
                            </span>
                            Accurate detailed report with paid charges for Road
                            Condition, Inventory Condition, Pothole Count, Major
                            Bridges Count, Minor Bridges Count, Culvert Count
                            (400 for report per km)
                        </div>
                        <div className="flex gap-2 ">
                            <span className="h-2 w-2 mr-2">
                                <FaCircleCheck color="#008000" />
                            </span>
                            3D View
                        </div>
                        <div className="flex gap-2 ">
                            <span className="h-2 w-2 mr-2">
                                <FaCircleCheck color="#008000" />
                            </span>
                            Screenshot
                        </div>
                        <div className="flex gap-2 ">
                            <span className="h-2 w-2 mr-2">
                                <FaCircleCheck color="#008000" />
                            </span>
                            Heat Map
                        </div>
                        <div className="flex gap-2 ">
                            <span className="h-2 w-2 mr-2">
                                <FaCircleCheck color="#008000" />
                            </span>
                            RCI
                        </div>
                        <div className="flex gap-2 ">
                            <span className="h-2 w-2 mr-2">
                                <FaCircleCheck color="#008000" />
                            </span>
                            Image Enlargement, Play Option, Video link, navigate
                            to Google maps and comment/feedback
                        </div>
                        <div className="flex gap-2 ">
                            <span className="h-2 w-2 mr-2">
                                <FaCircleCheck color="#008000" />
                            </span>
                            Mentioned in Free plan + Arboriculture, Public Works
                            Department, Budget Report
                        </div>
                    </div>
                    <div className="flex justify-center mt-16">
                        <button className="px-6 py-1 border border-slate-400 rounded-lg"  onClick={() => createRazorpayOrder(14999)}>
                            Subscribe
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PricingPlan
