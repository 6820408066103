import { ImagesOnMap } from '../../assets/IconArray'

export let currentLayerIdsOfAsset = []

export const plotMultipleAssets = (
    map,
    pointsData,
    openModalCallback,
    setAssetLayerId
) => {
    // Log the pointsData to see what all comes in points
    // console.log('pointsData:', pointsData);

    function getIconSize(asset) {
        // You can adjust icon sizes based on asset types as needed
        switch (asset) {
            case 'streetlightonmap':
                return 0.5
            case 'leftchevrononmap':
                return 0.5
            case 'rightchevrononmap':
                return 0.5
            case 'trafficlightonmap':
                return 0.5
            case 'mandatorysignonmap':
                return 0.5
            case 'cautionarysignonmap':
                return 0.5
            case 'informatorysignonmap':
                return 0.5
            case 'electicfeederonmap':
                return 0.5
            case 'gantryboardonmap':
                return 0.5
            case 'pedestraincrossingonmap':
                return 0.5
            case 'treesonmap':
                return 0.5
            case 'speedbreakeronmap':
                return 0.5
            case 'rumblingstriponmap':
                return 0.5
            case 'cctvonmap':
                return 0.5
            case 'busstoponmap':
                return 0.5
            case 'hoardingonmap':
                return 0.5
            case 'encroachmentonmap':
                return 0.5
            case 'workinprogressonmap':
                return 0.5
            case 'metrostationonmap':
                return 0.5
            case 'footpathonmap':
                return 0.5
            case 'manholeonmap':
                return 0.5
            case 'commenticononmap':
                return 0.5
            default:
                return 0.1 // Default size
        }
    }

    // Function to add a layer and call the toggleLayerVisibility function afterwards
    function addLayerWithVisibilityToggle(layerId, sourceId, iconImage, asset) {
        const iconSize = getIconSize(asset)
        map.loadImage(iconImage, (error, image) => {
            if (error) throw error

            // Add the image to the map style.
            map.addImage(layerId, image)

            map.addLayer({
                id: layerId,
                type: 'symbol',
                source: sourceId,
                layout: {
                    'icon-image': layerId, // Use the layerId as the icon image
                    'icon-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        11,
                        0.1,
                        12,
                        0.2,
                        13,
                        0.3,
                        14,
                        0.4,
                        15,
                        0.5,
                        16,
                        0.6, // Adjusted from 16.5
                        17,
                        0.7,
                        18,
                        0.8,
                        20,
                        0.9,
                        21,
                        1,
                        22,
                        1.1,
                    ],
                    'icon-allow-overlap': true,
                },
                paint: {
                    // You can add paint properties here if needed
                },
            })

            // Call toggleLayerVisibility after the layer is added
            currentLayerIdsOfAsset.push(layerId)
            map.on('mouseenter', layerId, () => {
                map.getCanvas().style.cursor = 'pointer'
            })

            map.on('mouseleave', layerId, () => {
                map.getCanvas().style.cursor = ''
            })
        })
    }

    // Function to update data on the map
    const updateData = (updatedPoints) => {
        let validPoints = updatedPoints.filter(
            (point) =>
                point?.lat !== null &&
                point?.long !== null &&
                point?.pred_image !== null
        )

        const pointGroups = groupPointsByAsset(validPoints)
        setAssetLayerId(pointGroups)

        Object.keys(pointGroups).forEach((asset) => {
            const layerId = `${asset}-point-layer`
            const featureCollection = {
                type: 'FeatureCollection',
                features: pointGroups[asset].map((point, index) => ({
                    type: 'Feature',
                    geometry: {
                        type: 'Point',
                        coordinates: [point[0], point[1]],
                    },
                    properties: {
                        index: index,
                        circleColor: asset,
                        coordinates: [point[0], point[1]],
                        mapType: 'Point',
                    },
                })),
            }

            const sourceId = `${asset}-point-source`

            // Check if source already exists
            if (!map.getSource(sourceId)) {
                // If it doesn't exist, add it
                map.addSource(sourceId, {
                    type: 'geojson',
                    data: featureCollection,
                })
            } else {
                // Update existing source data
                map.getSource(sourceId).setData(featureCollection)
            }

            // Check if layer already exists
            if (map && !map.getLayer(layerId)) {
                addLayerWithVisibilityToggle(
                    layerId,
                    sourceId,
                    ImagesOnMap[asset],
                    asset
                )
            }
        })
    }

    updateData(pointsData)
}

const groupPointsByAsset = (points) => {
    return points.reduce((groups, point) => {
        let asset = ''
        if (point.pred_image === false && point?.asset === null) {
            asset = 'green'
        }
        if (
            point.pred_image === true &&
            point?.asset !== null &&
            point?.asset['STREET-LIGHT'] > 0
        ) {
            // console.log('point:....',  point?.asset["STREET-LIGHT"]);
            asset = 'streetlightonmap'
            if (!groups[asset]) {
                groups[asset] = []
            }
            groups[asset].push([point.long, point.lat])
        }
        if (
            point.pred_image === true &&
            point?.asset !== null &&
            point?.asset['TRAFFIC-LIGHT'] > 0
        ) {
            // console.log('point:....',  point?.asset);
            asset = 'trafficlightonmap'
            if (!groups[asset]) {
                groups[asset] = []
            }
            groups[asset].push([point.long, point.lat])
        }
        if (
            point.pred_image === true &&
            point?.asset !== null &&
            point?.asset['LEFT-CHEVRON'] &&
            point?.asset['LEFT-CHEVRON'] > 0
        ) {
            asset = 'leftchevrononmap'
            if (!groups[asset]) {
                groups[asset] = []
            }
            groups[asset].push([point.long, point.lat])
        }
        if (
            point.pred_image === true &&
            point?.asset !== null &&
            point?.asset['RIGHT-CHEVRON'] &&
            point?.asset['RIGHT-CHEVRON'] > 0
        ) {
            asset = 'rightchevrononmap'
            if (!groups[asset]) {
                groups[asset] = []
            }
            groups[asset].push([point.long, point.lat])
        }
        if (
            point.pred_image === true &&
            point?.asset !== null &&
            point?.asset['CAUTIONARY-SIGN'] &&
            point?.asset['CAUTIONARY-SIGN'] > 0
        ) {
            asset = 'cautionarysignonmap'
            if (!groups[asset]) {
                groups[asset] = []
            }
            groups[asset].push([point.long, point.lat])
        }
        if (
            point.pred_image === true &&
            point?.asset !== null &&
            point?.asset['MANDATORY-SIGN'] &&
            point?.asset['MANDATORY-SIGN'] > 0
        ) {
            // console.log('point:....',  point?.asset);
            asset = 'mandatorysignonmap'
            if (!groups[asset]) {
                groups[asset] = []
            }
            groups[asset].push([point.long, point.lat])
        }
        if (
            point.pred_image === true &&
            point?.asset !== null &&
            point?.asset['INFORMATORY-SIGN'] &&
            point?.asset['INFORMATORY-SIGN'] > 0
        ) {
            asset = 'informatorysignonmap'
            if (!groups[asset]) {
                groups[asset] = []
            }
            groups[asset].push([point.long, point.lat])
        }
        if (
            point.pred_image === true &&
            point?.asset !== null &&
            point?.asset['ELECTRIC-FEEDER'] &&
            point?.asset['ELECTRIC-FEEDER'] > 0
        ) {
            asset = 'electicfeederonmap'
            if (!groups[asset]) {
                groups[asset] = []
            }
            groups[asset].push([point.long, point.lat])
        }
        if (
            point.pred_image === true &&
            point?.asset !== null &&
            point?.asset['GANTRY-BOARD'] &&
            point?.asset['GANTRY-BOARD'] > 0
        ) {
            asset = 'gantryboardonmap'
            if (!groups[asset]) {
                groups[asset] = []
            }
            groups[asset].push([point.long, point.lat])
        }
        if (
            point.pred_image === true &&
            point?.asset !== null &&
            point?.asset['PEDESTRIAN-CROSSING'] &&
            point?.asset['PEDESTRIAN-CROSSING'] > 0
        ) {
            asset = 'pedestraincrossingonmap'
            if (!groups[asset]) {
                groups[asset] = []
            }
            groups[asset].push([point.long, point.lat])
        }
        if (
            point.pred_image === true &&
            point?.asset !== null &&
            point?.asset['TREES'] &&
            point?.asset['TREES'] > 0
        ) {
            // console.log('point:....',  point?.asset);
            asset = 'treesonmap'
            if (!groups[asset]) {
                groups[asset] = []
            }
            groups[asset].push([point.long, point.lat])
        }
        if (
            point.pred_image === true &&
            point?.asset !== null &&
            point?.asset['SPEED-BREAKER'] &&
            point?.asset['SPEED-BREAKER'] > 0
        ) {
            asset = 'speedbreakeronmap'
            if (!groups[asset]) {
                groups[asset] = []
            }
            groups[asset].push([point.long, point.lat])
        }
        if (
            point.pred_image === true &&
            point?.asset !== null &&
            point?.asset['RUMBLING-STRIP'] &&
            point?.asset['RUMBLING-STRIP'] > 0
        ) {
            asset = 'rumblingstriponmap'
            if (!groups[asset]) {
                groups[asset] = []
            }
            groups[asset].push([point.long, point.lat])
        }
        if (
            point.pred_image === true &&
            point?.asset !== null &&
            point?.asset['CCTV'] &&
            point?.asset['CCTV'] > 0
        ) {
            asset = 'cctvonmap'
            if (!groups[asset]) {
                groups[asset] = []
            }
            groups[asset].push([point.long, point.lat])
        }
        if (
            point.pred_image === true &&
            point?.asset !== null &&
            point?.asset['BUS-STOP'] &&
            point?.asset['BUS-STOP'] > 0
        ) {
            asset = 'busstoponmap'
            if (!groups[asset]) {
                groups[asset] = []
            }
            groups[asset].push([point.long, point.lat])
        }
        if (
            point.pred_image === true &&
            point?.asset !== null &&
            point?.asset['HOARDING'] &&
            point?.asset['HOARDING'] > 0
        ) {
            asset = 'hoardingonmap'
            if (!groups[asset]) {
                groups[asset] = []
            }
            groups[asset].push([point.long, point.lat])
        }
        if (
            point.pred_image === true &&
            point?.asset !== null &&
            point?.asset['ENCROACHMENT'] &&
            point?.asset['ENCROACHMENT'] > 0
        ) {
            asset = 'encroachmentonmap'
            if (!groups[asset]) {
                groups[asset] = []
            }
            groups[asset].push([point.long, point.lat])
        }
        if (
            point.pred_image === true &&
            point?.asset !== null &&
            point?.asset['WORK-IN-PROGRESS'] &&
            point?.asset['WORK-IN-PROGRESS'] > 0
        ) {
            asset = 'workinprogressonmap'
            if (!groups[asset]) {
                groups[asset] = []
            }
            groups[asset].push([point.long, point.lat])
        }
        if (
            point.pred_image === true &&
            point?.asset !== null &&
            point?.asset['METRO-STATION'] &&
            point?.asset['METRO-STATION'] > 0
        ) {
            asset = 'metrostationonmap'
            if (!groups[asset]) {
                groups[asset] = []
            }
            groups[asset].push([point.long, point.lat])
        }
        if (
            point.pred_image === true &&
            point?.asset !== null &&
            point?.asset['FOOTPATH'] &&
            point?.asset['FOOTPATH'] > 0
        ) {
            asset = 'footpathonmap'
            if (!groups[asset]) {
                groups[asset] = []
            }
            groups[asset].push([point.long, point.lat])
        }
        // if (
        //     point.pred_image === true &&
        //     point?.defect !== null &&
        //     point?.defect['MANHOLE'] &&
        //     point?.defect['MANHOLE'] > 0
        // ) {
        //     asset = 'manholeonmap';
        //     if (!groups[asset]) {
        //         groups[asset] = [];
        //     }
        //     groups[asset].push([point.long, point.lat]);
        // }
        if (point.comment === true) {
            asset = 'commenticononmap'
            if (!groups[asset]) {
                groups[asset] = []
            }
            groups[asset].push([point.long, point.lat])
        }

        return groups
    }, {})
}

export function getCurrentLayerIdsOfAsset() {
    return currentLayerIdsOfAsset
}
