import React, { useEffect, useState } from 'react'
import '../CSS/Utils.css'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Sidebar from './Sidebar'
import { row, dropdowndata } from './ListView/constants'
import { extractAllRoads } from '../utils/utils'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { getSurveyedRoadData } from '../mapbox/services/Operations/RoadsAPI'
import { ShowForPermission } from '../usermanagement/accesscontrol/ShowPermissionComponent'
import { useSidebar } from './Context/SidebarContext'
import { selectTrip } from '../mapbox/slices/tripSlice'
import { BackButton } from './Utils/BackButton'
import ListViewPagination from './ListViewMode/ListViewPagination'
import './ListViewMode/ListViewMode.css'

const ListView = () => {
    const [loading, setLoading] = useState(null)
    const [roadList, setRoadList] = useState([])
    const { selectedOfficeName, selectedOfficeLevel } = useSelector(selectTrip)
    const searchQuery = {
        officeLevel: selectedOfficeLevel,
        officeName: selectedOfficeName,
    }

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true)
            setListViewOpen(false)

            try {
                const result = await dispatch(
                    getSurveyedRoadData(setLoading, searchQuery)
                )
                setRoadList(result)
            } catch (error) {
                console.error('Error fetching road data:', error)
            }
            setLoading(false)
        }

        fetchData()
    }, [selectedOfficeName, selectedOfficeLevel])

    const { userType } = useSelector((state) => state.auth)
    const [selectedRegion, setSelectedRegion] = useState(null)
    const [selectedCircle, setSelectedCircle] = useState(null)
    const [selectedDivision, setSelectedDivision] = useState(null)
    const [selectedSubDivision, setSelectedSubDivision] = useState(null)
    const [openRegion, setOpenRegion] = React.useState(false)
    const [openCircle, setOpenCircle] = React.useState(false)
    const [openDivision, setOpenDivision] = React.useState(false)
    const [openSubDivision, setOpenSubDivision] = React.useState(false)
    const dispatch = useDispatch()
    const { listViewOpen, setListViewOpen } = useSidebar()

    // Handlers for dropdowns
    const handleRegionChange = (RegionId) => {
        const region = dropdowndata.find(
            (item) => item.id === parseInt(RegionId, 10)
        )
        if (region) setSelectedRegion(region)
        else setSelectedRegion('')
        setSelectedCircle(null)
        setSelectedDivision(null)
        setSelectedSubDivision(null)
    }
    const handleCircleChange = (CircleId) => {
        const Circle = selectedRegion.circle.find(
            (item) => item.id === parseInt(CircleId, 10)
        )
        setSelectedCircle(Circle)
        setSelectedDivision(null)
        setSelectedSubDivision(null)
    }
    const handleDivisionChange = (DivisionId) => {
        const Division = selectedCircle.division.find(
            (item) => item.id === parseInt(DivisionId, 10)
        )
        setSelectedDivision(Division)
        setSelectedSubDivision(null)
    }
    const handleSubDivisionChange = (SubDivisionId) => {
        const SubDivision = selectedDivision.subdivisions.find(
            (item) => item.id === parseInt(SubDivisionId, 10)
        )
        setSelectedSubDivision(SubDivision)
    }
    const handleCloseDrop = (val) => {
        switch (val) {
            case 'Select Region':
                setOpenRegion(false)
                break
            case 'Select Circle':
                setOpenCircle(false)
                break
            case 'Select Division':
                setOpenDivision(false)
                break
            case 'Select SubDivision':
                setOpenSubDivision(false)
                break
            default:
                break
        }
    }
    const handleOpenDrop = (val) => {
        switch (val) {
            case 'Select Region':
                setOpenRegion(true)
                break
            case 'Select Circle':
                setOpenCircle(true)
                break
            case 'Select Division':
                setOpenDivision(true)
                break
            case 'Select SubDivision':
                setOpenSubDivision(true)
                break
            default:
                break
        }
    }

    const renderDropdown = (items, onSelect, placeholder) => {
        if (placeholder === 'Select Region') {
            return (
                <>
                    <FormControl sx={{ m: 2, minWidth: 200 }}>
                        <InputLabel
                            id="demo-controlled-open-select-label"
                            style={{ color: '#FE6100' }}
                        >
                            {placeholder}
                        </InputLabel>
                        <Select
                            labelId="demo-controlled-open-select-label"
                            id="demo-controlled-open-select"
                            open={openRegion}
                            onClose={() => handleCloseDrop(placeholder)}
                            onOpen={() => handleOpenDrop(placeholder)}
                            label={placeholder}
                            onChange={(e) => onSelect(e.target.value)}
                            value={items ? items.id : ''}
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            {items &&
                                items.map((item) => (
                                    <MenuItem key={item.id} value={item.id}>
                                        {item.name}
                                    </MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                </>
            )
        } else if (placeholder === 'Select Circle') {
            return (
                <>
                    <FormControl sx={{ m: 2, minWidth: 200 }}>
                        <InputLabel id="demo-controlled-open-select-label">
                            {placeholder}
                        </InputLabel>
                        <Select
                            labelId="demo-controlled-open-select-label"
                            id="demo-controlled-open-select"
                            open={openCircle}
                            onClose={() => handleCloseDrop(placeholder)}
                            onOpen={() => handleOpenDrop(placeholder)}
                            label={placeholder}
                            onChange={(e) => onSelect(e.target.value)}
                            value={items ? items.id : ''}
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            {items &&
                                items.map((item) => (
                                    <MenuItem key={item.id} value={item.id}>
                                        {item.name}
                                    </MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                </>
            )
        } else if (placeholder === 'Select Division') {
            return (
                <>
                    <FormControl sx={{ m: 2, minWidth: 200 }}>
                        <InputLabel id="demo-controlled-open-select-label">
                            {placeholder}
                        </InputLabel>
                        <Select
                            labelId="demo-controlled-open-select-label"
                            id="demo-controlled-open-select"
                            open={openDivision}
                            onClose={() => handleCloseDrop(placeholder)}
                            onOpen={() => handleOpenDrop(placeholder)}
                            label={placeholder}
                            onChange={(e) => onSelect(e.target.value)}
                            value={items ? items.id : ''}
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            {items &&
                                items.map((item) => (
                                    <MenuItem key={item.id} value={item.id}>
                                        {item.name}
                                    </MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                </>
            )
        } else if (placeholder === 'Select SubDivision') {
            return (
                <>
                    <FormControl sx={{ m: 2, minWidth: 200 }}>
                        <InputLabel id="demo-controlled-open-select-label">
                            {placeholder}
                        </InputLabel>
                        <Select
                            labelId="demo-controlled-open-select-label"
                            id="demo-controlled-open-select"
                            open={openSubDivision}
                            onClose={() => handleCloseDrop(placeholder)}
                            onOpen={() => handleOpenDrop(placeholder)}
                            label={placeholder}
                            onChange={(e) => onSelect(e.target.value)}
                            value={items ? items.id : ''}
                        >
                            <MenuItem value={''}>
                                <em>None</em>
                            </MenuItem>
                            {items &&
                                items.map((item) => (
                                    <MenuItem key={item.id} value={item.id}>
                                        {item.name}
                                    </MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                </>
            )
        }
    }

    const [filteredData, setFilteredData] = useState([])

    const submitData = (RegionId, CircleId, DivisionId, SubDivisionId) => {
        const filteredRows = row.filter((row) => {
            const hasMatchingRegion =
                RegionId === null || row.RegionId === RegionId
            const hasMatchingCircle =
                CircleId === null || row.CircleId === CircleId
            const hasMatchingDivision =
                DivisionId === null || row.DivisionId === DivisionId
            const hasMatchingSubDivision =
                SubDivisionId === null || row.SubDivisionId === SubDivisionId

            return (
                hasMatchingRegion &&
                hasMatchingCircle &&
                hasMatchingDivision &&
                hasMatchingSubDivision
            )
        })

        const extractedRoads = extractAllRoads(
            selectedSubDivision ||
                selectedDivision ||
                selectedCircle ||
                selectedRegion
        )

        // Update the Circle with the filtered data
        setFilteredData(extractedRoads)
    }

    let row = []

    return (
        <div id="listViewMode" className="flex ">
            <div className="flex">
                <Sidebar />
                {listViewOpen && (
                    <div className="bg-white w-[320px] ">demo divv</div>
                )}
                {/* Render your dropdowns */}
            </div>

            {/* div list view */}
            <div
                className="flex flex-col ml-20 flex-1 h-screen justify-center items-center"
                style={{
                    width: 'calc(100% - 5rem)',
                }}
            >
                {/* <div>
                    <ShowForPermission permission="SELECT_LIST_VIEW_REGION">
                        <div className="flex items-center">
                            {renderDropdown(
                                dropdowndata,
                                handleRegionChange,
                                'Select Region'
                            )}
                            {selectedRegion &&
                                renderDropdown(
                                    selectedRegion.circle,
                                    handleCircleChange,
                                    'Select Circle'
                                )}
                            {selectedCircle &&
                                renderDropdown(
                                    selectedCircle.division,
                                    handleDivisionChange,
                                    'Select Division'
                                )}
                            {selectedDivision &&
                                renderDropdown(
                                    selectedDivision.subdivisions,
                                    handleSubDivisionChange,
                                    'Select SubDivision'
                                )}

                            <button
                                className="bg-orange-500 text-white px-4 py-2 rounded-md ml-4 h-[80%]"
                                onClick={() =>
                                    submitData(
                                        selectedRegion?.id,
                                        selectedCircle?.id,
                                        selectedDivision?.id,
                                        selectedSubDivision?.id
                                    )
                                }
                            >
                                Submit Data
                            </button>
                        </div>
                    </ShowForPermission>
                </div> */}

                {/* heading and table */}
                <ShowForPermission permission='ListOfTrips'>
                <div className="flex flex-col h-screen w-[95%]">
                    <div className="flex w-full justify-start pt-8 pb-2 px-2">
                        <div className="flex justify-center items-center">
                            <BackButton direct={-1} />
                        </div>
                        <h1 className="font-poppins font-bold text-4xl ml-10">
                            List of Trips
                        </h1>
                    </div>
                    <div className="flex-1 px-2 py-8 w-full overflow-hidden">
                        <div className="h-full w-full">
                            <ListViewPagination
                                rows={roadList}
                                rowsLoading={loading}
                                userType={userType}
                                selectedOfficeName={selectedOfficeName}
                                selectedOfficeLevel={selectedOfficeLevel}
                            />
                        </div>
                    </div>
                </div>
                </ShowForPermission>
            </div>
        </div>
    )
}

export default ListView
