import React from 'react'
import { useForm } from 'react-hook-form'

export const CSVConsentForm = ({ formData, setFormData }) => {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        defaultValues: formData,
    })

    const onSubmit = (data) => {
        setFormData(data)
    }

    return (
        <div className="flex justify-center items-center w-[90%] h-full flex-col gap-4">
            <div className="text-base w-full">
                <span className="font-semibold text-primary">Disclaimer</span>:
                AI Unika or Rasta.Ai is not liable or responsible for any
                modifications or changes made to the editable CSV file provided.
                Any alterations are made at your own discretion and risk.
            </div>
            <div className="w-full font-medium">
                Please enter your details and provide your consent to continue.
            </div>
            <form
                onSubmit={handleSubmit(onSubmit)}
                className="flex w-full flex-col gap-4"
            >
                <div className="flex flex-col w-full">
                    <label htmlFor="name" className="mb-1 w-full font-medium">
                        Name
                    </label>
                    <input
                        type="text"
                        id="name"
                        className={`p-2 outline-none hover:border-primary w-full rounded-md border ${errors.name ? 'border-red-500' : 'border-gray-300 focus:border-primary'}`}
                        placeholder="Enter your name"
                        {...register('name', { required: 'Name is required' })}
                    />
                    {errors.name && (
                        <span className="text-red-500 text-sm mt-1">
                            {errors.name.message}
                        </span>
                    )}
                </div>

                <div className="flex flex-col w-full">
                    <label
                        htmlFor="designation"
                        className="mb-1 font-medium w-full"
                    >
                        Designation
                    </label>
                    <input
                        type="text"
                        id="designation"
                        className={`p-2 outline-none w-full hover:border-primary rounded-md border ${errors.designation ? 'border-red-500' : 'border-gray-300 focus:border-primary'}`}
                        placeholder="Enter your designation"
                        {...register('designation', {
                            required: 'Designation is required',
                        })}
                    />
                    {errors.designation && (
                        <span className="text-red-500 text-sm mt-1">
                            {errors.designation.message}
                        </span>
                    )}
                </div>

                <button
                    type="submit"
                    className="bg-primary text-white w-full py-2 px-4 mt-4 rounded-md hover:bg-orange-600"
                >
                    Accept and Continue
                </button>
            </form>
        </div>
    )
}
