import React,{useState,useEffect} from 'react'
import RoadRoughnessIndex from '../Details/RoadRoughnessIndex'

function DataoneMaintenance({data,roadName}) {
  const [currentRoad, setCurrentRoad] = useState('');
  const handleRoadChange = (roadName) => {
    setCurrentRoad(roadName);
  };
  return (
    <div>

            <h1 className="w-full flex justify-center items-center font-bold color-blue text-2xl">
                Maintenance Road Report of Each Road
            </h1>
            {/* <div className='mt-8'>
                <RoadRoughnessIndex />
            </div> */}
            <br />
            <div>
        {data && data.length > 0 ? (
          data.map((road, index) => (
            <div key={road.roadName || index}> 
              <h2 className="w-full text-xl font-semibold text-center">
                Road Condition Data For - {road.roadName}
              </h2>
              <br />
              {road.data && road.data.length > 0 ? (
                <>
                <div>
                  <h2 className="w-full flex justify-center items-center font-semibold">
                    Road Chainage - {`${road.data[0].from}-${road.data[road.data.length-1].to}`}
                  </h2>
                  <br />
                  <div>
                    <table className="table-auto border-collapse border border-gray-300 w-full">
                      <thead>
                        <tr>
                          <th className="border border-gray-300 p-2">Start Chainage</th>
                          <th className="border border-gray-300 p-2">End Chainage</th>
                          <th className="border border-gray-300 p-2">PCI Index</th>
                          <th className="border border-gray-300 p-2">IRI Index</th>
                          
                          <th className="border border-gray-300 p-2">Start Latitude, Longitude</th>
                          <th className="border border-gray-300 p-2">End Latitude, Longitude</th>
                          <th className="border border-gray-300 p-2">Road Type</th>
                          <th className="border border-gray-300 p-2">Category</th>
                          <th className="border border-gray-300 p-2">Remark</th>
                        </tr>
                      </thead>
                      <tbody>
                        {road.data.map((roadData, dataIndex) => (
                          <tr key={dataIndex} className="border-b border-gray-200">
                            <td className="border border-gray-300 p-2">{roadData.from}</td>
                            <td className="border border-gray-300 p-2">{roadData.to}</td>
                            <td className="border border-gray-300 p-2">{roadData.PCI}</td>
                            <td className="border border-gray-300 p-2">{roadData.IRI}</td>
                            <td className="border border-gray-300 p-2">
                              {roadData['Start Latitude, Longitude']}
                            </td>
                            <td className="border border-gray-300 p-2">
                              {roadData['End Latitude, Longitude']}
                            </td>
                            <td className="border border-gray-300 p-2">{roadData['Road Type']}</td>
                            <td className="border border-gray-300 p-2">{roadData['roadCategory']}</td>
                            <td className="border border-gray-300 p-2">
                              {roadData.Remarks ? Object.keys(roadData.Remarks)[0] : 'N/A'}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div>
                <h2 className="w-full flex justify-center items-center font-semibold mt-8 text-xl">
                  Budget Estimate report for - {road.roadName}
                </h2 >
                <h1 className="w-full flex justify-center items-center font-light mt-2 text-md mb-6 text-red-500">
                * The costs in this report are estimate and may vary.
                </h1>
                

                    <table className='w-full mb-8'>
                      <thead>
                        <tr>
                          <th className="border border-gray-300 p-2">Defects</th>
                          <th className="border border-gray-300 p-2">Cost (rupees)</th>
                          <th className="border border-gray-300 p-2">Found</th>
                          <th className="border border-gray-300 p-2">Total (rupees)</th>
                        </tr>
                      </thead>
                      <tbody>
                      {road.budget && road.budget.length > 0 ? (
                          road.budget.map((roadData, roadDataIndex) => (
                            <React.Fragment key={roadDataIndex}>
                              {Object.keys(roadData.total_detected).map((defect, defectIndex) => (
                                <tr key={defectIndex} className="border-b border-gray-200">
                                  <td className="border border-gray-300 p-2">{defect}</td>
                                  <td className="border border-gray-300 p-2">{roadData.cost[defect]}</td>
                                  <td className="border border-gray-300 p-2">{roadData.total_detected[defect]}</td>
                                  <td className="border border-gray-300 p-2">{roadData.toatlCostForEach[defect]}</td>
                                </tr>
                              ))}
                              <tr className="border-b border-gray-200">
                                <td className="border border-gray-300 p-2" colSpan="3">Total:</td>
                                <td className="border border-gray-300 p-2">{roadData.totalCost}</td>
                              </tr>
                            </React.Fragment>
                          ))
                        )  : (
                          <tr>
                            <td className="border border-gray-300 p-2" colSpan="4">No defect data available.</td>
                          </tr>
                        )}
                      </tbody>
                    </table>

                </div>
                </>
              ) : (
                <p>No road data available for {road.roadName}</p>
              )}
              <br />
            </div>
          ))
        ) : (
          <p>No maintenance report data available.</p>
        )}
      </div>
    </div>
  )
}

export default DataoneMaintenance