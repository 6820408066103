import React, { useRef, useState } from 'react'
import { styled } from '@mui/system'
import {
    TablePagination,
    tablePaginationClasses as classes,
} from '@mui/base/TablePagination'
import FirstPageRoundedIcon from '@mui/icons-material/FirstPageRounded'
import LastPageRoundedIcon from '@mui/icons-material/LastPageRounded'
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded'
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded'
import { IconButton } from '@mui/material'
import increaseIcon from '../../assets/markers/increaseIcon.png'
import decreaseIcon from '../../assets/markers/decreaseIcon.png'
import { FaRegEdit } from 'react-icons/fa'
import { Spinner } from '../../utils/Spinner'
import { Link } from 'react-router-dom'

const reverseAndFormatDate = (dateString) => {
    return dateString
}

export default function TableCustomized({
    rows,
    rowsLoading,
    userType,
    selectedOfficeName,
    selectedOfficeLevel,
}) {
    const rowsRef = useRef([])
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(15)

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }

    const colWidth =
        userType === 'Owner'
            ? {
                  1: { width: '6%' },
                  2: { width: '6%' },
                  3: { width: '15%' },
                  4: { width: '6%' },
                  5: { width: '6%' },
                  6: { width: '6%' },
                  7: { width: '7%' },
                  8: { width: '9%' },
                  9: { width: '7%' },
                  10: { width: '7%' },
                  11: { width: '7%' },
                  12: { width: '7%' },
                  13: { width: '3%' },
                  69 : {width : '15%'},
              }
            : {
                  1: { width: '8%' },
                  2: { width: '8%' },
                  3: { width: '15%' },
                  4: { width: '8%' },
                  5: { width: '8%' },
                  6: { width: '8%' },
                  7: { width: '15%' },
                  8: { width: '15%' },
                  69 : {width : '15%'},
              }

    return (
        <Root className="h-full" sx={{ width: '100%' }}>
            {rowsLoading ? (
                <Spinner height={'full'} />
            ) : (
                <div className="flex flex-col h-full w-full">
                    <div className="w-full flex-1 overflow-x-auto">
                        <table
                            className="upperTable"
                            aria-label="custom pagination table"
                        >
                            <thead>
                                <tr>
                                    <th style={colWidth[1]}>Date</th>
                                    <th style={colWidth[2]}>Road No</th>
                                    <th style={colWidth[3]}>Road Name</th>
                                    <th style={colWidth[69]}>Condition</th>
                                    <th style={colWidth[4]}>Start Chainage</th>
                                    <th style={colWidth[5]}>End Chainage</th>
                                    <th style={colWidth[6]}>Distance</th>
                                    <th style={colWidth[7]}>Type</th>
                                    <th style={colWidth[8]}>Engineer Name</th>
                                    {userType === 'Owner' && (
                                        <>
                                            <th style={colWidth[9]}>Region</th>
                                            <th style={colWidth[10]}>Circle</th>
                                            <th style={colWidth[11]}>
                                                Division
                                            </th>
                                            <th style={colWidth[12]}>
                                                Sub-Division
                                            </th>
                                            <th style={colWidth[13]}>Edit</th>
                                        </>
                                    )}
                                </tr>
                            </thead>
                            <tbody>
                                {rows && rows.length > 0 ? (
                                    (rowsPerPage > 0
                                        ? rows.slice(
                                              page * rowsPerPage,
                                              page * rowsPerPage + rowsPerPage
                                          )
                                        : rows
                                    ).map((row, key) => (
                                        <tr
                                            key={key}
                                            ref={(e) =>
                                                (rowsRef.current[key] = e)
                                            }
                                        >
                                            <td style={colWidth[1]}>
                                                {reverseAndFormatDate(row.Date)}
                                            </td>
                                            <td style={colWidth[2]}>
                                                {row?.roadNo}
                                            </td>
                                            <td
                                                style={{
                                                    ...colWidth[3],
                                                    cursor: 'pointer',
                                                }}
                                            >
                                                <Link
                                                    to={`/displaymap/road/${encodeURIComponent(
                                                        row.roadName
                                                    )}/${encodeURIComponent(
                                                        row.roadNo
                                                    )}-date-${encodeURIComponent(
                                                        reverseAndFormatDate(
                                                            row.Date
                                                        )
                                                    )}`}
                                                    className="text-blue-800"
                                                >
                                                    {row.roadName}
                                                </Link>
                                            </td>
                                            <td style={colWidth[69]}>
                                                <div className='flex flex-col w-full' >
                                                <div className='flex justify-around' >
                                                    <div className='text-green-500 flex items-center gap-1'><div className='h-2 w-2 bg-green-500 rounded-full'></div>{row.roadcondition.percentage.Good}% </div>
                                                    <div className='text-orange-500 flex items-center gap-1'><div className='h-2 w-2 bg-orange-500 rounded-full'></div>{row.roadcondition.percentage.Average}% </div>
                                                    <div className='text-red-500 flex items-center gap-1'><div className='h-2 w-2 bg-red-500 rounded-full'></div>{row.roadcondition.percentage.Poor}% </div>
                                                </div>
                                                <div className='w-full h-5 flex rounded-full'>
                                                    <div className='h-2 bg-green-500 ' style={{ width: `${row.roadcondition.percentage.Good}%` }}></div>
                                                    <div className='h-2 bg-orange-500  ' style={{ width: `${row.roadcondition.percentage.Average }%` }}></div>
                                                    <div className='h-2 bg-red-500 ' style={{ width: `${row.roadcondition.percentage.Poor}%` }}></div>
                                                </div>
                                                </div>
                                            </td>
                                            <td style={colWidth[4]}>
                                                {row?.startChainage}
                                            </td>
                                            <td style={colWidth[5]}>
                                                {row?.endChainage}
                                            </td>
                                            <td style={colWidth[6]}>
                                                {row?.distance}
                                            </td>
                                            <td style={colWidth[7]}>
                                                {row?.isAscending === '0' ? (
                                                    <DescendingBox></DescendingBox>
                                                ) : (
                                                    <AscendingBox></AscendingBox>
                                                )}
                                            </td>
                                            <td style={colWidth[8]}>
                                                {row?.juniorExecutiveName}
                                            </td>
                                            {userType === 'Owner' && (
                                                <>
                                                    <td style={colWidth[9]}>
                                                        {row?.region}
                                                    </td>
                                                    <td style={colWidth[10]}>
                                                        {row?.circle}
                                                    </td>
                                                    <td style={colWidth[11]}>
                                                        {row?.division}
                                                    </td>
                                                    <td style={colWidth[12]}>
                                                        {row?.subDivision}
                                                    </td>
                                                    <td
                                                        className="hover:scale-110"
                                                        style={colWidth[13]}
                                                    >
                                                        <Link
                                                            to={`/editRoadInfo/${row.roadId}`}
                                                            // state={{ roadData: row }}
                                                        >
                                                            <FaRegEdit className="text-primary w-5 h-5 " />
                                                        </Link>
                                                    </td>
                                                </>
                                            )}
                                        </tr>
                                    ))
                                ) : (
                                    <tr className="noFilter flex w-full h-full items-center justify-center">
                                        <td
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                width: '100%',
                                            }}
                                        >
                                            No Roads Available for
                                            <span className="font-semibold ml-2 mr-2">
                                                {selectedOfficeName}
                                            </span>
                                            in jurisdiction
                                            <span className="font-semibold ml-2">
                                                {selectedOfficeLevel}
                                            </span>
                                            !
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                    <table className="pagination-footer h-auto w-full">
                        <tfoot className="h-full w-full">
                            <tr>
                                <CustomTablePagination
                                    rowsPerPageOptions={[
                                        5,
                                        10,
                                        15,
                                        20,
                                        25,
                                        30,
                                        { label: 'All', value: rows.length },
                                    ]}
                                    colSpan={6}
                                    count={rows.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    slotProps={{
                                        select: {
                                            'aria-label': 'rows per page',
                                        },
                                        actions: {
                                            showFirstButton: true,
                                            showLastButton: true,
                                            slots: {
                                                firstPageIcon:
                                                    FirstPageRoundedIcon,
                                                lastPageIcon:
                                                    LastPageRoundedIcon,
                                                nextPageIcon:
                                                    ChevronRightRoundedIcon,
                                                backPageIcon:
                                                    ChevronLeftRoundedIcon,
                                            },
                                        },
                                    }}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={
                                        handleChangeRowsPerPage
                                    }
                                />
                            </tr>
                        </tfoot>
                    </table>
                </div>
            )}
        </Root>
    )
}

const AscendingBox = () => {
    return (
        <span>
            Ascending
            <IconButton color="secondary" aria-label="ascending">
                <img src={increaseIcon} alt="increaseIcon" />
            </IconButton>
        </span>
    )
}

const DescendingBox = () => {
    return (
        <span>
            Descending
            <IconButton color="secondary" aria-label="ascending">
                <img src={decreaseIcon} alt="increaseIcon" />
            </IconButton>
        </span>
    )
}

const orange = {
    50: '#ffdd89',
    200: '#ffca48',
    400: '#f97316',
    900: '#e37302',
}

const grey = {
    50: '#F3F6F9',
    100: '#E5EAF2',
    200: '#DAE2ED',
    300: '#C7D0DD',
    400: '#B0B8C4',
    500: '#9DA8B7',
    600: '#6B7A90',
    700: '#434D5B',
    800: '#303740',
    900: '#1C2025',
}

const Root = styled('div')(
    ({ theme }) => `
  font-family: 'Poppins', sans-serif;
  background: #fff;
  flex: 1;
  border-radius: 12px;
  border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
  
  .upperTable {
    height: 100%;
    width: 100%;
  }

  table {
    display: flex;
    flex-direction: column;
    font-size: 0.75rem;
    border-collapse: collapse;
    border: none;
    min-width: max-content;
  }

  thead {
    display: flex;
    width: 100%;
    height: auto;
  }
  
  tbody > tr:hover {
    filter: brightness(0.97);
  }
  
  tbody > tr.noFilter:hover {
    filter: none;
  }
  
  .noFilter > td {
    font-size: 1rem;
  }

  tbody > tr {
    background: #fff;
  }

  tr {
    display: flex;
    width: 100%;
    gap: 1.5rem;
    border-bottom: 1px solid ${
        theme.palette.mode === 'dark' ? grey[800] : grey[200]
    };
  }
  
  .pagination-footer > tfoot > tr {
    border-top: 1px solid ${
        theme.palette.mode === 'dark' ? grey[800] : grey[200]
    };
    border-bottom: none;
    width: 100%;
  }
  
  .pagination-footer td {
    width: 100%;
  }
  
  tbody {
    flex: 1;
    width: 100%;
    min-width: max-content;
    overflow-y: auto;
  }

  td,
  th {
    display: flex;
    word-wrap: break-word;
    justify-content: flex-start;
    align-items: center;
    font-size: 1rem;
    text-align: left;
    padding: 0.5rem;
  }

  tbody > tr > td {
    font-size: 0.9rem;
  }
  `
)

const CustomTablePagination = styled(TablePagination)(
    ({ theme }) => `
  & .${classes.spacer} {
    display: none;
  }

  & .${classes.toolbar}  {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    padding: 4px 0;

    @media (min-width: 768px) {
      flex-direction: row;
      align-items: center;
    }
  }

  & .${classes.selectLabel} {
    margin: 0;
  }

  & .${classes.select}{
    padding: 2px 0 2px 4px;
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
    border-radius: 6px; 
    background-color: transparent;
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
    transition: all 100ms ease;

    &:hover {
      background-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[50]};
      border-color: ${theme.palette.mode === 'dark' ? grey[600] : grey[300]};
    }

    &:focus {
      outline: 3px solid ${
          theme.palette.mode === 'dark' ? orange[400] : orange[200]
      };
      border-color: ${orange[400]};
    }
  }

  & .${classes.displayedRows} {
    margin: 0;

    @media (min-width: 768px) {
      margin-left: auto;
    }
  }

  & .${classes.actions} {
    display: flex;
    gap: 6px;
    border: transparent;
    text-align: center;
  }

  & .${classes.actions} > button {
    display: flex;
    align-items: center;
    padding: 0;
    border: transparent;
    border-radius: 50%;
    background-color: transparent;
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
    transition: all 120ms ease;

    > svg {
      font-size: 22px;
    }

    &:hover {
      background-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[50]};
      border-color: ${theme.palette.mode === 'dark' ? grey[600] : grey[300]};
    }

    &:focus {
      outline: 3px solid ${
          theme.palette.mode === 'dark' ? orange[400] : orange[200]
      };
      border-color: ${orange[400]};
    }

    &:disabled {
      opacity: 0.3;
      &:hover {
        border: 1px solid ${
            theme.palette.mode === 'dark' ? grey[800] : grey[200]
        };
        background-color: transparent;
      }
    }
  }
  `
)
