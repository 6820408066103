import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../services/Operations/authAPI";
import signInBg from "../../../assets/MainImages/newbg1.svg";
import RastaLogo from '../../../assets/MainImages/rasta-logo1.png';
import { IoMdEye, IoMdEyeOff } from "react-icons/io";
import toast, { Toaster } from "react-hot-toast"
import Preloader from "../../../Component/preloader/Preloader";
import ReCAPTCHA from "react-google-recaptcha";
import rastaLogo from "../../../assets/MainImages/loginRastaLogo.svg"

const LoginPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  
  const [formData, setFormData] = useState({ username: "", password: "" });
  const [showPassword, setShowPassword] = useState(false);
  const { username, password } = formData;
  const [backgroundLoading, setBackgroundLoading] = useState(true)
  const [verified, setVerified] = useState(false);
 

  useEffect(() => {
    const img = new Image();
    img.src = signInBg;

    const timer = setTimeout(() => {
      img.onload = () => {
        setBackgrounLoading(false);
      };
    }, 1600);

    img.onload = () => {
      clearTimeout(timer);
      setTimeout(() => {
        setBackgroundLoading(false);
      }, 1600);
    };

    return () => {
      clearTimeout(timer);
    };
  }, [signInBg]);

  const handleOnChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = (e) => {
		e.preventDefault();
		// "if (!username ||!verified) {
		// 	toast.error('Please verify you are not a robot', {
		// 		duration: 3000,
		// 		position: 'top-center',
		// 	});
		// 	return;
		// }"
		dispatch(login(username, password, navigate));
	};
	  const onCaptchaChange = (value) => {
		if (value) {
		  setVerified(true); 
		} else {
		  setVerified(false); 
		}
	  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
            <Toaster />
            {backgroundLoading ? (
                <Preloader />
            ) : (
                <>
                    <div className="md:hidden">
                        <div className="flex flex-col justify-center items-center text-black h-screen text-2xl font-semibold font-poppins bg-gray-300">
                            <img src={rastaLogo} alt="rasta-logo" width={100} height={100} className="mb-6"/>
                            <h1>No Access For Mobile Users.</h1>
                            <h1>Please Login Using Desktop.</h1>
                        </div>
                    </div>
                    <div
                    className="hidden md:flex items-center justify-center h-screen"
                    style={{
                        backgroundImage: `url(${signInBg})`,
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        width: "100%",
                        height: "100vh",
                    }}
                >
                      <div
                            className="login-form-container p-8 rounded-sm text-white border-2 border-orange-400 shadow-2xl relative z-90"
                            style={{
                                width: "450px",
                                height: "600px",
                                borderRadius: "7%",
                                borderColor: "#FF8849",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                backgroundColor: "#2F303C",
                                position: "absolute",
                                right: "15%",
                                top: "50%",
                                transform: "translateY(-50%)",
                            }}
                        >
                            <img
                                src={rastaLogo}
                                alt="Rasta Logo"
                                className="mb-4"
                                style={{ width: "120px", height: "120px" }}
                            />
                            <h6 className="text-4xl font-semibold mb-4 mt-4 font-poppins text-white">
                                Rasta.<strong className="text-orange-500">360</strong>
                            </h6>
                            <h6 className="text-xl">Enterprise Platform</h6>
                            <form
                                onSubmit={handleSubmit}
                                className="flex flex-col flex-1 items-center mt-10 w-full"
                            >
                                <div className="mb-10">
                                    <input
                                        style={{
                                            color: "black",
                                            width: "360px",
                                        }}
                                        type="text"
                                        name="username"
                                        placeholder="User ID"
                                        value={username}
                                        onChange={handleOnChange}
                                        className="w-full py-3 px-4 rounded-xl bg-white focus:outline-none"
                                    />
                                </div>
                                <div className="mb-10 relative">
                                    <input
                                        style={{
                                            color: "black",
                                            width: "360px",
                                        }}
                                        type={showPassword ? "text" : "password"}
                                        name="password"
                                        placeholder="Password"
                                        value={password}
                                        onChange={handleOnChange}
                                        className="w-full py-3 px-4 rounded-xl bg-white focus:outline-none pr-10"
                                    />
                                    {password.length > 0 && (
                                        <div
                                            onClick={togglePasswordVisibility}
                                            className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer"
                                        >
                                            {showPassword ? (
                                                <IoMdEyeOff className="h-6 w-6 text-black" />
                                            ) : (
                                                <IoMdEye className="h-6 w-6 text-black" />
                                            )}
                                        </div>
                                    )}
                                </div>
                                <div className="text-center mt-8">
                                    <button
                                        type="submit"
                                        className="py-3 px-14 rounded-lg font-poppins text-white cursor-pointer hover:border border-white transition-all duration-200 ease-in-out"
                                        style={{
                                            backgroundColor: "#FF6500",
                                            fontWeight: 500,
                                            fontSize: 18,
                                        }}
                                    >
                                        Login
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </>
            )}
        </>
  );
};

export default LoginPage;