import { filterIcons } from '../assets/IconArray'
import { ImagesOnMap } from '../assets/IconArray'

const pointCheckboxesData = [
	{
		id: 1,
		label: 'All',
		checked: true,
		imagePath: filterIcons.all,
		color: 'all',
	},
	{
		id: 2,
		label: 'Good',
		checked: true,
		imagePath: filterIcons.good,
		color: '#63AD43',
	},
	{
		id: 3,
		label: 'Fair',
		checked: true,
		imagePath: filterIcons.average,
		color: '#FFD100',
	},
	{
		id: 4,
		label: 'Average',
		checked: true,
		imagePath: filterIcons.average,
		color: '#ff8437',
	},
	{
		id: 5,
		label: 'Bad',
		checked: true,
		imagePath: filterIcons.bad,
		color: '#FF0000',
	},
	{
		id: 6,
		label: 'Speed Breaker',
		checked: true,
		imagePath: filterIcons.speedbreaker,
		color: '#868686',
	},
	// { id: 5, label: 'Unassessed', checked: false, imagePath: filterIcons.unassessed },
	// { id: 6, label: 'Major Potholes', checked: false, imagePath: filterIcons.majorpothole },
	// { id: 7, label: 'Minor Pothholes', checked: false, imagePath: filterIcons.minorpothole },
	// { id: 8, label: 'Servere Potholes', checked: false, imagePath: filterIcons.severepothole },
	// { id: 8, label: 'Patches', checked: false, imagePath: patch },
	// { id: 9, label: 'Slab Alignment', checked: false, imagePath: slab },
	// { id: 10, label: 'Cracks', checked: false, imagePath: crack },
	// { id: 11, label: 'Ravelling', checked: false, imagePath: ravel },
	// { id: 12, label: 'Major Junctions', checked: false, imagePath: junction },
];

const segmentCheckboxesData = [
    {
        id: 1,
        label: 'All',
        checked: false,
        imagePath: filterIcons.all,
        layer: 'all',
    },
    {
        id: 2,
        label: 'Good',
        checked: false,
        imagePath: filterIcons.good,
        layer: 'good',
    },
    {
        id: 3,
        label: 'Average',
        checked: false,
        imagePath: filterIcons.average,
        layer: 'average',
    },
    {
        id: 4,
        label: 'Bad',
        checked: false,
        imagePath: filterIcons.bad,
        layer: 'failed',
    },

    // { id: 5, label: 'Unassessed', checked: false, imagePath: filterIcons.unassessed },
    // { id: 6, label: 'Major Potholes', checked: false, imagePath: filterIcons.majorpothole },
    // { id: 7, label: 'Minor Potholes', checked: false, imagePath: filterIcons.minorpothole },
    // { id: 8, label: 'Severe Potholes', checked: false, imagePath: filterIcons.severepothole },
    // { id: 2, label: 'Excellent', checked: false, imagePath: excellent },
    // { id: 9, label: 'Patches', checked: false, imagePath: patch },
    // { id: 10, label: 'Slab Alignment', checked: false, imagePath: slab },
    // { id: 11, label: 'Cracks', checked: false, imagePath: crack },
    // { id: 12, label: 'Ravelling', checked: false, imagePath: ravel },
    // { id: 13, label: 'Major Junctions', checked: false, imagePath: junction },
]

const otherConditionsData = [
    {
        id: 1,
        label: 'Speed Breaker',
        checked: false,
        imagePath: filterIcons.speedbreaker,
    },
    {
        id: 2,
        label: 'Manhole',
        checked: false,
        imagePath: filterIcons.manhole,
    },
]

const roadAssetsData = [
    {
        id: 0,
        label: 'All',
        checked: true,
        imagePath: filterIcons.all,
        layer: 'all',
    },
    {
        id: 1,
        label: 'Bus Stop',
        checked: true,
        imagePath: ImagesOnMap.busstoponmap,
        layer: 'busstoponmap',
    },
    {
        id: 2,
        label: 'Cautionary Sign',
        checked: true,
        imagePath: ImagesOnMap.cautionarysignonmap,
        layer: 'cautionarysignonmap',
    },
    {
        id: 3,
        label: 'CCTV',
        checked: true,
        imagePath: ImagesOnMap.cctvonmap,
        layer: 'cctvonmap',
    },
    {
        id: 4,
        label: 'Electric Feeder',
        checked: true,
        imagePath: ImagesOnMap.electicfeederonmap,
        layer: 'electicfeederonmap',
    },
    {
        id: 5,
        label: 'Encroachment',
        checked: true,
        imagePath: ImagesOnMap.encroachmentonmap,
        layer: 'encroachmentonmap',
    },
    {
        id: 6,
        label: 'Foot Path',
        checked: true,
        imagePath: ImagesOnMap.informatoryboardonmap,
        layer: 'footpathonmap',
    },
    {
        id: 7,
        label: 'Gantry Board',
        checked: true,
        imagePath: ImagesOnMap.gantryboardonmap,
        layer: 'gantryboardonmap',
    },
    {
        id: 8,
        label: 'Hoarding',
        checked: true,
        imagePath: ImagesOnMap.hoardingonmap,
        layer: 'hoardingonmap',
    },
    {
        id: 9,
        label: 'Informatory Board',
        checked: true,
        imagePath: ImagesOnMap.informatoryboardonmap,
        layer: 'informatorysignonmap',
    },
    {
        id: 10,
        label: 'Left Chevron',
        checked: true,
        imagePath: ImagesOnMap.leftchevrononfeature,
        layer: 'leftchevrononmap',
    },
    {
        id: 11,
        label: 'Manhole',
        checked: true,
        imagePath: ImagesOnMap.informatoryboardonmap,
        layer: 'manholeonmap',
    },
    {
        id: 12,
        label: 'Mandatory Sign',
        checked: true,
        imagePath: ImagesOnMap.mandatorysignonmap,
        layer: 'mandatorysignonmap',
    },
    {
        id: 13,
        label: 'Metro Station',
        checked: true,
        imagePath: ImagesOnMap.metrostationonmap,
        layer: 'metrostationonmap',
    },
    {
        id: 14,
        label: 'Pedestrain Crossing',
        checked: true,
        imagePath: ImagesOnMap.pedestraincrossingonmap,
        layer: 'pedestraincrossingonmap',
    },
    {
        id: 15,
        label: 'Right Chevron',
        checked: true,
        imagePath: ImagesOnMap.rightchevrononfeature,
        layer: 'rightchevrononmap',
    },
    {
        id: 16,
        label: 'Rumbling Strip',
        checked: true,
        imagePath: ImagesOnMap.rumblingstriponmap,
        layer: 'rumblingstriponmap',
    },
    {
        id: 17,
        label: 'Speed Breaker',
        checked: true,
        imagePath: ImagesOnMap.speedbreakeronmap,
        layer: 'speedbreakeronmap',
    },
    {
        id: 18,
        label: 'Street Light',
        checked: true,
        imagePath: filterIcons.streetlight,
        layer: 'streetlightonmap',
    },
    {
        id: 19,
        label: 'Traffic Signal',
        checked: true,
        imagePath: filterIcons.trafficsignal,
        layer: 'trafficlightonmap',
    },
    {
        id: 20,
        label: 'Trees',
        checked: true,
        imagePath: ImagesOnMap.treesonmap,
        layer: 'treesonmap',
    },
    {
        id: 21,
        label: 'Work In Progress',
        checked: true,
        imagePath: ImagesOnMap.workinprogressonmap,
        layer: 'workinprogressonmap',
    },
    {
        id: 22,
        label: 'Comment',
        checked: false,
        imagePath: ImagesOnMap.commenticononmap,
        layer: 'commenticononmap',
    }
]



const RoadFeaturesData = [
    // { id: 1, label: 'All', checked: false, imagePath: all },
    // {
    //   id: 1,
    //   label: "Informatory Signs",
    //   checked: false,
    //   imagePath: filterIcons.informatorysign,
    // },
    // {
    // 	id: 2,
    // 	label: 'Mandatory Signs',
    // 	checked: false,
    // 	imagePath: mandatorysigns,
    // },
    // {
    // 	id: 4,
    // 	label: 'Cautionary Signs',
    // 	checked: false,
    // 	imagePath: caution,
    // },
]

const InformatorySigns = [
    { id: 1, label: 'No Parking', imagePath: filterIcons.streetlight },
    {
        id: 2,
        label: 'Pedestrian Crossing',
        imagePath: filterIcons.streetlight,
    },
    { id: 3, label: 'Left Chevron', imagePath: filterIcons.streetlight },
    {
        id: 4,
        label: 'Right Chevron',
        imagePath: filterIcons.streetlight,
    },
    {
        id: 5,
        label: 'SchoolZone',
        imagePath: filterIcons.streetlight,
    },
    {
        id: 6,
        label: 'Side road left ',
        imagePath: filterIcons.streetlight,
    },
    {
        id: 7,
        label: 'Speed breaker Ahead',
        imagePath: filterIcons.streetlight,
    },
    {
        id: 8,
        label: 'SpeedLimit-20',
        imagePath: filterIcons.streetlight,
    },
    {
        id: 9,
        label: 'SpeedLimit-40',
        imagePath: filterIcons.streetlight,
    },
    {
        id: 10,
        label: 'SpeedLimit-80',
        imagePath: filterIcons.streetlight,
    },
    {
        id: 11,
        label: 'U-turn',
        imagePath: filterIcons.streetlight,
    },
]

const MandatorySigns = []

const CautionarySigns = []

export {
    pointCheckboxesData,
    segmentCheckboxesData,
    otherConditionsData,
    roadAssetsData,
    MandatorySigns,
    CautionarySigns,
    RoadFeaturesData,
    InformatorySigns,
}
