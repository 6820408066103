import { setLoading } from '../../slices/profileSlice'
import { apiConnector } from '../apiConnector'
import { memberendpoints } from '../apis'
import { toast } from 'react-hot-toast'
import { REQUEST_TYPE, STATUS } from '../../data/constantdata'

export function assignMember(
    office_id,
    name,
    username,
    role,
    email,
    phone,
    dob,
    password,
    memberPermissions,
    navigate
) {
    return async (dispatch, getState) => {
        // const toastId = toast.loading('Loading..');
        const { token } = getState().auth
        dispatch(setLoading(true))

        try {
            const response = await apiConnector(
                'POST',
                memberendpoints.ASSIGN_MEMBER,
                {
                    office_id,
                    name,
                    username,
                    role,
                    email,
                    phone,
                    dob,
                    password,
                    memberPermissions,
                },
                { authorization: token }
            )

            toast.success('Member Added Successfully')
        } catch (error) {
            if (error.response) {
                switch (error.response.status) {
                    case 404:
                        if (error.response.data.message === "The username already exist") {
                            toast.error("Username already exists");
                        } else if (error.response.data.message === "The email already exist") {
                            toast.error("Email already exists");
                        } else if (error.response.data.message === "The phone number already exist") {
                            toast.error("Phone number already exists");
                        } else {
                            toast.error("An error occurred");
                        }
                        break;
                    default:
                        toast.error("An error occurred");
                }
            } else {
                toast.error("An error occurred");
            }
            console.error("Error in assigning member: ", error);
        }
        // Cleanup actions
        dispatch(setLoading(false))
    }
}

export function updateMember( 
    user_id,
    email, 
    phone, 
    memberPermissions,
) {
    return async (dispatch, getState) => {
        const { token } = getState().auth
        dispatch(setLoading(true))

        try {
            const response = await apiConnector(
                "POST",
                memberendpoints.UPDATE_MEMBER,
                {
                    user_id,
                    email,
                    phone,
                    memberPermissions,
                },
                { authorization: token }
            )

            toast.success("Member Details Updated Successfully!")

        } catch (error) {
            console.error("Error in updating member details: ", error);
            toast.error("Failed to update member!")
        }
        dispatch(setLoading(false))
    }
}

export function getMemberDetailsByAdmin(user_id) {
    return async (dispatch, getState) => {
        const { token } = getState().auth
        let result = []

        dispatch(setLoading(true)) 
        try {
            const response = await apiConnector(
                'GET',
                memberendpoints.GET_MEMBER_DETAILS,
                null,
                { authorization: token },
                { user_id }
            )

            if (response.status == 200) {
                result = response?.data
            }

            return result
        } catch (error) {
            console.error("Error while fetching member details: ", error);
        } 
        dispatch(setLoading(false)) 
    }
}

export function DeleteMemberByAdmin(user_id) {
    return async (dispatch, getState) => {
        const { token } = getState().auth

        dispatch(setLoading(true))
        try {
            const response = await apiConnector(
                "POST",
                memberendpoints.DELETE_MEMBER,
                {
                    user_id
                },
                { authorization: token },
            )

            toast.success("Member Deleted Successfully!!!");
        } catch (error) {
            console.error("Error while deleting member: ", error);
            toast.error("Error while deleting member!")
        }
    }
}

// write the business logic here delete member req to admin
export function deleteMemberReq(office_id) {
    return async (dispatch, getState) => {
        const { token } = getState().auth

        dispatch(setLoading(true))
        try {
            const response = await apiConnector(
                'POST',
                memberendpoints.DEL_MEMBER,
                {
                    request_type: REQUEST_TYPE.DELETE_PROFILE,
                    office_id,
                    status: STATUS.STATUS_TYPE,
                },
                { authorization: token }
            )
            toast.success('Delete Request sent to Admin Successfully ')
        } catch (error) {
            console.error('Error Message : ', error.message)
            toast.error('Failed to send Delete Request to Admin')
        }
        dispatch(setLoading(false))
    }
}
