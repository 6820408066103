import React, { useRef, useState, useEffect } from 'react'
import {
    Stage,
    Layer,
    Line,
    Circle,
    Text,
    Image as KonvaImage,
    Rect,
} from 'react-konva'
import { ChromePicker } from 'react-color'
import { Spinner } from '../../../utils/Spinner'
import { IoIosSave, IoMdCheckboxOutline } from 'react-icons/io'
import { FaUndo, FaRedo } from 'react-icons/fa'
import { LuZoomOut } from 'react-icons/lu'
import { MdAutoDelete, MdCheckBoxOutlineBlank } from 'react-icons/md'
import { CgColorPicker } from 'react-icons/cg'
import { Tooltip } from 'react-tooltip'
import { BiRotateLeft, BiRotateRight } from 'react-icons/bi'

const ImageAnnotation = ({
    flag,
    imgSrc,
    canvasRef,
    annotations,
    setAnnotations,
    containerRef,
    customDivRef,
    loading,
    setShapes,
    shapes,
    rotation,
    setRotation,
    viewerDimensions,
    setViewerDimensions,
}) => {
    const [points, setPoints] = useState([])
    const [currentLabel, setCurrentLabel] = useState('')
    const [showInput, setShowInput] = useState(false)
    const [inputPosition, setInputPosition] = useState({ x: 0, y: 0 })
    const [history, setHistory] = useState([])
    const [redoHistory, setRedoHistory] = useState([])
    const [isDrawing, setIsDrawing] = useState(false)
    const [scaleValue, setScaleValue] = useState(1)
    const stageRef = useRef(null)
    const [color, setColor] = useState('#E45116')
    const [showColorPicker, setShowColorPicker] = useState(false)
    const [selectedShapeIndex, setSelectedShapeIndex] = useState(null)
    const [image, setImage] = useState(null)
    const [originalDimensions, setOriginalDimensions] = useState({
        width: 0,
        height: 0,
    })
    const [originalImageVisible, setOriginalImageVisible] = useState(false)
    const [originalImageFlag, setOriginalImageFlag] = useState(flag)
    const [currentLine, setCurrentLine] = useState(null)

    // useEffect(() => {

    //     setRotation(flag === 1 ? -90 : 0);
    // }, [flag]);

    useEffect(() => {
        if (imgSrc) {
            const img = new window.Image()
            img.src = `data:image/jpeg;base64,${imgSrc}`

            img.onload = () => {
                // console.log('Image loaded successfully');
                setImage(img)

                const containerWidth = containerRef.current?.clientWidth || 1000

                const scale = (containerWidth / img.width) * 0.85

                // Set dimensions maintaining aspect ratio
                const newWidth = img.width * scale
                const newHeight = img.height * scale

                setViewerDimensions({
                    width: newWidth,
                    height: newHeight,
                })

                setOriginalDimensions({ width: img.width, height: img.height })
            }

            img.onerror = (error) => {
                console.error('Error loading image:', error)
                setImage(null)
            }

            return () => {
                img.onload = null
                img.onerror = null
            }
        }
    }, [imgSrc, containerRef, setViewerDimensions])

    const handleClick = (e) => {
        if (!isDrawing) return

        const stage = e.target.getStage()
        const scale = stage.scaleX()
        const position = stage.position()

        const point = stage.getPointerPosition()
        const viewerPoint = {
            x: (point.x - position.x) / scale,
            y: (point.y - position.y) / scale,
        }

        if (points.length > 1 && isCloseToFirstPoint(viewerPoint)) {
            const newShape = {
                points: [...points, points[0]],
                label: null,
                color: color,
            }

            setShapes([...shapes, newShape])
            setRedoHistory([])
            setPoints([])
            setCurrentLine(null)
            setSelectedShapeIndex(shapes.length)
            setShowInput(true)
            return
        }

        const newPoints = [...points, viewerPoint]
        setPoints(newPoints)
        setRedoHistory([])

        if (newPoints.length > 1) {
            const lastTwoPoints = newPoints.slice(-2)
            setCurrentLine({
                points: lastTwoPoints.flatMap(p => [p.x, p.y]),
                color: color,
            })
        }
    }

    const isCloseToFirstPoint = (point) => {
        const firstPoint = points[0]
        const distance = Math.sqrt(
            Math.pow(firstPoint.x - point.x, 2) +
                Math.pow(firstPoint.y - point.y, 2)
        )
        return distance < 10
    }

    const handleLabelSubmit = (e) => {
        if (e.key === 'Enter' && currentLabel.trim()) {
            const newShapes = shapes.map((shape, index) =>
                index === selectedShapeIndex
                    ? { ...shape, label: currentLabel.trim() }
                    : shape
            )
            setShapes(newShapes)
            setCurrentLabel('')
            setShowInput(false)
            setSelectedShapeIndex(null)
            setHistory([...history, newShapes])
        }
    }

    const handleUndo = () => {
        // If we're in the middle of drawing, remove the last point
        if (points.length > 0) {
            const newPoints = points.slice(0, -1);
            setPoints(newPoints);
            
            // Update the current line based on the new points
            if (newPoints.length > 1) {
                const lastTwoPoints = newPoints.slice(-2);
                setCurrentLine({
                    points: lastTwoPoints.flatMap(p => [p.x, p.y]),
                    color: color,
                });
            } else {
                setCurrentLine(null); // Clear the line if we have 1 or 0 points
            }
            return;
        }

        // If we have shapes, remove the last shape
        if (shapes.length > 0) {
            const newShapes = shapes.slice(0, -1);
            setRedoHistory([...redoHistory, shapes[shapes.length - 1]]);
            setShapes(newShapes);
        }
    };

    const handleRedo = () => {
        if (redoHistory.length > 0) {
            const lastRedoShape = redoHistory[redoHistory.length - 1]
            setShapes([...shapes, lastRedoShape])
            setRedoHistory(redoHistory.slice(0, -1))
        }
    }

    const handleImageZoom = (e) => {
        e.evt.preventDefault()
        const scaleBy = 1.1
        const stage = stageRef.current
        const oldScale = stage.scaleX()
        const pointer = stage.getPointerPosition()
        const mousePointTo = {
            x: (pointer.x - stage.x()) / oldScale,
            y: (pointer.y - stage.y()) / oldScale,
        }

        let newScale =
            e.evt.deltaY > 0 ? oldScale * scaleBy : oldScale / scaleBy
        setScaleValue(newScale)
        if (newScale < 1) newScale = 1
        if (newScale > 6) newScale = 6

        stage.scale({ x: newScale, y: newScale })
        const newPos = {
            x: pointer.x - mousePointTo.x * newScale,
            y: pointer.y - mousePointTo.y * newScale,
        }
        stage.position(newPos)
        stage.batchDraw()
    }

    const handleResetZoom = () => {
        const stage = stageRef.current
        stage.scale({ x: 1, y: 1 })
        stage.position({ x: 0, y: 0 })
        stage.batchDraw()
        setScaleValue(1)
    }

    const handleDeleteAllDrawing = () => {
        setShapes([])
        setPoints([])
        setHistory([])
        setRedoHistory([])
        setCurrentLine(null)
    }

    const handleOriginalImage = () => {
        originalImageFlag == 0
            ? setOriginalImageFlag(1)
            : setOriginalImageFlag(0)
        setOriginalImageVisible(!originalImageVisible)
    }

    const handleRotateLeft = () => {
        setRotation((prev) => prev - 90)
    }

    const handleRotateRight = () => {
        setRotation((prev) => prev + 90)
    }

    return (
        <div className="flex flex-col box-border w-full h-full">
            {showInput && (
                <input
                    className="h-[30px] min-w-[200px] bg-white border border-[#ee6b37] p-[5px] outline-none focus:border-[#cd4510]"
                    type="text"
                    value={currentLabel}
                    onChange={(e) => setCurrentLabel(e.target.value)}
                    onKeyPress={handleLabelSubmit}
                    placeholder="Enter label and press Enter"
                    style={{
                        position: 'fixed',
                        left: '50%',
                        top: '50%',
                        transform: 'translate(-50%, -50%)',
                        zIndex: 1000,
                    }}
                />
            )}
            <div className="flex justify-center items-center ">
                {!loading && (
                    <div className="flex gap-x-6">
                        <button
                            onClick={() => setIsDrawing(!isDrawing)}
                            className="font-poppins m-[10px] p-[10px] py-2 px-4 border-none rounded-[5px] bg-orange-500 cursor-pointer text-white transform transition-transform duration-200 hover:scale-105 active:scale-95 focus:outline-none"
                            data-tooltip-id="draw-tooltip"
                            data-tooltip-content={
                                isDrawing ? 'Stop Drawing' : 'Start Drawing'
                            }
                        >
                            {isDrawing ? 'Stop' : 'Draw'}
                        </button>
                        <Tooltip id="draw-tooltip" />

                        <div
                            onClick={handleUndo}
                            className="font-poppins m-[10px] p-[10px] py-2 px-4 border-none rounded-[5px] bg-orange-500 cursor-pointer text-[aliceblue] transform transition-transform duration-200 hover:scale-105 active:scale-95 focus:outline-none"
                            data-tooltip-id="undo-tooltip"
                            data-tooltip-content="Undo"
                        >
                            <FaUndo size={24} />
                        </div>
                        <Tooltip id="undo-tooltip" />

                        <div
                            onClick={handleRedo}
                            className="m-[10px] p-[10px] py-2 px-4 border-none rounded-[5px] bg-orange-500 cursor-pointer text-[aliceblue] transform transition-transform duration-200 hover:scale-105 active:scale-95 focus:outline-none"
                            data-tooltip-id="redo-tooltip"
                            data-tooltip-content="Redo"
                        >
                            <FaRedo size={24} />
                        </div>
                        <Tooltip id="redo-tooltip" />

                        <div
                            onClick={handleResetZoom}
                            className="font-poppins m-[10px] p-[10px] py-2 px-4 border-none rounded-[5px] bg-orange-500 cursor-pointer text-[aliceblue] transform transition-transform duration-200 hover:scale-105 active:scale-95 focus:outline-none"
                            data-tooltip-id="reset-zoom-tooltip"
                            data-tooltip-content="Reset Zoom"
                        >
                            <LuZoomOut size={24} />
                        </div>
                        <Tooltip id="reset-zoom-tooltip" />

                        <div
                            onClick={handleDeleteAllDrawing}
                            className="font-poppins m-[10px] p-[10px] py-2 px-4 border-none rounded-[5px] bg-orange-500 cursor-pointer text-[aliceblue] transform transition-transform duration-200 hover:scale-105 active:scale-95 focus:outline-none"
                            data-tooltip-id="delete-all-tooltip"
                            data-tooltip-content="Delete All Drawings"
                        >
                            <MdAutoDelete size={24} />
                        </div>
                        <Tooltip id="delete-all-tooltip" />

                        <div
                            onClick={handleRotateLeft}
                            className="font-poppins m-[10px] p-[10px] py-2 px-4 border-none rounded-[5px] bg-orange-500 cursor-pointer text-[aliceblue] transform transition-transform duration-200 hover:scale-105 active:scale-95 focus:outline-none"
                            data-tooltip-id="rotate-left-tooltip"
                            data-tooltip-content="Rotate Left"
                        >
                            <BiRotateLeft size={24} />
                        </div>
                        <Tooltip id="rotate-left-tooltip" />

                        <div
                            onClick={handleRotateRight}
                            className="font-poppins m-[10px] p-[10px] py-2 px-4 border-none rounded-[5px] bg-orange-500 cursor-pointer text-[aliceblue] transform transition-transform duration-200 hover:scale-105 active:scale-95 focus:outline-none"
                            data-tooltip-id="rotate-right-tooltip"
                            data-tooltip-content="Rotate Right"
                        >
                            <BiRotateRight size={24} />
                        </div>
                        <Tooltip id="rotate-right-tooltip" />

                        <div
                            onClick={() => setShowColorPicker(!showColorPicker)}
                            className="font-poppins m-[10px] p-[10px] py-2 px-4 border-none rounded-[5px] bg-orange-500 cursor-pointer text-[aliceblue] transform transition-transform duration-200 hover:scale-105 active:scale-95 focus:outline-none"
                            data-tooltip-id="color-picker-tooltip"
                            data-tooltip-content="Color Picker"
                        >
                            <CgColorPicker size={24} />
                        </div>
                        <Tooltip id="color-picker-tooltip" />
                        {/* <div
                                className="flex flex-row justify-center items-center cursor-pointer text-white font-poppins font-semibold rounded-md bg-orange-500 px-2 py-1 hover:scale-105 transition-transform duration-300"
                                onClick={handleOriginalImage}
                            >
                                {originalImageVisible ? (
                                    <IoMdCheckboxOutline className="w-5 h-5 mr-2" />
                                ) : (
                                    <MdCheckBoxOutlineBlank className="w-5 h-5 mr-2" />
                                )}
                                 Original 
                            </div> */}
                    </div>
                )}
                <div
                    style={{
                        position: 'absolute',
                        top: 50,
                        left: 50,
                        zIndex: 10,
                    }}
                >
                    {showColorPicker && (
                        <ChromePicker
                            color={color}
                            onChange={(color) => setColor(color.hex)}
                            disableAlpha
                        />
                    )}
                </div>
            </div>
            <div
                ref={containerRef}
                className="flex justify-center items-center w-full h-full"
                style={{
                    position: 'relative',
                    overflow: 'hidden',
                    padding: '10px',
                }}
            >
                {imgSrc ? (
                    <div
                        ref={customDivRef}
                        style={{
                            position: 'relative',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '100%',
                            height: '100%',
                            maxWidth: '100%',
                            overflow: 'auto',
                        }}
                    >
                        {loading ? (
                            <div className="h-full flex items-center">
                                <Spinner />
                            </div>
                        ) : (
                            <Stage
                                width={viewerDimensions.width}
                                height={viewerDimensions.height}
                                onClick={handleClick}
                                ref={stageRef}
                                onWheel={handleImageZoom}
                                style={{
                                    cursor: isDrawing ? 'crosshair' : 'default',
                                }}
                            >
                                <Layer>
                                    {image && (
                                        <KonvaImage
                                            image={image}
                                            width={viewerDimensions.width}
                                            height={viewerDimensions.height}
                                            x={viewerDimensions.width / 2}
                                            y={viewerDimensions.height / 2}
                                            offsetX={viewerDimensions.width / 2}
                                            offsetY={
                                                viewerDimensions.height / 2
                                            }
                                            rotation={rotation}
                                        />
                                    )}
                                    {shapes.map((shape, i) => (
                                        <React.Fragment key={i}>
                                            <Line
                                                points={shape.points.flatMap(
                                                    (p) => [p.x, p.y]
                                                )}
                                                stroke={shape.color}
                                                strokeWidth={1.5}
                                                closed={true}
                                                fill={`${shape.color}22`}
                                            />
                                            {shape.label && (
                                                (() => {
                                                    const centerX = shape.points.reduce((sum, p) => sum + p.x, 0) / shape.points.length;
                                                    const centerY = shape.points.reduce((sum, p) => sum + p.y, 0) / shape.points.length;
                                                    const minX = Math.min(...shape.points.map(p => p.x));
                                                    const maxX = Math.max(...shape.points.map(p => p.x));
                                                    const isInLeftHalf = centerX < viewerDimensions.width / 2;
                                                    const labelWidth = shape.label.length * 8 + 20;
                                                    const labelHeight = 24;
                                                    const labelX = isInLeftHalf ? maxX + 15 : minX - labelWidth - 15;

                                                    return (
                                                        <>
                                                            <Rect
                                                                x={labelX}
                                                                y={centerY - labelHeight / 2}
                                                                width={labelWidth}
                                                                height={labelHeight}
                                                                fill={shape.color}
                                                                cornerRadius={4}
                                                            />
                                                            <Text
                                                                text={shape.label}
                                                                x={labelX}
                                                                y={centerY - labelHeight / 2}
                                                                width={labelWidth}
                                                                height={labelHeight}
                                                                align="center"
                                                                verticalAlign="middle"
                                                                fontSize={14}
                                                                fill="white"
                                                                padding={5}
                                                                listening={false}
                                                            />
                                                        </>
                                                    )
                                                })()
                                            )}
                                        </React.Fragment>
                                    ))}
                                    {currentLine && (
                                        <Line
                                            points={currentLine.points}
                                            stroke={currentLine.color}
                                            strokeWidth={3}
                                        />
                                    )}
                                    {points.map((point, i) => (
                                        <Circle
                                            key={i}
                                            x={point.x}
                                            y={point.y}
                                            radius={3}
                                            fill={color}
                                        />
                                    ))}
                                    {points.length > 1 && points.map((point, i) => {
                                        if (i === points.length - 1) return null;
                                        return (
                                            <Line
                                                key={`line-${i}`}
                                                points={[
                                                    point.x,
                                                    point.y,
                                                    points[i + 1].x,
                                                    points[i + 1].y,
                                                ]}
                                                stroke={color}
                                                strokeWidth={3}
                                            />
                                        );
                                    })}
                                </Layer>
                            </Stage>
                        )}

                        <canvas ref={canvasRef} style={{ display: 'none' }} />
                    </div>
                ) : (
                    <div className="w-full h-full flex justify-center items-center">
                        <Spinner />
                    </div>
                )}
            </div>
        </div>
    )
}

export default ImageAnnotation
