import { useState } from 'react'
import InnerSideBar from '../../../Component/InnerSideBar'
import { UserProfileIcons } from '../../../assets/IconArray'
import { LoginStatus } from '../../../Component/Utils/LoginStatus'
import CloseButton from '../../../Component/Utils/CloseButton'
import { useNavigate } from 'react-router-dom'

export const OwnerProfileData = ({ profileData }) => {
    const [activePage, setActivePage] = useState('settings')
    const navigate = useNavigate()

    const handlePageChange = (page) => {
        setActivePage(page)
    }

    return (
        <div className="flex">
            <div className="w-20">
                <InnerSideBar
                    setActivePage={handlePageChange}
                    activePage={activePage}
                />
            </div>

            <div className="flex-1  h-screen ">
                <div className="bg-white mediumShadow mx-auto my-12 h-[35rem] w-[72rem] p-6 gap-y-5">
                    <div onClick={() => navigate(-1)}>
                        <CloseButton />
                    </div>

                    <section className=" flex  h-56 justify-between mx-10">
                        <div className="flex-col  p-2 justify-center items-center gap-x-4">
                            <div className="border-2 border-[#FE6100] rounded-lg h-40 w-44  justify-center items-center  mx-auto gap-y-4 p-3 ">
                                <img
                                    src={UserProfileIcons.profilephoto}
                                    alt="User Photo"
                                    className="h-[80px] w-[80px] mx-auto"
                                />
                                <p className="font-bold font-poppins mx-auto text-xl text-center mt-4">
                                    Profile Photo
                                </p>
                            </div>
                        </div>
                        <div className="flex-col h-full gap-y-6 w-72 justify-start items-center ">
                            <div
                                className=" w-full p-3 mt-4 relative bottom-[26px] left-[75px]"
                                style={{ pointerEvents: 'none' }}
                            >
                                <LoginStatus />
                            </div>
                        </div>
                    </section>
                    <section className="flex flex-1 border-[1px] border-orange-400 rounded-lg justify-between p-4 font-poppins ">
                        <div className="flex-col">
                            <div className="col-span-1">
                                <div className="grid grid-rows-2 gap-2 w-[30vw]">
                                    <div className="row-span-1 bg-[#FFF1E7] p-2 flex items-center rounded w-auto ">
                                        <div className="flex w-[50%]">
                                            <p className="text-name font-semibold ">
                                                UserName
                                            </p>
                                            <p className="colon-class">:</p>
                                        </div>
                                        <p className="colon-class flex-1">
                                            {profileData?.username
                                                ? profileData.username
                                                : 'N/A'}
                                        </p>
                                    </div>

                                    <div className="row-span-1 bg-[#FFF1E7] p-2 flex items-center rounded w-auto ">
                                        <div className="flex w-[50%]">
                                            <p className="text-name font-semibold ">
                                                Name
                                            </p>
                                            <p className="colon-class">:</p>
                                        </div>
                                        <p className="colon-class flex-1">
                                            {profileData?.name
                                                ? profileData.name
                                                : 'N/A'}
                                        </p>
                                    </div>
                                    <div className="row-span-1 bg-[#FFF1E7] p-2 flex items-center rounded w-auto ">
                                        <div className="flex w-[50%]">
                                            <p className="text-name font-semibold ">
                                                Mobile No
                                            </p>
                                            <p className="colon-class">:</p>
                                        </div>
                                        <p className="colon-class flex-1">
                                            {profileData?.phone
                                                ? profileData.phone
                                                : 'N/A'}
                                        </p>
                                    </div>
                                    <div className="row-span-1 bg-[#FFF1E7] p-2 flex items-center rounded w-auto ">
                                        <div className="flex w-[50%]">
                                            <p className="text-name font-semibold ">
                                                Email ID
                                            </p>
                                            <p className="colon-class">:</p>
                                        </div>
                                        <p className="colon-class flex-1">
                                            {profileData?.email
                                                ? profileData.email
                                                : 'N/A'}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {
                            <div className="col-span-1">
                                <div className="grid grid-rows-3 gap-2 w-[30vw]">
                                    <div className="row-span-1 bg-[#FFF1E7] p-2 flex items-center rounded w-auto ">
                                        <div className="flex w-[44%]">
                                            <p className="text-name font-semibold ">
                                                Office Name
                                            </p>
                                            <p className="colon-class">:</p>
                                        </div>
                                        <p className="colon-class flex-1">
                                            {profileData?.office_name
                                                ? profileData.office_name
                                                : 'N/A'}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        }
                    </section>
                </div>
            </div>
        </div>
    )
}
