import PropTypes from 'prop-types'
import { connect, useSelector } from 'react-redux'
import { selectAuth } from '../slices/authSlice'

const ShowForPermissionComponent = ({ permission, children, userPermissions }) => {
    const { userType } = useSelector(selectAuth);
    const memberPermissions = useSelector(state => state.auth.memberPermissions)

    const hasPermissions = () => {
        if (userType === 'Member') {
            return memberPermissions.includes(permission)
        } else {
            return userPermissions.includes(permission)
        }
    }

    const couldShow = hasPermissions()

    return couldShow ? children : null
}

ShowForPermissionComponent.propTypes = {
    userPermissions: PropTypes.arrayOf(PropTypes.string),
    permission: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
}

const mapStateToProps = (state) => {
    return {
        userPermissions: state.user.permissions,
    }
}

export const ShowForPermission = connect(mapStateToProps)(ShowForPermissionComponent) 
